<template>
    <div v-if="organization">

        <nav>
            <div class="nav__item" @click="displayProfile" :class="{ 'is-active': showOrganizationProfile }" >Organization’s profile</div>
            <div class="nav__item" @click="displayVerificationDocuments" :class="{ 'is-active': showVerificationDocuments }" >Verification documents</div>
        </nav>

        <div v-if="showOrganizationProfile">
            <ViewOrganizationProfile :organization="organization" />
        </div>

        <div v-if="showVerificationDocuments && organization.verificationDocument">
          <p class="info__message" style="margin-top: 12px;">Notes: Please check all details and determine weather the organization is qualified to get verification from Empower or not. The notification will be sent to organization right away once the decision is made.</p>
          <form>
            <p>
              <input type="radio" v-model="verificationStatus" value="APPROVED" id="pass" name="approve">
              <label for="pass">Approve</label>
            </p>
            <p>
              <input type="radio" class="waiting"  id="waitList" v-model="verificationStatus" value="WAITING" name="waitingList" >
              <label class="rejection" for="waitList">Waiting list</label>
            </p>
            <p>
              <input type="radio" class="rejection"  id="rejection" v-model="verificationStatus" value="REJECTED" name="reject" >
              <label class="rejection" for="rejection">Reject</label>
            </p>
          </form>
          <div>
            <textarea name="" id="" cols="30" rows="10" v-model="verificationReason" style="width: 50%; margin-top: 20px;"></textarea>
          </div>
          <button class="btn-s" @click.prevent="updateVerification">Confirm</button>
            <ShowVerificationDocuments :organization="organization" />

        </div>

    </div>
</template>

<script>
import ViewOrganizationProfile from "./ViewOrganizationProfile";
import ShowVerificationDocuments from "./ShowVerificationDocuments";

export default {
        name: "ViewOrganizationDetails",
        components: {ShowVerificationDocuments, ViewOrganizationProfile},
        props: {
            organizationId: {
                type: String
            }
        },
        async mounted() {
          await this.$store.dispatch('getOneOrganization', this.organizationId);
          await this.$store.dispatch('getVerificationDocuments', this.organizationId);
          this.verificationStatus = this.organization.verificationStatus;
          this.verificationReason = this.organization.verificationReason;
        },
        data(){
            return {
                verificationStatus: null,
                showOrganizationProfile: true,
                showVerificationDocuments: false
            }
        },
        computed: {
          organization() {
            return this.$store.state.organizationStore.organizationDetails;
          }
        },
        methods: {
            displayProfile(){
                this.showOrganizationProfile = true;
                this.showVerificationDocuments = false;
            },
            displayVerificationDocuments(){
                this.showOrganizationProfile = false;
                this.showVerificationDocuments = true;
            },
            async updateVerification() {
              if (this.verificationStatus) {
                const payload = {
                  organizationId: this.organization.organizationId,
                  verificationStatus: this.verificationStatus,
                  verificationReason: this.verificationReason
                }
                await this.$store.dispatch('updateVerification', payload);
              }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../variables";

    nav{
        margin-top: 24px;
        .nav__item{
            margin-right: 40px;
            padding-bottom: 16px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            cursor: pointer;
        }
    }

    form, nav {
        display: flex;
    }

    .info__message{
        font-size: 16px;
        line-height: 22px;
        color: #1B1B1B;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #00E33A;
        border-radius: 100%;
        background: #fff;
    }

    .rejection:before{
        border: 1px solid #B00020 !important;
    }
    .rejection:after{
        background-color: #B00020 !important;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 12px;
        height: 12px;
        background: #00E33A;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    form{
        p {
            margin-right: 16px;
        }
        margin-top: 1rem;
    }

    .is-active{
        color: #00E33A;
        border-bottom: 2px solid #00E33A;
    }

    .btn-s{
        color: white;
        background: #1B1B1B;
        opacity: 0.5;
        padding: 0 32px;
        margin-top: 1rem;
    }
</style>
