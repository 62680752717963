<template>
    <DataTable :data-config="dataConfig"/>
</template>

<script>
import DataTable from "../DataTable";

export default {
        name: "Reports",
        components: {DataTable},
        created() {
            this.loadReportPosts();
        },
        methods: {
            loadReportPosts() {
                this.$cleanCityService.getReportPosts()
                    .then(litterReports => {
                            this.dataConfig.items = [];
                            for (let post of litterReports.litterReports) {
                                this.dataConfig.rows.push({
                                    id: post.litterReportId,
                                    selected: false,
                                    data: {
                                        litterReportId: post.litterReportId,
                                        points: post.points,
                                        comment: post.comment
                                    },
                                    callback: () => {
                                        this.$router.push({
                                            name: 'view-report-post',
                                            params: {litterReportId: post.litterReportId}
                                        });
                                    }

                                })
                            }

                        }
                    )
            }
        },
        data() {
            return {
                dataConfig: {
                    columns: [
                        "LitterReportId", "Points", "Comment"
                    ],
                    rows: [],
                    reload: () => {
                        this.loadReportPosts();
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
