<template>
    <div class="tracking__org">
        <div v-if="!showSingleOrganizationView">
            <h1 class="text-ls">Organizations</h1>
            <div>
                <div class="tracking__org--filters-container">
                    <img alt="Reload icon"  src="../../assets/reload-grey.svg">
                    <div class="tracking__org--filters">
                        <p class="label pointer" :class="{'underline bold': !filterByStatus && !filterFromDate && !filterToDate}" @click="clearAllFilters">All</p>
                        <Datetime v-model="filterFromDate"
                                  :phrases="{ok: 'Select'}"
                                  type="date"
                                  placeholder="Filter from date"
                                  format="dd-MM-yyyy"
                                  :max-datetime="today"
                                  input-style="
                                height: 30px;
                                padding: 7px 0 7px 3%;
                                border: none;
                                border-bottom: 1px solid #1B1B1B;
                                font-size: 12px;
                                line-height: 16px;
                                margin-right: 15px;
                                cursor: pointer">
                            <template slot="button-cancel">
                                <div @click="clearFromDate">
                                    Clear
                                </div>
                            </template>
                        </Datetime>
                        <Datetime v-model="filterToDate"
                                  :phrases="{ok: 'Select'}"
                                  type="date"
                                  placeholder="Filter to date"
                                  :max-datetime="today"
                                  format="dd-MM-yyyy"
                                  input-style="
                              height: 30px;
                              padding: 7px 0 7px 3%;
                              border: none;
                              border-bottom: 1px solid #1B1B1B;
                              font-size: 12px;
                              line-height: 16px;
                              margin-right: 15px;
                              cursor: pointer">
                            <template slot="button-cancel">
                                <div @click="clearToDate">
                                    Clear
                                </div>
                            </template>
                        </Datetime>
                    </div>
                </div>
                <div v-if="!loadingOrganizations">
                    <md-table :class="{  'is-selected' : selected.length > 0 }" v-model="filteredOrganizations" md-card @md-selected="onSelect">
                        <md-table-toolbar>
                            <h1 class="md-title"></h1>
                        </md-table-toolbar>
                        <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }" v-if="selected.length > 0">
                            <div class="md-toolbar-section-start">{{ getAlternateLabel(count) }}</div>
                            <div class="md-toolbar-section-end">
                                <md-button :mdRipple=true @click="updateVerification('APPROVED')">
                                    Approve
                                </md-button>
                                <md-button :mdRipple=false @click="updateVerification('REJECTED')">
                                    Reject
                                </md-button>
                                <md-button  :mdRipple=false>
                                    Delete
                                </md-button>
                                <md-button style="font-weight: normal;" @click="clearSelected">
                                    Cancel
                                </md-button>
                            </div>
                        </md-table-toolbar>
                        <md-table-row slot="md-table-row" style="z-index: 100" slot-scope="{ item }" :md-disabled="false" md-selectable="multiple" md-auto-select>
                            <md-table-cell class="table-text" md-label="Latest" md-sort-by="date">{{ item.date }}</md-table-cell>
                            <md-table-cell class="table-text" md-label="Organization" md-sort-by="organization">{{ item.organizationName }}</md-table-cell>
                            <md-table-cell class="table-text" md-label="Country/area" md-sort-by="amount">{{ item.country }}</md-table-cell>
                            <md-table-cell class="table-text bold" md-label="Status" md-sort-by="status"><p :class="{  'is-requested' : item.status === 'Requested'}">{{ item.status }}</p> </md-table-cell>
                            <!--                        <md-table-cell class="table-text" md-label="Status" md-sort-by="status"   >{{  formatStatus(item.status) }}</md-table-cell>-->
                            <md-table-cell class="table-text" md-label=""><span @click="showItem(item)"  style="color: #00C5FF; z-index: 10000; font-weight: bold; text-decoration-line: underline;">View</span></md-table-cell>
                        </md-table-row>
                    </md-table>
                </div>
            </div>
        </div>
        <div class="org__single--view" v-if="showSingleOrganizationView">
            <span class="button-back pointer" @click="showSingleOrganizationView = false">BACK</span>
            <ViewOrganizationDetails
                :organizationId="singleOrganizationToDisplay.organizationId"
            />
        </div>
    </div>
</template>

<script>
import {Datetime} from 'vue-datetime';
import ViewOrganizationDetails from "./ViewOrganizationDetails";
import moment from "moment";

export default {
        name: "TrackingOrganizations",
        components: {
            ViewOrganizationDetails,
            Datetime
        },
        async created() {
          this.loadingOrganizations = true;
          if (!this.$store.state.organizationStore.organizations.length) {
              await this.$store.dispatch('getAllOrganizations')
          }
          this.loadingOrganizations = false;
        },
        data() {
            return {
                selected: [],
                filterToDate: null,
                filterFromDate: null,
                filterByStatus: null,
                showSingleOrganizationView: false,
                singleOrganizationToDisplay: {},
                loadingOrganizations: false,
                today: new Date().toISOString(),
            }
        },
        computed: {
            organizations() {
                return this.$store.getters.organizationsWithRegisteredDate;
            },
            filteredOrganizations() {
                if (this.filterToDate || this.filterFromDate || this.filterByStatus ) {
                    return this.applyFilters();
                }
                return this.organizations;
            },
        },
        methods: {
            getAlternateLabel (count) {
                let plural = ''
                if (count > 1) {
                    plural = 's'
                }
                return `${count} organization${plural} selected`
            },
            clearAllFilters() {
              this.filterToDate = null;
              this.filterFromDate = null;
              this.filterByStatus = null;
            },
            clearFromDate() {
                this.filterFromDate = null;
            },
            clearToDate() {
                this.filterToDate = null;
            },
            onSelect (items) {
                this.selected = items
            },
            showItem(item){
                this.singleOrganizationToDisplay = item;
                this.showSingleOrganizationView = true;
            },

            applyFilters() {
                let organizations = [...this.organizations];
                if (this.filterByStatus) {
                  organizations = organizations.filter(org => {
                      return org.status == this.filterByStatus;
                  })
                }
                if (this.filterFromDate && this.filterToDate) {
                    organizations = organizations.filter(org => {
                      return moment(org.registered).isBetween(this.filterFromDate , this.filterToDate, 'day', '[]')
                    });
                }
                if (this.filterFromDate && !this.filterToDate) {
                  organizations = organizations.filter(org => {
                      return moment(org.registered).isAfter(this.filterFromDate)
                  })
                }
                if (this.filterToDate && !this.filterFromDate) {
                  organizations = organizations.filter(org => {
                    return moment(org.registered).isBefore(this.filterFromDate);
                  })
                }

                return organizations;
            },
            clearSelected() {
              this.selected = [];
            },
            async updateVerification(status) {
                for(let org of this.selected) {
                    await this.$store.dispatch('updateVerification', {organizationId: org.organizationId, verificationStatus: status});
                }
                this.clearSelected();
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../variables";

    h1{
        font-style: normal;
        font-weight: bold;
        color: #1B1B1B;
        margin-bottom: 35px;
    }

    .tracking__org{
        padding: 24px;
        &--filters-container{
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &--filters{
            display: flex;
            width: 80%;
            justify-content: flex-end;
            p{
                margin: 8px 25px 0 0;
            }
        }
        &--filters-status{
            height: 30px;
            padding: 7px 3%;
            color: #4A4A4A;
            border: none;
            border-bottom: 1px solid #1B1B1B;
            font-size: 12px;
            line-height: 16px;
            margin-right: 15px;
            cursor: pointer;
        }
    }

    .md-table + .md-table {
        margin-top: 16px
    }

    .md-table-toolbar .md-button {
        background: transparent ;
        border-radius: 0 ;
        font-weight: bold;
        font-size: 12px ;
        line-height: 16px ;
        text-decoration-line: underline;
        color: #1B1B1B;
        text-transform: none;
        margin: 0;
    }

     .md-toolbar-section-start{
        justify-content: unset ;
        order: unset ;
        width: 20% ;
        flex: unset ;
    }

    .md-toolbar-section-end{
        justify-content: unset;
    }

    .table-text{
        font-size: 12px;
        p{
            margin: 0;
        }
    }

    .button-back{
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        text-decoration-line: underline;
    }

    .md-toolbar{
        background-color: #00E33A;
    }

    .md-table.is-selected ::v-deep .md-table-toolbar {
        background-color: #00E33A !important;
    }

    .md-table ::v-deep .md-table-head-label{
        color: #9B9B9B;
        font-weight: normal;
    }

    .md-table ::v-deep .md-theme-default.md-checked .md-checkbox-container{
        background-color: #00E33A;
        border-color:  #00E33A;
    }

    .md-table ::v-deep .is-requested{
        color: #00E33A !important;
    }

    .org__single--view{
        padding: 16px 2.7%
    }

</style>
