<template>
  <ContentView :content-config="contentConfig" />
</template>

<script>
import ContentView from "../ContentView";

export default {
  name: "ViewCleanedPost",
  components: { ContentView },
  created() {
    this.$cleanCityService
      .getReportPost(this.$route.params.litterReportId)
      .then((reportPost) => {
        this.contentConfig.sections[0].data.litterReportId.value =
          reportPost.litterReportId;
        this.contentConfig.sections[0].data.points.value = reportPost.points;
        this.contentConfig.sections[0].data.comment.value = reportPost.comment;
        //this.contentConfig.sections[0].data.contactPerson.value = facility.contactPerson;
        this.contentConfig.sections[0].data.lat.value = reportPost.lat;
        this.contentConfig.sections[0].data.lng.value = reportPost.lng;
        this.contentConfig.sections[0].data.pictures.values =
          reportPost.pictures;
      });
  },
  data() {
    return {
      contentConfig: {
        sections: [
          {
            type: "info",
            title: "Report Post Info",
            data: {
              litterReportId: {
                title: "Report Post ID",
                value: "",
              },
              points: {
                title: "Points",
                value: "",
              },
              comment: {
                title: "Comment",
                value: "",
              },
              lat: {
                title: "Latitude",
                value: "",
              },
              lng: {
                title: "Longitude",
                value: "",
              },

              test: {
                title: "Pictures",
                type: "multiple",
                values: [],
                typeDefinition: "img",
              },
              pictures: {
                title: "",
                type: "multiple",
                values: [],
                typeDefinition: "img",
              },
            },
          },
        ],
      },
    };
  },
};
</script>


<style scoped>
</style>