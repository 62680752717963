<template>
    <div>
        <Modal :close-callback="closeCallback" v-if="showModal">
            <OrganizationSearch :select-callback="addUserToOrganization"/>
        </Modal>
        <ContentInput :content-config="contentConfig"/>
    </div>
</template>

<script>
import ContentInput from "../ContentInput";
import Modal from "../Modal";
import OrganizationSearch from "../OrganizationSearch";

export default {
        name: "EditUser",
        components: {ContentInput, OrganizationSearch, Modal},
        data() {
          return {
            phoneNumberObj: {},
            showModal: false,
            contentConfig: {
              title: "Edit User",
              buttons: [
                {
                  'title': "Back",
                  callback: () => {
                    this.$router.push({
                      name: 'view-user',
                      params: {userId: this.$route.params.userId}
                    })
                  }
                },
                {
                  title: "Save",
                  isDark: true,
                  callback: async () => {
                    const userInfoInputs = this.contentConfig.sections.userInfo.inputs;
                    const userUpdateObj = {
                      firstName: userInfoInputs.firstName.value,
                      lastName: userInfoInputs.lastName.value,
                      country: userInfoInputs.country.value,
                      countryCode: this.phoneNumberObj.countryCallingCode,
                      phoneNumber: this.phoneNumberObj.nationalNumber
                    };

                    await this.$userService.updateUserById(this.$route.params.userId, userUpdateObj);
                    await this.$router.push({
                      name: 'view-user',
                      params: {userId: this.$route.params.userId}
                    })
                  }
                }
              ],
              sections: {
                "userInfo": {
                  title: "User Info:",
                  type: "info",
                  inputs: {
                    "firstName": {
                      title: "First Name",
                      value: "",
                    },
                    "lastName": {
                      title: "Last Name",
                      value: "",
                    },
                    "email": {
                      title: "Email",
                      type: "email",
                      value: "",
                    },
                    "country": {
                      title: "Country",
                      value: "",
                    },
                    "phoneNumber": {
                      title: "Phone number",
                      type: "phonenumber",
                      countryCode: "",
                      phoneNumber: "",
                    },
                  }
                },
                "organizations" : {
                  type: "table",
                  buttons: [
                    {
                      title: "Add To Organization",
                      callback: () => {
                        this.showModal = true;
                      }
                    },
                  ],
                  columns: ["Org Name", "Org Number", "Org ID"],
                  rows: []
                }
              },
            }
          }
      },

        async mounted() {
          await this.loadUser();
          this.$on('phone_number_updated', function(phoneNumberObj){
            this.phoneNumberObj = phoneNumberObj;
          });
        },
        methods: {
            closeCallback() {
                this.showModal = false;
            },
            async loadUser() {
              const userInfo = await this.$userService.getUserById(this.$route.params.userId);
              this.contentConfig.sections.userInfo.inputs.firstName.value = userInfo.firstName;
              this.contentConfig.sections.userInfo.inputs.lastName.value = userInfo.lastName;
              this.contentConfig.sections.userInfo.inputs.email.value = userInfo.email;
              this.contentConfig.sections.userInfo.inputs.country.value = userInfo.country;
              this.contentConfig.sections.userInfo.inputs.phoneNumber.phoneNumber = userInfo.phoneNumber;
              this.contentConfig.sections.userInfo.inputs.phoneNumber.countryCode = userInfo.countryCode;

                this.contentConfig.sections.organizations.rows.push({
                  id: userInfo.organization.organizationId,
                  data: [userInfo.organization.orgName, userInfo.organization.orgNumber, userInfo.organization.organizationId],
                  callback: async () => {
                    const confirmation = confirm("Are you sure you want to remove this organization from the user?");
                    if (confirmation) {
                      await this.removeUserFromOrganization(userInfo.organization.organizationId);
                    }
                  }
                })
            },
            async addUserToOrganization(org) {
                this.showModal = false;
                await this.$organizationsService.addUserToOrganization(org.organizationId, this.$route.params.userId);
                await this.loadUser();
            },
            async removeUserFromOrganization(organizationId) {
              await this.$organizationsService.removeUserFromOrganization(organizationId, this.$route.params.userId);
              await this.$router.push({
                name: 'view-user',
                params: {userId: this.$route.params.userId}
              })
            }
        },

    }
</script>

<style scoped>

</style>
