<template>
    <div v-if="!isLoading">
        <h2 class="text-ls bold">Sum up：</h2>
        <ul>
            <li class="label">
                <p> Plastic Credits (KGs):</p>
                <p>{{certificate.balance}}</p>
            </li>
            <li class="label">
                <p>Organization:</p>
                <p>{{certificate.orgName}}</p>
            </li>
            <li class="label">
                <p>Facility:</p>
                <p>{{certificate.currentOwnerName}}</p>
            </li>
            <li class="label">
                <p>Country:</p>
                <p>{{certificate.country}}</p>
            </li>
            <li class="label">
                <p>Material Type:</p>
                <div class="material-type">
                    <p class="label bold text-align-right">{{ certificate.massBalanceDefinition.plasticType }}</p>
                    <ul class="material-type__list">
                        <li v-for="(description, index) in transform(certificate.massBalanceDefinition)" :key="index" class="material-type__list-item" >
                            <p class="text-align-right label" v-if="description !== certificate.massBalanceDefinition[ 'material origin' ]">· {{description}}</p>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="label">
                <p>Material Origin：</p>
                <p>
                    <span v-if="certificate.massBalanceDefinition.materialOrigin || certificate.massBalanceDefinition[ 'material origin' ]"> {{certificate.massBalanceDefinition.materialOrigin || certificate.massBalanceDefinition[ 'material origin' ]}}</span>
                    <span v-else>--</span>
                </p>
            </li>
            <md-divider class="divider"/>
            <li class="label">
                <p>Paid out:</p>
                <div class="text-align-right" v-if="showSum">
                    <div v-for="(amount, currency) in totalPlasticCreditPaidOutAmount" :key="currency">
                        <p>{{amount}} {{currency}}</p>
                    </div>
                    <p v-if="!Object.keys(totalPlasticCreditPaidOutAmount).length">--</p>
                    <p v-else class="blue-bold-text pointer"  @click="showSum = false">see details</p>
                </div>
                <div class="text-align-right" v-else>
                    <div v-for="(cleanup,index) in certificate.cleanups" :key="index">
                        <p v-if="cleanup.payment">{{formatDate(cleanup.manualDateTime)}} {{ cleanup.payment.amount }} {{ cleanup.payment.currency }}</p>
                    </div>
                    <p class="blue-bold-text pointer" @click="showSum = true">see sum</p>
                </div>
            </li>
            <md-divider class="divider"/>
            <li class="label">
                <p>Date Issued:</p>
                <div class="flex-date">
                    <p>{{ formatDate(certificate.cleanups[0].issuedDate) }}</p>
                    <p v-if="certificate.cleanups.length > 1">&nbsp;-&nbsp;{{ formatDate(certificate.cleanups[certificate.cleanups.length - 1].issuedDate) }}</p>
                </div>
            </li>
            <md-divider class="divider"/>
            <li class="label">
                <p>Organization info:</p>
                <p v-if="organizationInfo" class="text-align-right more-info">{{organizationInfo}}</p>
                <p v-else class="text-align-right more-info">--</p>
            </li>
            <li class="label">
                <p>Facility Location:</p>
                <p class="text-align-right more-info" v-if="facilityLongitude && facilityLatitude">
                    <ReverseGeoCoding :lat="facilityLatitude" :lng="facilityLongitude"/>
                </p>
            </li>
            <li class="label">
                <p>Facility image:</p>
                <span v-if="!facility.profilePicture">--</span>
                <p v-else><img :src="facility.profilePicture" alt="Facility image" class="facility-image"/>
                </p>
            </li>
            <md-divider class="divider"/>
        </ul>
        <section>
            <h2>Clean up details：</h2>
            <SingleCertificateView
                    v-if="certificate.cleanups[0]"
                    :certificate="certificate.cleanups"
                    :isEditable="false"
            />
            <p v-else>No cleanup details found.</p>
        </section>
    </div>
</template>

<script>
import SingleCertificateView from "./SingleCertificateView";
import ReverseGeoCoding from "@/components/map/ReverseGeoCoding";
import moment from "moment";
import transform from "@/mixins/transformWasteDefinitionForDisplay";

export default {
        name: "PlasticCreditsBuySingleView",
        components: {SingleCertificateView, ReverseGeoCoding},
        mixins: [transform],
        props: {
            certificate: {
                type: Object
            },
            orgId: {
                type: String
            },
            facilityId: {
                type: String
            }
        },
        async mounted() {
            if (!this.$store.state.facilityStore.facilities.length) {
                // if admin needs one facility, let's just load all facilities once, place in store, and let them access as needed
                await this.$store.dispatch('getAllFacilities')
            }
            this.isLoading = false
        },
        data(){
            return{
                isLoading: true,
                showSum: true,
            }
        },
        computed: {
            totalPlasticCreditPaidOutAmount(){
                let sumHash = {}
                for (let cleanup of this.certificate.cleanups) {
                    if (cleanup.payment) {
                        sumHash[cleanup.payment.currency] ? sumHash[cleanup.payment.currency] += cleanup.payment.amount : sumHash[cleanup.payment.currency] = cleanup.payment.amount
                    }
                }
                return sumHash
            },
            organizationInfo() {
                let org = this.$store.getters.returnOneOrganizationDetails(this.orgId)
                return org.aboutOrganization
            },
            facility() {
                return this.$store.getters.returnOneFacilityDetails(this.facilityId)
            },
            facilityLatitude() {
                if(this.facility.location){
                    return this.facility.location.coordinates[1]
                } else return null
            },
            facilityLongitude() {
                if(this.facility.location){
                    return this.facility.location.coordinates[0]
                } else return null
            },
        },
        methods: {
            formatDate(date) {
                return moment.unix(date / 1000).format('DD-MM-YYYY')
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/variables";

    h2{
        padding: 32px 0 16px;
        color: #1B1B1B;
    }

    ul{
        list-style: none;
        max-width: 400px;
    }

    li{
        display: flex;
        justify-content: space-between;
        font-weight: bold;
    }

    .label{
        padding-bottom: 8px;
    }

    .divider {
        background-color: $placeholder-grey;
        margin-bottom: 8px;
    }
    .material-type__list-item{
        display: block;
    }

    .material-type__list p {
        width: 100%;
        font-weight: normal;
    }

    .more-info{
        max-width: 55%;
    }

    .facility-image{
        max-height: 70px;
        max-width: 200px;
    }

    .flex-date {
      display: flex;
      flex-direction: row;
    }
</style>

