<template>
    <DataTable :data-config="dataConfig"/>
</template>

<script>
import DataTable from "../DataTable";

export default {
        name: "Redeemables",
        components: {DataTable},
        created() {
           this.loadRedeemables(); 
        },
        methods: {
            loadRedeemables() {
                this.$cleanCityService.getRedeemables()
                    .then(redeemables => {
                    this.dataConfig.items = [];
                    for (let aRedeemable of redeemables.redeemables) {
                        this.dataConfig.rows.push({
                            id: aRedeemable.redeemableId,
                            selected: false,
                            data: {
                                redeemableId: aRedeemable.redeemableId,
                                type: aRedeemable.type,
                                name: aRedeemable.name,
                                goal: aRedeemable.goal,
                                description: aRedeemable.description
                            },
                            callback: () => {
                                this.$router.push({
                                    name: 'view-redeemable',
                                    params: {redeemableId: aRedeemable.redeemableId}
                                });
                            }
                            
                        })
                    }

                }
              )
            }
        },
        data() {
            return {
                dataConfig: {
                    columns: [
                        "RedeemableId","Type" ,"Name", "Goal", "Description"
                    ],
                    rows: [],
                    reload: () => {
                            this.loadRedeemables();
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
