<template>
    <div>
        <ContentInput :content-config="contentConfig"/>
    </div>
</template>

<script>
import ContentInput from "../ContentInput";

export default {
        name: "CreateVoucherDefinition",
        components: {ContentInput},
        data() {
            return {
                contentConfig: {
                    title: "Create voucher definition",
                    buttons: [
                        {
                            title: "Create voucher definition",
                            isDark: true,
                            callback: () => {
                                const organizationId = this.contentConfig.sections.voucherInfo.inputs.organizationId.value
                                const voucherName = this.contentConfig.sections.voucherInfo.inputs.voucherName.value
                                const pictureURL = this.contentConfig.sections.voucherInfo.inputs.pictureURL.value
                                const adminToken = this.contentConfig.sections.voucherInfo.inputs.adminToken.value

                                if (!organizationId || !voucherName || !pictureURL || !adminToken) {
                                    alert('Missing field(s)')
                                    return
                                }

                                this.$depositAdminClient.createVoucherDefinition({
                                    organizationID: organizationId,
                                    name: voucherName,
                                    pictureURL: pictureURL
                                }, adminToken)
                                    .then(() => {
                                        alert("Success!")
                                        this.$router.push({name: 'deposit-organizations'})
                                    })
                                    .catch(err => {
                                        console.error(err)
                                        alert(err);
                                    })
                            }
                        }
                    ],
                    sections: {
                        "voucherInfo": {
                            title: "Voucher Definition Info:",
                            type: "info",
                            inputs: {
                                "organizationId": {
                                    title: "OrganizationID",
                                    type: "text",
                                    value: this.$route.params.organizationId,
                                },
                                "voucherName": {
                                    title: "Voucher Name",
                                    type: "text",
                                    value: undefined,
                                },
                                "pictureURL": {
                                    title: "Picture URL",
                                    type: "text",
                                    value: undefined,
                                },
                                "adminToken": {
                                    title: "Admin token",
                                    type: "text",
                                    value: undefined,
                                }
                            }
                        }
                    }
                }
            }
        },
    }
</script>

<style scoped>

</style>
