<template lang="html">
    <div class="category">
        <p class="heading">Delterra Pilot - Category Management</p>

        <div class="mainDiv">
            <div>
                <p class="subTitle , marginBottom">Default categories <button class="btnLink" style="float: right; margin-right: 10px;" @click="enableReOrder(true)">{{(activedCategories[0] && activedCategories[0].length > 0) ? defaultDisableDraggable == true ? 'Reorder' : 'Save':""}}</button></p>
                <div>
                    <ul class="marginBottom" style="list-style-type: none;">
                      <draggable v-model="activedCategories[0]" :options="{disabled : defaultDisableDraggable}">
                        <li class="marginBottom" v-for="(activedCategory, index) in activedCategories[0]" :key="index">
                            <div class="section">
                                <div class="collapsItem title">
                                    <img height="10" src="../../assets/right_arrow.svg" style="margin-right: 10px;" alt=""/>
                                    {{activedCategories[0][index].materialName}}
  
                                    <button class="btnLink" style="float: right;" @click="deleteCategory(index , true)">Delete</button>
                                    <button class="btnLink" style="float: right; margin-right: 10px;" @click="moveCategory(index , true)">Move to the optionals</button>
                                    <button class="btnLink" style="float: right; margin-right: 10px;" @click="duplicateCategory(index , true)">Duplicate</button>
                                    <button class="btnLink" style="float: right; margin-right: 10px;" @click="toggle(activedCategories[0][index] ,index , true)">{{(defaultShowHide[index] && defaultShowHide[index].isShow == false) ? 'Edit' : 'Cancel'}}</button>
                                </div>

                                <div class="body" v-show="defaultShowHide[index] && defaultShowHide[index].isShow">
                                    <div :style="addCategoryStyle">
                                        <div class="divStyle">
                                            <p class="categoryHeading , marginBottom">Update Category</p>
                                            <input type="text" placeholder="Category name" class="marginBottom inputStyle" v-model="updateCategoryText" required />
                                            <div class="subDivStyle">
                                                <ul class="marginBottom" style="list-style-type: none;" v-for="(defaultSubCategory , index) in defaultSubCategories" :key="index">
                                                    <li>
                                                        <div>
                                                            <div v-if="defaultSubCategories.length - 1 === index || defaultSubCategories.length === 1">
                                                                <div v-if="defaultSubCategory.value !== 'newSubCategory' && defaultSubCategory.value">
                                                               
                                                                    <div class="flexContainer , inputStyle">
                                                                                        <input
                                                                                            style="border: none;"
                                                                                            placeholder="value name"
                                                                                            v-model="defaultSubCategory.value"
                                                                                            :readonly="defaultSubCategory.edit === false"
                                                                                            type="text"
                                                                                            class="inputField"
                                                                                        />
                                                                                        <input
                                                                                            type="button"
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="defaultSubCategory.edit === false ?enableEditSubCategory(index):disableEditSubCategory(index , defaultSubCategory.value)"
                                                                                            :value="defaultSubCategory.edit === false ?'Edit':'Save'"
                                                                                        />
                                                                                        <button
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="deleteDefaultValue(index , defaultSubCategory.key , defaultSubCategory.value ,true)"
                                                                                        >
                                                                                            Delete
                                                                                        </button>
                                                                                    </div>
                                                                </div>
                                                                <div v-else>
                                                                    <input type="text" placeholder="Subcategory name" class="inputStyle" v-model="subCategoryName" required />
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                              
                                                                            <div class="flexContainer , inputStyle">
                                                                                        <input
                                                                                            style="border: none;"
                                                                                            placeholder="value name"
                                                                                            v-model="defaultSubCategory.value"
                                                                                            :readonly="defaultSubCategory.edit === false"
                                                                                            type="text"
                                                                                            class="inputField"
                                                                                        />
                                                                                        <input
                                                                                            type="button"
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="defaultSubCategory.edit === false ? enableEditSubCategory(index) : disableEditSubCategory(index , defaultSubCategory.value)"
                                                                                            :value="defaultSubCategory.edit === false ? 'Edit' : 'Save'"
                                                                                        />
                                                                                        <button
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="deleteDefaultValue(index , defaultSubCategory.key , defaultSubCategory.value ,true)"
                                                                                        >
                                                                                            Delete
                                                                                        </button>
                                                                                    </div>

                                                            </div>

                                                            <div class="divStyle1">
                                                                <ul class="marginBottom" style="list-style-type: none;" v-for="(defaultSubCategoriesValue ,index) in defaultSubCategoriesValues" :key="index">
                                                                    <li>
                                                                        <div v-if="defaultSubCategoriesValue.key === defaultSubCategory.key">
                                                                            <div v-if="(defaultSubCategoriesValues.length - 1 === index && defaultSubCategoriesValue.value === 'newValue') || defaultSubCategoriesValues.length === 1">
                                                                                <div v-if="defaultSubCategoriesValue.value !== 'newValue'">
                                                                                    <div class="flexContainer , inputStyle">
                                                                                        <input
                                                                                            style="border: none;"
                                                                                            placeholder="value name"
                                                                                            v-model="defaultSubCategoriesValue.value"
                                                                                            :readonly="defaultSubCategoriesValue.edit === false"
                                                                                            type="text"
                                                                                            class="inputField"
                                                                                        />
                                                                                        <input
                                                                                            type="button"
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="defaultSubCategoriesValue.edit === false ? enableEdit(index) : disableEdit(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value)"
                                                                                            :value="defaultSubCategoriesValue.edit === false ? 'Edit' : 'Save'"
                                                                                        />
                                                                                        <button
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="deleteDefaultValue(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value , false)"
                                                                                        >
                                                                                            Delete
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div v-else>
                                                                                    <input type="text" placeholder="value name" class="inputStyle" v-model="subValueText" required />
                                                                                </div>
                                                                            </div>
                                                                            <div v-else>
                                                                                <div v-if="defaultSubCategoriesValue.value !== 'newValue'">
                                                                                    <div class="flexContainer , inputStyle">
                                                                                        <input
                                                                                            style="border: none;"
                                                                                            placeholder="value name"
                                                                                            v-model="defaultSubCategoriesValue.value"
                                                                                            :readonly="defaultSubCategoriesValue.edit === false"
                                                                                            type="text"
                                                                                            class="inputField"
                                                                                        />
                                                                                        <input
                                                                                            type="button"
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="defaultSubCategoriesValue.edit === false ? enableEdit(index) : disableEdit(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value)"
                                                                                            :value="defaultSubCategoriesValue.edit === false ? 'Edit' : 'Save'"
                                                                                        />
                                                                                        <button
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="deleteDefaultValue(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value , false)"
                                                                                        >
                                                                                            Delete
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>

                                                                <p class="inputStyle1" @click="addDefaultValue(defaultSubCategory.key , false , true)">Add a value</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <p class="marginBottom text1" @click="addDefaultSubCategory(true)"><img height="12" src="../../assets/ic_plus.svg" alt="" /> Add a subcategory</p>
                                                <button class="main-black-button" @click="updateNewCategory(true)">UPDATE</button>
                                                <button class="main-white-button" @click="toggle(activedCategories[0][index] ,index , true)">CANCEL</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        </draggable>
                    </ul>
                </div>

                <div @click="addNewActiveCategory(true)" v-if="seeAddActiveCategoryButton">
                    <p class="text1"><img height="12" src="../../assets/ic_plus.svg" alt=""/> Add a category</p>
                </div>
                <div v-if="seeAddActiveCategory" :style="addCategoryStyle">
                    <div class="divStyle">
                        <p class="categoryHeading , marginBottom">New Category</p>
                        <input type="text" placeholder="Category name" class="marginBottom inputStyle" v-model="newCategoryText" required />
                        <div class="subDivStyle">
                            <ul class="marginBottom" style="list-style-type: none;" v-for="(defaultSubCategory , index) in defaultSubCategories" :key="index">
                                <li>
                                    <div>
                                        <div v-if="defaultSubCategories.length - 1 == index || defaultSubCategories.length == 1">
                                            <input type="text" placeholder="Subcategory name" class="inputStyle" v-model="subCategoryName" required />
                                        </div>
                                        <div v-else>
                                           
                                             <div class="flexContainer , inputStyle">
                                                                                        <input
                                                                                            style="border: none;"
                                                                                            placeholder="value name"
                                                                                            v-model="defaultSubCategory.value"
                                                                                            :readonly="defaultSubCategory.edit == false"
                                                                                            type="text"
                                                                                            class="inputField"
                                                                                        />
                                                                                        <input
                                                                                            type="button"
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="defaultSubCategory.edit == false ?enableEditSubCategory(index):disableEditSubCategory(index , defaultSubCategory.value)"
                                                                                            :value="defaultSubCategory.edit == false ?'Edit':'Save'"
                                                                                        />
                                                                                        <button
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="deleteDefaultValue(index , defaultSubCategory.key , defaultSubCategory.value , true)"
                                                                                        >
                                                                                            Delete
                                                                                        </button>
                                                                                    </div>

                                        </div>

                                        <div class="divStyle1">
                                            <ul class="marginBottom" style="list-style-type: none;" v-for="(defaultSubCategoriesValue ,index) in defaultSubCategoriesValues" :key="index">
                                                <li>
                                                    <div v-if="defaultSubCategoriesValue.key == defaultSubCategory.key">
                                                        <div v-if="(defaultSubCategoriesValues.length - 1 == index && defaultSubCategoriesValue.value == 'newValue') || defaultSubCategoriesValues.length == 1">
                                                            <div v-if="defaultSubCategoriesValue.value != 'newValue'">
                                                                <div class="flexContainer , inputStyle">
                                                                    <input
                                                                        style="border: none;"
                                                                        placeholder="value name"
                                                                        v-model="defaultSubCategoriesValue.value"
                                                                        :readonly="defaultSubCategoriesValue.edit == false"
                                                                        type="text"
                                                                        class="inputField"
                                                                    />
                                                                    <input
                                                                        type="button"
                                                                        class="btnLink"
                                                                        style="margin-right: 10px;"
                                                                        @click="defaultSubCategoriesValue.edit == false ?enableEdit(index):disableEdit(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value)"
                                                                        :value="defaultSubCategoriesValue.edit == false ?'Edit':'Save'"
                                                                    />
                                                                    <button class="btnLink" style="margin-right: 10px;" @click="deleteDefaultValue(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value , false)">Delete</button>
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                <input type="text" placeholder="value name" class="inputStyle" v-model="subValueText" required />
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <div v-if="defaultSubCategoriesValue.value != 'newValue'">
                                                                <div class="flexContainer , inputStyle">
                                                                    <input
                                                                        style="border: none;"
                                                                        placeholder="value name"
                                                                        v-model="defaultSubCategoriesValue.value"
                                                                        :readonly="defaultSubCategoriesValue.edit == false"
                                                                        type="text"
                                                                        class="inputField"
                                                                    />
                                                                    <input
                                                                        type="button"
                                                                        class="btnLink"
                                                                        style="margin-right: 10px;"
                                                                        @click="defaultSubCategoriesValue.edit == false ?enableEdit(index):disableEdit(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value)"
                                                                        :value="defaultSubCategoriesValue.edit == false ?'Edit':'Save'"
                                                                    />
                                                                    <button class="btnLink" style="margin-right: 10px;" @click="deleteDefaultValue(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value , false)">Delete</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>

                                            <p class="inputStyle1" @click="addDefaultValue(defaultSubCategory.key , false ,false)">Add a value</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <p class="marginBottom text1" @click="addDefaultSubCategory(false)"><img height="12" src="../../assets/ic_plus.svg" /> Add a subcategory</p>
                            <button class="main-black-button" @click="addNewCategory(true)">SAVE</button>
                            <button class="main-white-button" @click="toggleSeeActiveCategory">CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <hr />
            <br />
            <div>
                <p class="subTitle , marginBottom">Optional categories <button class="btnLink" style="float: right; margin-right: 10px;" @click="enableReOrder(false)">{{( hiddenCategories[0] && hiddenCategories[0].length > 0)?optionalDisableDraggable == true ? 'Reorder' : 'Save':""}}</button></p>

                <div>
                    <ul class="marginBottom" style="list-style-type: none;">
                      <draggable v-model="hiddenCategories[0]" :options="{disabled : optionalDisableDraggable}">
                        <li class="marginBottom" v-for="(hiddenCategory, index) in hiddenCategories[0]" :key="index">
                            <div class="section">
                                <div class="collapsItem title">
                                    <img height="10" src="../../assets/right_arrow.svg" style="margin-right: 10px;" />
                                    {{hiddenCategories[0][index].materialName}}

                                    <button class="btnLink" style="float: right;" @click="deleteCategory(index , false)">Delete</button>
                                    <button class="btnLink" style="float: right; margin-right: 10px;" @click="moveCategory(index , false)">Move to the default</button>
                                    <button class="btnLink" style="float: right; margin-right: 10px;" @click="duplicateCategory(index , false)">Duplicate</button>
                                    <button class="btnLink" style="float: right; margin-right: 10px;" @click="toggle(hiddenCategories[0][index] ,index , false)">{{(optionalShowHide[index] && optionalShowHide[index].isShow == false) ? 'Edit' : 'Cancel'}}</button>
                                </div>

                                <div class="body" v-show="optionalShowHide[index] && optionalShowHide[index].isShow">
                                    <div :style="addCategoryStyle">
                                        <div class="divStyle">
                                            <p class="categoryHeading , marginBottom">Update Category</p>
                                            <input type="text" placeholder="Category name" class="marginBottom inputStyle" v-model="updateCategoryText" required />
                                            <div class="subDivStyle">
                                                <ul class="marginBottom" style="list-style-type: none;" v-for="(defaultSubCategory , index) in defaultSubCategories" :key="index">
                                                    <li>
                                                        <div>
                                                            <div v-if="defaultSubCategories.length - 1 == index || defaultSubCategories.length == 1">
                                                                <div v-if="defaultSubCategory.value != 'newSubCategory' && defaultSubCategory.value">
                                                                    
                                                                     <div class="flexContainer , inputStyle">
                                                                                        <input
                                                                                            style="border: none;"
                                                                                            placeholder="value name"
                                                                                            v-model="defaultSubCategory.value"
                                                                                            :readonly="defaultSubCategory.edit == false"
                                                                                            type="text"
                                                                                            class="inputField"
                                                                                        />
                                                                                        <input
                                                                                            type="button"
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="defaultSubCategory.edit == false ?enableEditSubCategory(index):disableEditSubCategory(index , defaultSubCategory.value)"
                                                                                            :value="defaultSubCategory.edit == false ?'Edit':'Save'"
                                                                                        />
                                                                                        <button
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="deleteDefaultValue(index , defaultSubCategory.key , defaultSubCategory.value , true)"
                                                                                        >
                                                                                            Delete
                                                                                        </button>
                                                                                    </div>

                                                                </div>
                                                                <div v-else>
                                                                    <input type="text" placeholder="Subcategory name" class="inputStyle" v-model="subCategoryName" required />
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                               
                                                                 <div class="flexContainer , inputStyle">
                                                                                        <input
                                                                                            style="border: none;"
                                                                                            placeholder="value name"
                                                                                            v-model="defaultSubCategory.value"
                                                                                            :readonly="defaultSubCategory.edit == false"
                                                                                            type="text"
                                                                                            class="inputField"
                                                                                        />
                                                                                        <input
                                                                                            type="button"
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="defaultSubCategory.edit == false ?enableEditSubCategory(index):disableEditSubCategory(index , defaultSubCategory.value)"
                                                                                            :value="defaultSubCategory.edit == false ?'Edit':'Save'"
                                                                                        />
                                                                                        <button
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="deleteDefaultValue(index , defaultSubCategory.key , defaultSubCategory.value , true)"
                                                                                        >
                                                                                            Delete
                                                                                        </button>
                                                                                    </div>

                                                            </div>

                                                            <div class="divStyle1">
                                                                <ul class="marginBottom" style="list-style-type: none;" v-for="(defaultSubCategoriesValue ,index) in defaultSubCategoriesValues" :key="index">
                                                                    <li>
                                                                        <div v-if="defaultSubCategoriesValue.key == defaultSubCategory.key">
                                                                            <div v-if="(defaultSubCategoriesValues.length - 1 == index && defaultSubCategoriesValue.value == 'newValue') || defaultSubCategoriesValues.length == 1">
                                                                                <div v-if="defaultSubCategoriesValue.value != 'newValue'">
                                                                                    <div class="flexContainer , inputStyle">
                                                                                        <input
                                                                                            style="border: none;"
                                                                                            placeholder="value name"
                                                                                            v-model="defaultSubCategoriesValue.value"
                                                                                            :readonly="defaultSubCategoriesValue.edit == false"
                                                                                            type="text"
                                                                                            class="inputField"
                                                                                        />
                                                                                        <input
                                                                                            type="button"
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="defaultSubCategoriesValue.edit == false ?enableEdit(index):disableEdit(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value)"
                                                                                            :value="defaultSubCategoriesValue.edit == false ?'Edit':'Save'"
                                                                                        />
                                                                                        <button
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="deleteDefaultValue(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value , false)"
                                                                                        >
                                                                                            Delete
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div v-else>
                                                                                    <input type="text" placeholder="value name" class="inputStyle" v-model="subValueText" required />
                                                                                </div>
                                                                            </div>
                                                                            <div v-else>
                                                                                <div v-if="defaultSubCategoriesValue.value != 'newValue'">
                                                                                    <div class="flexContainer , inputStyle">
                                                                                        <input
                                                                                            style="border: none;"
                                                                                            placeholder="value name"
                                                                                            v-model="defaultSubCategoriesValue.value"
                                                                                            :readonly="defaultSubCategoriesValue.edit == false"
                                                                                            type="text"
                                                                                            class="inputField"
                                                                                        />
                                                                                        <input
                                                                                            type="button"
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="defaultSubCategoriesValue.edit == false ?enableEdit(index):disableEdit(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value)"
                                                                                            :value="defaultSubCategoriesValue.edit == false ?'Edit':'Save'"
                                                                                        />
                                                                                        <button
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="deleteDefaultValue(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value , false)"
                                                                                        >
                                                                                            Delete
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>

                                                                <p class="inputStyle1" @click="addDefaultValue(defaultSubCategory.key , false, true)">Add a value</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <p class="marginBottom text1" @click="addDefaultSubCategory(true)"><img height="12" src="../../assets/ic_plus.svg" /> Add a subcategory</p>
                                                <button class="main-black-button" @click="updateNewCategory(false)">UPDATE</button>
                                                <button class="main-white-button" @click="toggle(hiddenCategories[0][index] ,index ,false)">CANCEL</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        </draggable>
                    </ul>
                </div>

                <div @click="addNewActiveCategory(false)" v-if="seeAddHiddenCategoryButton">
                    <p class="text1"><img height="12" src="../../assets/ic_plus.svg" /> Add a category</p>
                </div>
                <div v-if="seeAddHiddenCategory" id="hide" :style="addCategoryStyle">
                    <div class="divStyle">
                        <p class="categoryHeading marginBottom">New Category</p>
                        <input type="text" placeholder="Category name" class="marginBottom inputStyle" v-model="newCategoryText" required />
                        <div class="subDivStyle">
                            <ul class="marginBottom" style="list-style-type: none;" v-for="(defaultSubCategory , index) in defaultSubCategories" :key="index">
                                <li>
                                    <div>
                                        <div v-if="defaultSubCategories.length - 1 == index || defaultSubCategories.length == 1">
                                            <input type="text" placeholder="Subcategory name" class="inputStyle" v-model="subCategoryName" required />
                                        </div>
                                        <div v-else>
                                           
                                             <div class="flexContainer , inputStyle">
                                                                                        <input
                                                                                            style="border: none;"
                                                                                            placeholder="value name"
                                                                                            v-model="defaultSubCategory.value"
                                                                                            :readonly="defaultSubCategory.edit == false"
                                                                                            type="text"
                                                                                            class="inputField"
                                                                                        />
                                                                                        <input
                                                                                            type="button"
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="defaultSubCategory.edit == false ?enableEditSubCategory(index):disableEditSubCategory(index , defaultSubCategory.value)"
                                                                                            :value="defaultSubCategory.edit == false ?'Edit':'Save'"
                                                                                        />
                                                                                        <button
                                                                                            class="btnLink"
                                                                                            style="margin-right: 10px;"
                                                                                            @click="deleteDefaultValue(index , defaultSubCategory.key , defaultSubCategory.value , true)"
                                                                                        >
                                                                                            Delete
                                                                                        </button>
                                                                                    </div>

                                        </div>

                                        <div class="divStyle1">
                                            <ul class="marginBottom" style="list-style-type: none;" v-for="(defaultSubCategoriesValue ,index) in defaultSubCategoriesValues" :key="index">
                                                <li>
                                                    <div v-if="defaultSubCategoriesValue.key == defaultSubCategory.key">
                                                        <div v-if="(defaultSubCategoriesValues.length - 1 == index && defaultSubCategoriesValue.value == 'newValue') || defaultSubCategoriesValues.length == 1">
                                                            <div v-if="defaultSubCategoriesValue.value != 'newValue'">
                                                                <div class="flexContainer , inputStyle">
                                                                    <input
                                                                        style="border: none;"
                                                                        placeholder="value name"
                                                                        v-model="defaultSubCategoriesValue.value"
                                                                        :readonly="defaultSubCategoriesValue.edit == false"
                                                                        type="text"
                                                                        class="inputField"
                                                                    />
                                                                    <input
                                                                        type="button"
                                                                        class="btnLink"
                                                                        style="margin-right: 10px;"
                                                                        @click="defaultSubCategoriesValue.edit == false ?enableEdit(index):disableEdit(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value)"
                                                                        :value="defaultSubCategoriesValue.edit == false ?'Edit':'Save'"
                                                                    />
                                                                    <button class="btnLink" style="margin-right: 10px;" @click="deleteDefaultValue(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value , false)">Delete</button>
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                <input type="text" placeholder="value name" class="inputStyle" v-model="subValueText" required />
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <div v-if="defaultSubCategoriesValue.value != 'newValue'">
                                                                <div class="flexContainer , inputStyle">
                                                                    <input
                                                                        style="border: none;"
                                                                        placeholder="value name"
                                                                        v-model="defaultSubCategoriesValue.value"
                                                                        :readonly="defaultSubCategoriesValue.edit == false"
                                                                        type="text"
                                                                        class="inputField"
                                                                    />
                                                                    <input
                                                                        type="button"
                                                                        class="btnLink"
                                                                        style="margin-right: 10px;"
                                                                        @click="defaultSubCategoriesValue.edit == false ?enableEdit(index):disableEdit(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value)"
                                                                        :value="defaultSubCategoriesValue.edit == false ?'Edit':'Save'"
                                                                    />
                                                                    <button class="btnLink" style="margin-right: 10px;" @click="deleteDefaultValue(index , defaultSubCategoriesValue.key , defaultSubCategoriesValue.value , false)">Delete</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>

                                            <p class="inputStyle1" @click="addDefaultValue(defaultSubCategory.key , false ,false)">Add a value</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <p class="marginBottom text1" @click="addDefaultSubCategory(false)"><img height="12" src="../../assets/ic_plus.svg" /> Add a subcategory</p>
                            <button class="main-black-button" @click="addNewCategory(false)">SAVE</button>
                            <button class="main-white-button" @click="toggleSeeHiddenCategory">CANCEL</button>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      addCategoryStyle: {
        backgroundColor: "#F6F5F5",
      },
      defaultDisableDraggable: true,
      optionalDisableDraggable: true,
      showSection: false,
      currentIndex: 0,
      currentSubCategoryIndex: -1,
      currentValueIndex: 0,
      currentKeyIndex: 0,
      type: "margin",
      seeAddActiveCategoryButton: true,
      seeAddActiveCategory: false,
      seeAddHiddenCategory: false,
      seeAddHiddenCategoryButton: true,
      newCategoryText: "",
      updateCategoryText: "",
      subCategoryName: "",
      subValueText: "",
      greenColor: "#00E33A",
      defaultSubCategories: [],
      defaultSubCategoriesValues: [],
      updateSubCategories: [],
      updateSubCategoriesValues: [],
      isValueClicked: false,
      activedCategories: [],
      material: [],
      categories: [],
      defaultShowHide: [],
      isDeleteClicked: false,
      hiddenCategories: [],
      isToggleVisible: false,
      optionalShowHide: [],
      isCategoryActive: 0,
    };
  },

  computed: {},

  async mounted() {
    await this.getAllCategories();
  },

  methods: {
    addNewActiveCategory(isActive) {
      if (isActive) {
        this.toggleSeeActiveCategory();
      } else {
        this.toggleSeeHiddenCategory();
      }
      this.disabaleAllDragables();
      this.subCategoryName = "";
      this.subValueText = "";
      this.newCategoryText = "";
      this.defaultSubCategoriesValues = [];
      this.defaultSubCategories = [];
      if (!this.isToggleVisible) {
        this.toggle("hideAll", -1, isActive);
      }
    },

    toggle(category, index, isActive) {
      this.disabaleAllDragables();
      if (!this.isDeleteClicked) {
        this.defaultSubCategoriesValues = [];
        this.defaultSubCategories = [];
        this.subCategoryName = "";
        this.subValueText = "";
        this.newCategoryText = "";
        this.isValueClicked = false;
        let isHide = false;
        if (isActive) {
          if (this.isCategoryActive != isActive) {
            this.seeAddHiddenCategory = false;
            this.seeAddHiddenCategoryButton = true;
            for (let j = 0; j < this.optionalShowHide.length; j++) {
              this.optionalShowHide.splice(j, 1, {
                isShow: false,
              });
            }
          }
          for (let h = 0; h < this.defaultShowHide.length; h++) {
            if (index == h) {
              if (this.defaultShowHide[h].isShow == true) {
                isHide = true;
                this.defaultShowHide.splice(h, 1, {
                  isShow: false,
                });
              } else {
                this.defaultShowHide.splice(h, 1, {
                  isShow: true,
                });
              }
            } else {
              this.defaultShowHide.splice(h, 1, {
                isShow: false,
              });
            }
          }
          this.isCategoryActive = isActive;
        } else {
          if (this.isCategoryActive != isActive) {
            this.seeAddActiveCategory = false;
            this.seeAddActiveCategoryButton = true;
            for (let j = 0; j < this.defaultShowHide.length; j++) {
              this.defaultShowHide.splice(j, 1, {
                isShow: false,
              });
            }
          }
          for (let i = 0; i < this.optionalShowHide.length; i++) {
            if (index == i) {
              if (this.optionalShowHide[i].isShow == true) {
                isHide = true;
                this.optionalShowHide.splice(i, 1, {
                  isShow: false,
                });
              } else {
                this.optionalShowHide.splice(i, 1, {
                  isShow: true,
                });
              }
            } else {
              this.optionalShowHide.splice(i, 1, {
                isShow: false,
              });
            }
          }

          this.isCategoryActive = isActive;
        }
        if (!isHide && category != "hideAll") {
          if (isActive) {
            this.seeAddActiveCategory = false;
            this.seeAddActiveCategoryButton = true;
          } else {
            this.seeAddHiddenCategory = false;
            this.seeAddHiddenCategoryButton = true;
          }
          this.updateText(category, index);
        }
        this.isToggleVisible = isHide;
      } else {
        this.isDeleteClicked = false;
      }
    },
    async getAllCategories() {
      this.activedCategories = [];
      this.hiddenCategories = [];
      this.defaultShowHide = [];
      this.optionalShowHide = [];
      const categories = await this.$facilityService.getDelterraMaterialTypeCategories();
        if (categories) {
          this.activedCategories.push(categories.active);
          for (let i = 0; i < categories.active.length; i++) {
            this.defaultShowHide.push({ isShow: false });
          }

          this.hiddenCategories.push(categories.optional);
          for (let i = 0; i < categories.optional.length; i++) {
            this.optionalShowHide.push({ isShow: false });
          }
        }
    },
    addNewCategory(isActive) {
      if (isActive) {
        this.toggleSeeActiveCategory();
      } else {
        this.toggleSeeHiddenCategory();
      }

      var newCategory = this.newCategoryText.trim();
      if (!newCategory) {
        alert("Please Enter Category!");
        if (isActive) {
          this.seeAddActiveCategory = false;
          this.seeAddActiveCategoryButton = true;
        } else {
          this.seeAddHiddenCategory = false;
          this.seeAddHiddenCategoryButton = true;
        }
        return;
      }
      var subCategory = this.subCategoryName.trim();
      var subValue = this.subValueText.trim();
      this.material = [];
      this.categories = [];
      if (isActive) {
        for (let i = 0; i < this.activedCategories[0].length; i++) {
          this.material.push({
            materialName: this.activedCategories[0][i].materialName,
            categories: this.activedCategories[0][i].categories,
          });
        }
      } else {
        for (let i = 0; i < this.hiddenCategories[0].length; i++) {
          this.material.push({
            materialName: this.hiddenCategories[0][i].materialName,
            categories: this.hiddenCategories[0][i].categories,
          });
        }
      }

      if (this.defaultSubCategories.length == 0) {
        var valuesList1 = [];

        if (subCategory) {
          if (this.defaultSubCategoriesValues.length == 0) {
            if (subValue) {
              valuesList1.push(subValue);
              this.categories.push({
                itemValue: subCategory,
                values: valuesList1,
              });
            }
          } else {
            this.categories.push({
              itemValue: subCategory,
              values: valuesList1,
            });
          }
        }
      }
      for (let j = 0; j < this.defaultSubCategories.length; j++) {
        var valuesList = [];
        let subCategoryIndex = this.defaultSubCategories[j].key;

        if (this.defaultSubCategoriesValues.length == 0) {
          if (subValue) {
            valuesList.push(subValue);
            this.subValueText = "";
          }
        }

        for (let k = 0; k < this.defaultSubCategoriesValues.length; k++) {
          if (this.defaultSubCategoriesValues[k].key == subCategoryIndex) {
            if (this.defaultSubCategoriesValues[k].value == "newValue") {
              if (subValue) {
                valuesList.push(subValue);
              }
            } else {
              valuesList.push(this.defaultSubCategoriesValues[k].value);
            }
          }
        }
        let itemValueName = this.defaultSubCategories[j].value;
        if (itemValueName == "newSubCategory") {
          if (subCategory) {
            this.categories.push({
              itemValue: subCategory,
              values: valuesList,
            });
            this.subCategoryName = "";
          }
        } else {
          if (itemValueName) {
            this.categories.push({
              itemValue: itemValueName,
              values: valuesList,
            });
          } else {
            if (subCategory) {
              this.categories.push({
                itemValue: subCategory,
                values: valuesList,
              });
              this.subCategoryName = "";
            }
          }
        }
      }
      this.material.push({
        materialName: newCategory,
        categories: this.categories,
      });

      this.newCategoryText = "";

      this.$facilityService
        .postDelterraMaterialTypeCategory(this.material, isActive)
        .then(() => {
          this.getAllCategories();
        })
        .catch(err => alert(err.message));
    },
    toggleSeeActiveCategory() {
      this.seeAddActiveCategory = !this.seeAddActiveCategory;
      this.seeAddActiveCategoryButton = !this.seeAddActiveCategoryButton;
    },
    toggleSeeHiddenCategory() {
      this.seeAddHiddenCategory = !this.seeAddHiddenCategory;
      this.seeAddHiddenCategoryButton = !this.seeAddHiddenCategoryButton;
    },
    updateNewCategory(isActive) {
      const updateCategory = this.updateCategoryText.trim();
      if (!updateCategory) {
        alert("Please Enter Category!");
        return;
      }
      const subCategory = this.subCategoryName.trim();
      const subValue = this.subValueText.trim();
      this.material = [];
      this.categories = [];

      if (isActive) {
        for (let i = 0; i < this.activedCategories[0].length; i++) {
          this.material.push({
            materialName: this.activedCategories[0][i].materialName,
            categories: this.activedCategories[0][i].categories,
          });
        }
      } else {
        for (let i = 0; i < this.hiddenCategories[0].length; i++) {
          this.material.push({
            materialName: this.hiddenCategories[0][i].materialName,
            categories: this.hiddenCategories[0][i].categories,
          });
        }
      }

      if (this.defaultSubCategories.length == 0) {
        let valuesList1 = [];

        if (subCategory) {
          if (this.defaultSubCategoriesValues.length == 0) {
            if (subValue) {
              valuesList1.push(subValue);
              this.categories.push({
                itemValue: subCategory,
                values: valuesList1,
              });
            }
          } else {
            this.categories.push({
              itemValue: subCategory,
              values: valuesList1,
            });
          }
        }
      }
      for (let j = 0; j < this.defaultSubCategories.length; j++) {
        let valuesList = [];
        let subCategoryIndex = this.defaultSubCategories[j].key;

        if (this.defaultSubCategoriesValues.length == 0) {
          if (subValue) {
            valuesList.push(subValue);
            this.subValueText = "";
          }
        }

        for (let k = 0; k < this.defaultSubCategoriesValues.length; k++) {
          if (this.defaultSubCategoriesValues[k].key == subCategoryIndex) {
            if (this.defaultSubCategoriesValues[k].value == "newValue") {
              if (subValue) {
                valuesList.push(subValue);
              }
            } else {
              valuesList.push(this.defaultSubCategoriesValues[k].value);
            }
          }
        }
        let itemValueName = this.defaultSubCategories[j].value;
        if (itemValueName == "newSubCategory") {
          if (subCategory) {
            this.categories.push({
              itemValue: subCategory,
              values: valuesList,
            });
            this.subCategoryName = "";
          }
        } else {
          if (itemValueName) {
            this.categories.push({
              itemValue: itemValueName,
              values: valuesList,
            });
          } else {
            if (subCategory) {
              this.categories.push({
                itemValue: subCategory,
                values: valuesList,
              });
              this.subCategoryName = "";
            }
          }
        }
      }

      this.material.splice(this.currentIndex, 1, {
        materialName: updateCategory,
        categories: this.categories,
      });

      this.updateCategory = "";

      this.$facilityService
        .postDelterraMaterialTypeCategory(this.material, isActive)
        .then(() => {
          this.getAllCategories();
        })
        .catch(err => alert(err.message));
    },
    updateText(categoryInfo, index) {
      this.updateCategoryText = categoryInfo.materialName;

      for (let j = 0; j < categoryInfo.categories.length; j++) {
        this.defaultSubCategories.push({
          key: j,
          value: categoryInfo.categories[j].itemValue,
          edit: false,
        });

        for (let i = 0; i < categoryInfo.categories[j].values.length; i++) {
          this.defaultSubCategoriesValues.push({
            key: j,
            value: categoryInfo.categories[j].values[i],
            edit: false,
          });
        }
      }

      this.currentIndex = index;
    },
    moveCategory(index, isFromDefault) {
      this.disabaleAllDragables();
      this.material = [];
      this.categories = [];
      this.isDeleteClicked = true;
      var category = isFromDefault
        ? this.activedCategories[0][index]
        : this.hiddenCategories[0][index];

      isFromDefault
        ? this.activedCategories[0].splice(index, 1)
        : this.hiddenCategories[0].splice(index, 1);
      if (isFromDefault) {
        for (let i = 0; i < this.activedCategories[0].length; i++) {
          this.material.push({
            materialName: this.activedCategories[0][i].materialName,
            categories: this.activedCategories[0][i].categories,
          });
        }
      } else {
        for (let j = 0; j < this.hiddenCategories[0].length; j++) {
          this.material.push({
            materialName: this.hiddenCategories[0][j].materialName,
            categories: this.hiddenCategories[0][j].categories,
          });
        }
      }

      this.$facilityService
        .postDelterraMaterialTypeCategory(this.material, isFromDefault ? true : false)
        .then(() => {
          this.material = [];
          this.categories = [];

          isFromDefault
            ? this.hiddenCategories[0].push(category)
            : this.activedCategories[0].push(category);
          if (isFromDefault) {
            for (let i = 0; i < this.hiddenCategories[0].length; i++) {
              this.material.push({
                materialName: this.hiddenCategories[0][i].materialName,
                categories: this.hiddenCategories[0][i].categories,
              });
            }
          } else {
            for (let j = 0; j < this.activedCategories[0].length; j++) {
              this.material.push({
                materialName: this.activedCategories[0][j].materialName,
                categories: this.activedCategories[0][j].categories,
              });
            }
          }
          this.$facilityService
            .postDelterraMaterialTypeCategory(
              this.material,
              isFromDefault ? false : true
            )
            .then(() => {
              this.getAllCategories();
            })
            .catch(err => alert(err.message));
        });
    },
    duplicateCategory(index, isFromActive) {
      this.disabaleAllDragables();
      this.material = [];
      isFromActive
        ? this.activedCategories[0].push(this.activedCategories[0][index])
        : this.hiddenCategories[0].push(this.hiddenCategories[0][index]);
      if (isFromActive) {
        for (let i = 0; i < this.activedCategories[0].length; i++) {
          this.material.push({
            materialName: this.activedCategories[0][i].materialName,
            categories: this.activedCategories[0][i].categories,
          });
        }
      } else {
        for (let i = 0; i < this.hiddenCategories[0].length; i++) {
          this.material.push({
            materialName: this.hiddenCategories[0][i].materialName,
            categories: this.hiddenCategories[0][i].categories,
          });
        }
      }

      this.$facilityService
        .postDelterraMaterialTypeCategory(this.material, isFromActive)
        .then(() => {
          this.getAllCategories();
        })
        .catch(err => alert(err.message));
    },

    deleteCategory(index, isActive) {
      this.disabaleAllDragables();
      this.material = [];
      this.isDeleteClicked = true;
      if (isActive) {
        this.activedCategories[0].splice(index, 1);
        for (let i = 0; i < this.activedCategories[0].length; i++) {
          this.material.push({
            materialName: this.activedCategories[0][i].materialName,
            categories: this.activedCategories[0][i].categories,
          });
        }
      } else {
        this.hiddenCategories[0].splice(index, 1);
        for (let i = 0; i < this.hiddenCategories[0].length; i++) {
          this.material.push({
            materialName: this.hiddenCategories[0][i].materialName,
            categories: this.hiddenCategories[0][i].categories,
          });
        }
      }

      this.$facilityService
        .postDelterraMaterialTypeCategory(this.material, isActive)
        .then(() => {
          this.getAllCategories();
        })
        .catch(err => alert(err.message));
    },
    addDefaultSubCategory(isUpdate) {
      var subCategoryName = this.subCategoryName.trim();
      if (this.defaultSubCategories.length > 0) {
        if (!subCategoryName) {
          if (isUpdate) {
            if (
              this.defaultSubCategories[this.defaultSubCategories.length - 1]
                .value == "newSubCategory" ||
              this.defaultSubCategories[this.defaultSubCategories.length - 1]
                .value == ""
            ) {
              alert("Please Enter Subcategory!");
              return;
            }
          }
        }
      }
      if (isUpdate) {
        this.currentValueIndex = this.defaultSubCategories.length - 1;
        this.currentSubCategoryIndex = this.defaultSubCategories.length - 1;
      } else {
        if (this.defaultSubCategories.length == 0) {
          this.currentSubCategoryIndex = -1;
        }
      }

      this.addDefaultValue(this.currentValueIndex, true, isUpdate);

      if (this.currentSubCategoryIndex == -1) {
        this.currentSubCategoryIndex += 1;
        this.defaultSubCategories.push({
          key: this.currentSubCategoryIndex,
          value: "",
          edit: false,
        });
      } else {
        var subCategory = this.subCategoryName.trim();

        if (!subCategory) {
          if (isUpdate) {
            this.defaultSubCategories.push({
              key: this.currentSubCategoryIndex + 1,
              value: "newSubCategory",
              edit: false,
            });

            this.currentSubCategoryIndex += 1;
            this.subCategoryName = "";
            this.isValueClicked = false;
          } else {
            alert("Please Enter Subcategory!");
          }
          return;
        }
        if (this.currentSubCategoryIndex == 0) {
          this.defaultSubCategories.splice(this.currentSubCategoryIndex, 1, {
            key: this.currentSubCategoryIndex,
            value: subCategory,
            edit: false,
          });
          this.defaultSubCategories.push({
            key: this.currentSubCategoryIndex + 1,
            value: "newSubCategory",
            edit: false,
          });
        } else {
          this.defaultSubCategories.splice(
            this.defaultSubCategories.length - 1,
            1
          );
          this.defaultSubCategories.push({
            key: this.currentSubCategoryIndex,
            value: subCategory,
            edit: false,
          });
          this.defaultSubCategories.push({
            key: this.currentSubCategoryIndex + 1,
            value: "newSubCategory",
            edit: false,
          });
        }

        this.currentSubCategoryIndex += 1;
        this.subCategoryName = "";
        this.isValueClicked = false;
      }
    },
    addDefaultValue(subCategoryIndex, isFromSubCategory, isUpdate) {
      const subCategory = this.subCategoryName.trim();

      if (!isFromSubCategory && !subCategory) {
        if (isUpdate) {
          if (
            this.defaultSubCategories[this.defaultSubCategories.length - 1]
              .value == "newSubCategory" ||
            this.defaultSubCategories[this.defaultSubCategories.length - 1]
              .value == ""
          ) {
            alert("Please Enter Subcategory!");
            return;
          }
        } else {
          alert("Please Enter Subcategory!");
          return;
        }
      }
      const subValue = this.subValueText.trim();

      this.currentValueIndex = subCategoryIndex;

      if (subValue == "") {
        if (isFromSubCategory) {
          if (this.isValueClicked == true) {
            this.defaultSubCategoriesValues.splice(
              this.defaultSubCategoriesValues.length - 1,
              1
            );
          }
        } else {
          this.defaultSubCategoriesValues.push({
            key: subCategoryIndex,
            value: "newValue",
            edit: false,
          });
        }
      } else {
        this.defaultSubCategoriesValues.splice(
          this.defaultSubCategoriesValues.length - 1,
          1
        );
        if (isFromSubCategory) {
          this.defaultSubCategoriesValues.push({
            key: subCategoryIndex,
            value: subValue,
            edit: false,
          });
        } else {
          if (this.currentKeyIndex == subCategoryIndex) {
            this.defaultSubCategoriesValues.push({
              key: subCategoryIndex,
              value: subValue,
              edit: false,
            });
          } else {
            this.defaultSubCategoriesValues.push({
              key: this.currentKeyIndex,
              value: subValue,
              edit: false,
            });
          }
          this.defaultSubCategoriesValues.push({
            key: subCategoryIndex,
            value: "newValue",
            edit: false,
          });
        }
        this.subValueText = "";
      }
      this.currentKeyIndex = subCategoryIndex;
      this.isValueClicked = true;
    },

    deleteDefaultSubCategory(index, keyValue, value) {
      for (var i = this.defaultSubCategories.length - 1; i >= 0; i--) {
        if (
          this.defaultSubCategories[i].value == value &&
          this.defaultSubCategories[i].key == keyValue
        ) {
          for (
            var j = this.defaultSubCategoriesValues.length - 1;
            j >= 0;
            j--
          ) {
            if (this.defaultSubCategoriesValues[j].key == keyValue) {
              this.defaultSubCategoriesValues.splice(j, 1);
            }
          }
          this.defaultSubCategories.splice(i, 1);
        }
      }
    },

    deleteDefaultValue(index, keyValue, value, isSubCategory) {
      if (isSubCategory) {
        this.defaultSubCategories.splice(index, 1);
      } else {
        for (let i = 0; i < this.defaultSubCategoriesValues.length; i++) {
          if (
            this.defaultSubCategoriesValues[i].value == value &&
            this.defaultSubCategoriesValues[i].key == keyValue
          ) {
            this.defaultSubCategoriesValues.splice(i, 1);
          }
        }
      }
    },
    enableEdit(index) {
      this.defaultSubCategoriesValues[index].edit = true;
    },
    disableEdit(index, key, value) {
      this.defaultSubCategoriesValues[index].edit = false;
      this.defaultSubCategoriesValues[index].value = value;
    },
    enableEditSubCategory(index) {
      this.defaultSubCategories[index].edit = true;
    },
    disableEditSubCategory(index, value) {
      this.defaultSubCategories[index].edit = false;
      this.defaultSubCategories[index].value = value;
    },
    enableReOrder(isFromDefault) {
      if (isFromDefault) {
        if (this.defaultDisableDraggable == false) {
          this.material = [];

          for (let i = 0; i < this.activedCategories[0].length; i++) {
            this.material.push({
              materialName: this.activedCategories[0][i].materialName,
              categories: this.activedCategories[0][i].categories,
            });
          }

          this.$facilityService
            .postDelterraMaterialTypeCategory(this.material, true)
            .then(() => {
              this.getAllCategories();
            })
            .catch(err => alert(err.message));
        }
        this.defaultDisableDraggable = !this.defaultDisableDraggable;
      } else {
        if (this.optionalDisableDraggable == false) {
          this.material = [];

          for (let i = 0; i < this.hiddenCategories[0].length; i++) {
            this.material.push({
              materialName: this.hiddenCategories[0][i].materialName,
              categories: this.hiddenCategories[0][i].categories,
            });
          }

          this.$facilityService
            .postDelterraMaterialTypeCategory(this.material, false)
            .then(() => {
              this.getAllCategories();
            })
            .catch(err => alert(err.message));
        }
        this.optionalDisableDraggable = !this.optionalDisableDraggable;
      }
    },
    disabaleAllDragables() {
      if (!this.defaultDisableDraggable) {
        this.defaultDisableDraggable = true;
      }

      if (!this.optionalDisableDraggable) {
        this.optionalDisableDraggable = true;
      }
    },
  },
};
</script>

<style scoped>
.mainDiv {
  margin: 25px 20px 10px 20px;
}
.heading {
  margin-top: 20px !important;
  margin-left: 20px !important;

  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;

  color: #1b1b1b !important;
}

.btnLink {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #00c4ff;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  align-items: center;
  text-align: right;
  text-decoration-line: underline;
}

.subTitle {
  height: 16px;
  left: 354px;
  top: 232px;

  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;

  /* Black */

  color: #1b1b1b;
}
.text1 {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 19px;
  margin-left: 5px;

  color: #1b1b1b;
}
.categoryHeading {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;

  color: #1b1b1b;
}

.divStyle {
  padding: 10px;
  margin: 30;
}

.divStyle1 {
  margin: 10px 0px 0px 30px;
}

.subDivStyle {
  margin: 10px 0px 0px 30px;
}

.inputStyle {
  width: 100%;
  background: #f2f2f2;
  padding: 9px 0 9px;
  padding-left: 10px;
  border: 0.5px solid #9b9b9b;
  background-color: #ffffff;
  border-radius: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #4a4a4a;
}

.inputStyle1 {
  width: 100%;
  height: 35px;
  background: #f2f2f2;
  align-items: center;
  text-align: left, center;
  padding: 9px 0 9px;
  padding-left: 10px;
  border: 0.5px solid #9b9b9b;
  background-color: #ffffff;
  border-radius: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #1b1b1b;
  text-decoration-line: underline;
}

.btn,
button {
  cursor: pointer;
}

#mainBackground {
  background-color: #f6f5f5;
}

.main-black-button {
  background-color: #1b1b1b;
  width: 106px;
  height: 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 0px;
  color: white;
  font-weight: bold;
  font-size: 10px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.545455px;
  border: none;
}

.marginBottom {
  margin-bottom: 10px;
}

.collapsItem {
  background-color: #ffffff;
  border: 1px solid #9b9b9b;
  border-bottom: 1px solid #9b9b9b !important;
  border-radius: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  padding: 10px;
}

.main-white-button {
  background-color: #ffffff;
  border: 1px solid #1b1b1b;
  height: 30px;
  width: 106px;
  margin-left: 10px;
  box-sizing: border-box;
  border-radius: 0px;
  font-weight: bold;
  font-size: 10px;
  line-height: 25px;
  text-align: center;
  color: #1b1b1b;
}

.flexContainer {
  display: flex;
}

.inputField {
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #4a4a4a;
}
</style>
