<template>
    <div>
        <div class="page-width" id="header">
            <div id="header-container">
                <router-link to="/">
                    <img alt="Empower workspace logo" id="logo" src="../assets/empower-workspace-logo-white.svg">
                </router-link>
                <div v-on:click="logout">
                    Hi, Username <img src="../assets/user.svg">
                </div>
            </div>
        </div>
        <nav>
            <div id="nav-container" class="page-width">
                <router-link class="nav-container__link" v-bind:class="{ 'is-active': $route.path === '/users' }" to="/users">
                    Users
                </router-link>
                <router-link class="nav-container__link" v-bind:class="{ 'is-active': $route.path === '/sponsor' }" to="/sponsor">
                    Sponsor
                </router-link>
                <router-link class="nav-container__link" v-bind:class="{ 'is-active': $route.path === '/deposit' }" to="/deposit">
                    Deposit
                </router-link>
                <router-link class="nav-container__link" v-bind:class="{ 'is-active': $route.path === '/tracking' }" to="/tracking">
                    Tracking
                </router-link>
                <router-link class="nav-container__link" v-bind:class="{ 'is-active': $route.path === '/olddeposit' }" to="/swedishmatch">
                    SwedishMatch Deposit
                </router-link>
                <router-link class="nav-container__link" v-bind:class="{ 'is-active': $route.path === '/cleancity' }" to="/cleancity">
                    Clean City
                </router-link>
                <router-link class="nav-container__link" v-bind:class="{ 'is-active': $route.path === '/new-deposit' }" to="/new-deposit">
                    New Deposit
                </router-link>
            </div>
        </nav>
    </div>

</template>

<script>

    export default {
        name: "Navbar",
        methods: {
            logout: function () {
                this.$userService.logout();
                this.$router.push('/login');
            }
        }
    }
</script>

<style lang="scss" scoped>
    #header {
        height: 55px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color:  #1B1B1B;

        #header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        #logo {
            display: block;
            height: 24px;
        }
    }

    .nav-container__link{
        color: #1B1B1B !important;
    }

    #header, #nav-container{
        padding: 0 5.5%;
    }

    nav {
        height: 45px;
        background-color: #fff;

        #nav-container {
            display: flex;
            height: 100%;

            a {
                display: block;
                height: 100%;
                line-height: 45px;
                margin-right: 50px;
                font-size: 12px;
                font-weight: bold;
                text-decoration: none;
            }
        }
        .is-active, .router-link-active {
            border-bottom: 1px solid black !important;
        }
    }

</style>
