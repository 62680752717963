import { render, staticRenderFns } from "./EditScheme.vue?vue&type=template&id=73439702&scoped=true&"
import script from "./EditScheme.vue?vue&type=script&lang=js&"
export * from "./EditScheme.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73439702",
  null
  
)

export default component.exports