<template>
    <ContentView :content-config="contentConfig"/>
</template>

<script>
import ContentView from "../ContentView";

export default {
        name: "ViewRedeemable",
        components: {ContentView},
        created() {
            this.loadRedeemable();
        },
        methods: {
          loadRedeemable() {
              this.$cleanCityService.getRedeemable(this.$route.params.redeemableId).then((redeemable) => {
                    this.contentConfig.sections[0].data.redeemableId.value = redeemable.redeemableId;
                    this.contentConfig.sections[0].data.name.value = redeemable.name;
                    this.contentConfig.sections[0].data.goal.value = redeemable.goal;
                    this.contentConfig.sections[0].data.description.value = redeemable.description;
                    this.contentConfig.sections[0].data.type.value = redeemable.type;
                    this.contentConfig.sections[0].data.couponCost.value = redeemable.couponCost;
                    this.contentConfig.sections[0].data.redeems.value = redeemable.redeems;
                    this.contentConfig.sections[0].data.availableCoupons.value = redeemable.availableCoupons;
                    this.contentConfig.sections[0].data.picture.values = [redeemable.picture];
                }
            );
          }
        },
        data() {
            return {
                contentConfig: {
                    buttons: [
                        {
                            title: "Remove",
                            isDark: true,
                            callback: () => {
                                const remove = confirm("Are you sure you want to remove this redeemable?");
                                if (remove) {
                                    this.$cleanCityService.removeRedeemable(this.$route.params.redeemableId).then(() => {
                                        return this.$router.push({
                                            name: 'all-redeemables',
                                            params: {}
                                        });
                                    })
                                }
                            }
                        },
                         {
                            title: "Edit",
                            isDark: true,
                            callback: () => {
                                this.$router.push({
                                    name: 'edit-redeemable',
                                    params: {redeemableId: this.$route.params.redeemableId}
                                });
                            }
                        }
                    ],
                    sections: [
                        {
                            type: "info",
                            title: "Redeemable Info",
                            data: {
                                "redeemableId": {
                                    title: "Redeemable ID",
                                    value: ""
                                },
                                "name": {
                                    title: "Name",
                                    value: ""
                                },
                                "goal": {
                                    title: "Goal",
                                    value: ""
                                },
                                "description": {
                                    title: "Description",
                                    value: ""
                                },
                                "type": {
                                    title: "Type",
                                    value: ""
                                },
                                "couponCost": {
                                    title: "Coupon Cost",
                                    value: ""
                                },
                                "redeems": {
                                    title: "User Redeems",
                                    value: ""
                                },
                                "availableCoupons": {
                                    title: "Available Coupons",
                                    value: ""
                                },
                                "picture": {
                                    title: "Picture",
                                    type: "multiple",
                                    typeDefinition: "img",
                                    values: []
                                }                              
                            }
                        }
                    ]
                }
            }
        }
    }
</script>

<style scoped>

</style>
