<template>
    <ContentInput :content-config="contentConfig"/>
</template>

<script>
import ContentInput from "../ContentInput";

export default {
        name: "EditCoupon",
        components: {ContentInput},
        created() {
           this.loadCoupon();
        },
        methods: {
            loadCoupon() {
                 this.$cleanCityService.getCoupon(this.$route.params.couponId).then((coupon) => {
                    this.contentConfig.sections.couponInfo.inputs.code.value = coupon.code;
                }
            );
            }
        },
        data() {
            return {
                contentConfig: {
                    title: "Edit Coupon",
                    buttons: [
                        {
                            title: "Update",
                            isDark: true,
                            callback: () => {
                                
                                const couponInfoInputs = this.contentConfig.sections.couponInfo.inputs;
                                let couponClone =  {
                                        couponId: this.$route.params.couponId,
                                        code: couponInfoInputs.code.value,
                                   }
                                
                                   this.$cleanCityService.editCoupon(couponClone).then(() => {
                                            this.$router.push({
                                                name: 'view-coupon',
                                                params: {couponId: this.$route.params.couponId}
                                            })
                                         })
                                }
                        }
                    ],
                    sections: {
                        "couponInfo": {
                            title: "Coupon Info:",
                            type: "info",
                            inputs: {
                                "code": {
                                    title: "Coupon Code",
                                    type: "text",
                                    value: undefined
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
