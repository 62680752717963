<template>
    <DataTable :data-config="dataConfig"/>
</template>

<script>
import DataTable from "../DataTable";
import moment from 'moment';

export default {
        name: "History",
        components: {DataTable},
        created() {
            this.loadHistory()
        },
        data() {
            return {
                dataConfig: {
                    columns: [
                        "ActivityTime",
                        "Status",
                        "Transaction Type",
                        "Deliverer",
                        "Delivery",
                        "Recipient",
                        "Receive"
                    ],
                    rows: [],
                    facilities: [],
                    historyItems: [],
                    reload: () => {
                        this.loadHistory();
                    }
                }
            }
        },
        methods: {
            loadHistory() {
                this.$wasteService.getTransactionHistory(this.$route.params.organizationId)
                    .then(transactionHistory => {
                        this.dataConfig.rows = [];
                        for (const [index, transaction] of transactionHistory.transactions.entries()) {
                            this.dataConfig.rows.push({
                                id: index,
                                selected: false,
                                data: {
                                    time: transaction.transactionData.completedDateTime ? new Date(transaction.transactionData.completedDateTime).toISOString() : new Date(transaction.transactionData.initiatedDateTime).toISOString(),
                                    status: transaction.status,
                                    type: transaction.transactionType,
                                    deliverer: transaction.transactionData.delivererFacilityName ? "Facility: " + transaction.transactionData.delivererFacilityName :  transaction.transactionData.delivererUserId? "Verified UserId " + transaction.transactionData.delivererUserId : "Manual Deliverer: " + transaction.transactionData.manualDeliverer,
                                    delivery: transaction.transactionData.delivererWasteAmounts ? transaction.transactionData.delivererWasteAmounts.reduce((p, c) => p.concat(c.amount + "kg of " + this.$wasteService.getMassBalanceDefinitionName(c.wasteDefinition)), "") : "",
                                    recipient: transaction.transactionData.recipientFacilityName ? "Facility: " + transaction.transactionData.recipientFacilityName : "Manual Recipient: " + transaction.transactionData.manualRecipient,
                                    receive: transaction.transactionData.recipientWasteAmounts ? transaction.transactionData.recipientWasteAmounts.reduce((p, c) => p.concat(c.amount + "kg of " + this.$wasteService.getMassBalanceDefinitionName(c.wasteDefinition)), "") : ""
                                },
                            })
                        }
                    })
            },
            convertToDate: function (value) {
                if (value) {
                    return moment.unix(value / 1000).format('DD-MM-YYYY')
                }
            },
            generateHistoryText: function (facilities, entry) {
                let textDescription;

                if (entry.inputs.length === 0) {
                    textDescription = `Unverified ${entry.type} of ${this.wasteDefsToText(entry.outputs)}`;
                } else if (entry.outputs.length === 0) {
                    textDescription = `Unverified ${entry.type} of ${this.wasteDefsToText(entry.inputs)}`;
                } else {
                    textDescription = `${this.wasteDefsToText(entry.inputs)} ${entry.type} ${this.wasteDefsToText(entry.outputs)}`;
                }
                entry.textDescription = textDescription;
                return textDescription;
            },
            getFacilityFromWasteDefs: function (allFacilities, entry) {
                for (let facility of allFacilities) {
                    if (facility.facilityId === entry.inputFacility || facility.facilityId === entry.outputFacility) return facility.facilityName
                }
            },
            wasteDefsToText: function (wasteDefs) {
                let text = "";
                for (let i = 0; i < wasteDefs.length; ++i) {
                    const wasteDef = wasteDefs[i];
                    if (i !== 0) text += " AND ";
                    text += this.wasteDefToText(wasteDef);
                }
                return text;
            },
            wasteDefToText: function (wasteDef) {
                return `${wasteDef.amount} kg ${wasteDef.compressed} ${wasteDef.condition} ${wasteDef.granularity} ${wasteDef.plasticType}`
            }
        }
    }
</script>

<style scoped>

</style>
