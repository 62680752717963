import {apiService} from "@/main";

export default class {
    depositEndpoint = process.env.VUE_APP_COLLECTION_API_BASE_URL + "/api/v1/deposit"

    async createCollectionPoint(facilityId) {
        return await apiService.call("POST",`${this.depositEndpoint}/collection-points`,
            {
                    facilityId: facilityId,
            }
        )
    }

    async getCollectionPoint(facilityId) {
        return await apiService.call("GET", `${this.depositEndpoint}/collection-points/${facilityId}`)
    }

    async getAllCollectionPoints() {
        const result = await apiService.call("GET",`${this.depositEndpoint}/collection-points`)
        return result.collectionPoints
    }

}
