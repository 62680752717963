<template lang="html">
<div id="container">
<ul id="flex-container">
<li v-for="(item,index) in flexContainer" :style="item.itemGrow"  :key="index">
     
  <p>
  <span v-if="index !== 0" class="dot"></span>
  <span id="heading">{{item.heading}}</span>
  </p>
  <p id="value">
  <span v-if="index === 0" style="padding-right:3px; display:inline-block;">

  <img style="width:50px;height:50px;" src="../../assets/bird.png" alt="">

  </span>
  <span >{{item.value}}</span>
  </p>
</li>
</ul>

<div id='container1'>
   <div id='left'>
             <div
          ref="gmap"
          class="map"
          :center="{lat:coordinates.lat, lng:coordinates.lng}"
          :zoom="15"
          style="width: 100%; height: 100%;margin: 0; padding: 0;"
        >
        </div>
   </div>
   <div id='right'>

   <table>
  <tr v-for="(user,index) in users" :key="index" v-on:click="clickList(index)" :class="[{'highlight': (index == selectedIndex)} , 'bottom-border']">
    <td><div class="numberCircle">{{firstLetters[index]}}</div></td>
    <td>{{user.name}}</td>
    <td>{{user.time}}</td>
  </tr>
 
  
</table>
   </div>
   </div>


</div>
</template>

<script>
import { getGoogleMaps } from '@empower-platform/google-maps-shared';

export default {
  data() {
    return {
      google: null,
      map: null,
      markers: [],
      firstLetters: [],
      coordinates: { lat: 59.924545, lng: 10.768063 },
      position: { lat: 59.924545, lng: 10.768063 },
      selectedIndex: -1,
      flexContainer: [
        {
          heading: "Total Empact: ",
          value: "1500,00 kg",
          itemGrow: {
            "flex-grow": 2,
          },
        },
        {
          heading: "Picked up",
          value: "600,00 pieces",
          itemGrow: {
            "flex-grow": 2,
          },
        },
        {
          heading: "Reported littering",
          value: "300 pieces",
          itemGrow: {
            "flex-grow": 2,
          },
        },
      ],
      users: [
        {
          name: "Leanne Graham",
          time: "10 mins ago",
        },
        {
          name: "Ervin Howell",
          time: "10 mins ago",
        },
        {
          name: "Clementine Bauch",
          time: "10 mins ago",
        },
        {
          name: "Patricia Lebsack",
          time: "10 mins ago",
        },
        {
          name: "Chelsey Dietrich",
          time: "10 mins ago",
        },
        {
          name: "Mrs. Dennis Schulist",
          time: "10 mins ago",
        },
        {
          name: "Kurtis Weissnat",
          time: "10 mins ago",
        },
        {
          name: "Nicholas Runolfsdottir V",
          time: "10 mins ago",
        },
        {
          name: "Glenna Reichert",
          time: "10 mins ago",
        },
        {
          name: "Clementina DuBuque",
          time: "10 mins ago",
        },
      ],
    };
  },

  created() {
    this.addNewActiveCategory();
  },

  async mounted() {
    this.google = await getGoogleMaps();
    await this.initMap();

    this.places = [
      {
        lat: 6.7007981,
        lng: 79.91026599999998,
      },
      {
        lat: 6.7008171,
        lng: 79.9102732,
      },
      {
        lat: 6.700866,
        lng: 79.91028160000002,
      },
      {
        lat: 8.7008161,
        lng: 80.91027170000001,
      },
      {
        lat: 11.7008161,
        lng: 50.91027170000001,
      },
    ];
    this.addLocationsToGoogleMaps();
  },
  methods: {
    initMap () {
      this.map = new this.google.maps.Map(this.$refs.gmap, {
        center: {lat: this.position.lat, lng: this.position.lng},
        zoom: 12,
        gestureHandling: "greedy",
        streetViewControl: false,
        mapTypeControl: false,
      });
    },
    clickList: function (index) {
      this.selectedIndex = index;
    },
    addNewActiveCategory: function () {
      for (let j = 0; j < this.users.length; j++) {
        let firstValue = this.users[j].name.charAt(0).toUpperCase();
        this.firstLetters.push(firstValue);
      }
    },

    addLocationsToGoogleMaps() {
      if (this.places.length > 0) {
        this.coordinates = this.places[0];
      }

      this.places.forEach((place) => {
        const position = place;
        this.markers = new this.google.maps.Marker({
          position: position,
          map: this.map,
        });
      });
    },
  },

};
</script>

<style scoped>
#container {
  margin: 25px 20px 10px 20px;
}
ul#flex-container {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul li {
  flex-grow: 1;
  height: 150px;

  text-align: left;
}

ul li:first-child #heading {
  color: #444444;
  font-style: normal;
  font-weight: normal;
  font-weight: bold;
  font-size: 20px;
}

ul li:first-child #value {
  color: #444444;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  padding-top: 10px;
}
ul li:nth-child(2) #heading {
  color: #00c4ff;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  padding-left: 10px;
}

ul li:nth-child(2) #value {
  color: #00c4ff;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  padding-top: 10px;
}
ul li:nth-child(3) #heading {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;

  color: #f98b00;
  padding-left: 10px;
}
ul li:nth-child(3) #value {
  color: #f98b00;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  padding-top: 10px;
}

ul li:nth-child(2) .dot {
  height: 10px;
  width: 10px;
  background-color: #00c4ff;
  border-radius: 50%;
  display: inline-block;
}

ul li:nth-child(3) .dot {
  height: 10px;
  width: 10px;
  background-color: #f98b00;
  border-radius: 50%;
  display: inline-block;
}

#container1 {
  display: flex;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
}

#left {
  display: flex;
  width: 70%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
#right {
  display: flex;
  width: 30%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
table {
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: left;
  padding: 8px;
}

.numberCircle {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;

  background: #00c4ff;

  color: white;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

tr:hover.selected td,
tr:hover td {
  background-color: #ffefc6;
  cursor: pointer;
}
.highlight {
  background-color: #ffd659;
}
.bottom-border {
  border-bottom: 0.3px solid #9b9b9b;
}
</style>


