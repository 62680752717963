<template>
    <div class="content-container">
        <div class="content-header">{{contentConfig.title}}</div>
        <div class="content-buttons">
            <button class="button__confirm"
                    v-for="(button, index) of contentConfig.buttons"
                    v-bind:class="{ 'admin-btn-dark': button.isDark }"
                    v-on:click="button.callback()" v-bind:key="index">
                {{button.title}}
            </button>
        </div>
        <div v-for="(section, index) of contentConfig.sections" v-bind:key="`section-${index}`">
            <div class="section-title">{{section.title}}</div>
            <div v-if="section.type === 'info'">
                <div v-for="(pieceOfData, index) in section.data" v-bind:key="`pieceOfData-${index}`" class="content-container-info">
                    <div v-if="pieceOfData.type === 'multiple'">
                        <div v-for="(value, valuesIndex) in pieceOfData.values" v-bind:key="`multipleValuesPieceOfData-${index}-${valuesIndex}`">
                            <div v-if="pieceOfData.typeDefinition === 'img'">
                                <img :src="value">
                            </div>
                            <div v-else>
                                {{value}}
                            </div>
                        </div>
                    </div>
                    <div v-if="pieceOfData.type === 'file'">
                        {{pieceOfData.title}}<br>
                        <button type="button" v-on:click="openUrl(pieceOfData.value)">Download File</button>
                    </div>
                    <div v-else class="content-container-info">
                      <label>{{pieceOfData.title}}:</label>
                         <span>{{pieceOfData.value}}</span>
                    </div>

                </div>
            </div>
            <div v-else-if="section.type === 'table'" class="content-container-info">
                <table>
                    <tr>
                        <th v-for="(column, index) in section.columns" v-bind:key="`content-column-${index}`" :id="`header-${index}`">
                            {{column}}
                        </th>
                        <th v-if="typeof section.rowButton !== 'undefined'">Action</th>
                    </tr>
                    <tr v-for="row in section.rows" v-bind:key="row.id"
                        v-on:click="handleTrClick(row, $event)">
                        <td v-for="(value, index) of row.data" v-bind:key="`content-row-${index}`" :headers="`header-${index}`">
                            {{value}}
                        </td>
                        <td v-if="typeof section.rowButton !== 'undefined'">
                            <button type="button" v-on:click="section.rowButton.callback(row)">{{section.rowButton.title}}</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContentView",
        props: {
            contentConfig: Object
        },
        methods: {
            handleTrClick(item, event) {
                if (event.target.type !== 'button') {
                    item.callback();
                }
            },
            openUrl(url) {
                window.open(url, "_blank");
            }
        }
    }
</script>

<style lang="scss" scoped>
.section-title {
  margin-bottom: .5rem;
}


</style>
