<template>
    <div>
        <ContentInput :content-config="contentConfig"/>
    </div>
</template>

<script>
import ContentInput from "../ContentInput";

export default {
    name: "EditVoucherDefinition",
    components: {ContentInput},
    async created() {
        const vd = await this.$depositAdminClient.getVoucherDefinition(this.$route.params.voucherDefinitionId)
        this.contentConfig.sections.voucherInfo.inputs.voucherName.value = vd.name
        this.contentConfig.sections.voucherInfo.inputs.pictureURL.value = vd.pictureURL
    },
    data() {
        return {
            contentConfig: {
                title: "Edit voucher definition",
                buttons: [
                    {
                        title: "Save",
                        isDark: true,
                        callback: () => {
                            const voucherDefinitionId = this.contentConfig.sections.voucherInfo.inputs.voucherDefinitionId.value
                            const voucherName = this.contentConfig.sections.voucherInfo.inputs.voucherName.value
                            const pictureURL = this.contentConfig.sections.voucherInfo.inputs.pictureURL.value
                            const adminToken = this.contentConfig.sections.voucherInfo.inputs.adminToken.value

                            if (!voucherDefinitionId || !voucherName || !pictureURL || !adminToken) {
                                alert('Missing field(s)')
                                return
                            }

                            this.$depositAdminClient.editVoucherDefinition({
                                voucherDefinitionID: voucherDefinitionId,
                                name: voucherName,
                                pictureURL: pictureURL
                            }, adminToken)
                                .then(() => {
                                    alert("Success!")
                                    this.$router.push({name: 'deposit-organizations'})
                                })
                                .catch(err => {
                                    console.error(err)
                                    alert(err);
                                })
                        }
                    }
                ],
                sections: {
                    "voucherInfo": {
                        title: "Voucher Definition Info:",
                        type: "info",
                        inputs: {
                            "voucherDefinitionId": {
                                title: "Voucher Definition ID",
                                type: "text",
                                value: this.$route.params.voucherDefinitionId,
                            },
                            "voucherName": {
                                title: "Voucher Name",
                                type: "text",
                                value: undefined,
                            },
                            "pictureURL": {
                                title: "Picture URL",
                                type: "text",
                                value: undefined,
                            },
                            "adminToken": {
                                title: "Admin token",
                                type: "text",
                                value: undefined,
                            }
                        }
                    }
                }
            }
        }
    },
}
</script>

<style scoped>

</style>
