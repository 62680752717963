import {apiService} from "@/main";

export default class {

    async getPickUpPost(litterPickUpId) {
        return await apiService.call("GET",`/clean-city/litter-pick-up/${litterPickUpId}`)
    }

    async getPickUpPosts() {
        return await apiService.call("GET", `/admin/clean-city/litter-pick-ups`)
    }

    async removePickUpPost(litterPickUpId) {
        return await apiService.call("DELETE", `/admin/clean-city/litter-pick-up/${litterPickUpId}`)
    }

    async getReportPost(litterReportId) {
        return await apiService.call("GET",`/admin/clean-city/litter-reports/${litterReportId}`)
    }

    async getReportPosts() {
        return await apiService.call("GET", `/admin/clean-city/litter-reports`)
    }

    async removeReportPost(litterReportId) {
        return await apiService.call("DELETE",`/admin/clean-city/litter-reports/${litterReportId}`)
    }

    async createRedeemable(redeemableObj) {
        return await apiService.call("POST", `/admin/clean-city/redeemables`, redeemableObj)
    }

    async editRedeemable(redeemableObj) {
        return await apiService.call("PUT",`/admin/clean-city/redeemables`, redeemableObj)
    }

    async removeRedeemable(redeemableId) {
        return await apiService.call("DELETE",`/admin/clean-city/redeemables/${redeemableId}`)
    }

    async getRedeemable(redeemableId) {
        return await apiService.call("GET", `/admin/clean-city/redeemables/${redeemableId}`)
    }

    async getRedeemables() {
        return await apiService.call("GET",`/clean-city/redeemables`)
    }

    async createGroup(groupObj) {
        return await apiService.call("POST",`/admin/clean-city/groups`, groupObj)
    }

    async getGroup(groupId) {
        return await apiService.call("GET",`/admin/clean-city/groups/${groupId}`)
    }

    async getGroups() {
        return await apiService.call("GET",`/clean-city/groups`)
    }

    async createCoupon(couponObj) {
        return await apiService.call("POST", `/admin/clean-city/coupons`, couponObj)
    }

    async editCoupon(couponObj) {
        return await apiService.call("PUT", `/admin/clean-city/coupons`, couponObj)
    }

    async removeCoupon(couponId) {
        return await apiService.call("DELETE", `/admin/clean-city/coupons/${couponId}`)
    }

    async getCoupon(couponId) {
        return await apiService.call("GET", `/admin/clean-city/coupons/${couponId}`)
    }

    async getCoupons() {
        return await apiService.call("GET",`/admin/clean-city/coupons`)
    }

    async getUnusedCoupons() {
        return await apiService.call("GET", `/admin/clean-city/coupons/un-used`)
    }

    async getCouponsByRedeemable(redeemableId) {
        return await apiService.call("GET", `/admin/clean-city/coupons/project/${redeemableId}`)
    }

    async cleanReportPost(litterReportId) {
        return await apiService.call('PUT', `/admin/clean-city/litter-reports/${litterReportId}/cleaned`, [])
    }
}
