import axios from 'axios'
import Cookies from "js-cookie";
import {apiService} from "@/main";

export default class {
    userEndpoint = process.env.VUE_APP_USER_API_BASE_URL + '/api/v1/user'

    constructor(eventBus) {
        this.eventBus = eventBus;
        this.token = undefined;
        this.userId = undefined;
        this.userInfo = new Promise((resolve) => {
            this.userInfoResolve = resolve;
        })
    }

    async initUserService() {
        const cookieToken = Cookies.get("token");
        const cookieUserId = Cookies.get("userId");
        if (cookieToken && cookieUserId) {
            if (this._isAdmin(cookieToken) !== true) {
                alert("Access denied: user is not admin")
                throw Error("User is not admin")
            }
            this.token = cookieToken;
            this.userId = cookieUserId;

            return this.updateUserInfo().catch(() => {
                alert("Something went wrong while fetching your credentials");
            })
        }
    }

    login(password, email, phoneNumber, countryCode) {
        this.eventBus.$emit('loggedOut');
        return axios.post(`${this.userEndpoint}/users/login`, {
            email,
            countryCode,
            phoneNumber,
            password
        }).then(response => {
            if (this._isAdmin(response.data.token) !== true) {
                alert("Access denied: user is not admin")
                throw Error("User is not admin")
            }
            this.token = response.data.token;
            this.userId = response.data.userId;

            Cookies.set("token", this.token);
            Cookies.set("userId", this.userId);
            apiService.setToken()

            return this.updateUserInfo();
        }).catch(e => {
            throw new Error(e);
        })
    }

    isLoggedIn() {
        return typeof this.token !== "undefined";
    }

    logout() {
        this.token = undefined;
        this.userId = undefined;
        this.userinfo = undefined;

        Cookies.remove("token");
        Cookies.remove("userId");

        this.eventBus.$emit('loggedOut');
    }

    updateUserInfo() {
        return axios.get(`${this.userEndpoint}/users/${this.userId}`, {
                headers: {
                    'Authorization': 'Bearer ' + this.token
                }
            }
        ).then(response => {
            const userInfo = response.data;
            this.userInfoResolve(userInfo);
            this.userInfo = Promise.resolve(userInfo);
        });
    }

    getOrganization() {
        return this.userInfo.then(userInfo => {
            return (userInfo.organization) ? userInfo.organization : null;
        });
    }

    async resendEvents() {
        return await apiService.call("POST", `${this.userEndpoint}/users/resend-events`, {})
    }

    async getAllUsers() {
        const result = await apiService.call("GET",`${this.userEndpoint}/users`)
        return result.users
    }

    async createUser(registerObj) {
        return await apiService.call("POST", `${this.userEndpoint}/users`,
            {
                    password: registerObj.password,
                    email: registerObj.email,
                    countryCode: registerObj.countryCode,
                    phoneNumber: registerObj.phoneNumber,
                    firstName: registerObj.firstName,
                    lastName: registerObj.lastName,
                    country: registerObj.country,
            }
        )
    }

    _isAdmin(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload).empowerAdmin
    }

    getToken() {
        return this.token
    }

    async getUserById(id) {
        return await apiService.call("GET", `${this.userEndpoint}/users/${id}`);
    }

    async updateUserById(id, updateBody) {
        return await apiService.call("PUT", `${this.userEndpoint}/users/${id}`, updateBody);
    }
}
