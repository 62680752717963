import {userService} from '@/main';

export const userStore = {
    state: {
      success: null,
      error: null,
      users: [],
      userInfo: null,
    },

    actions: {
      async getAllUsers({commit}) {
        try {
          const users = await userService.getAllUsers();
          commit('setAllUsers', users);
        } catch (e) {
          alert(e);
        }
      },

      async getUserById({commit}, userId) {
        try {
          const user = await userService.getUserById(userId);
          commit('setUserInfo', user);
        } catch (e) {
          alert(e);
        }
      }
    },

    mutations: {
      setSuccess(state, msg) {
        state.success = msg;
      },

      setError(state, msg) {
        state.error = msg;
      },

      setAllUsers(state, users) {
        state.users = users.reverse();
      },

      setUserInfo(state, user) {
        state.userInfo = user;
      }
    },

};
