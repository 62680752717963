<template>
    <DataTable :data-config="dataConfig"/>
</template>

<script>
import DataTable from "../DataTable";

export default {
        name: "AllCollectionPoints",
        components: {DataTable},
        async created() {
            if (!this.$store.state.facilityStore.collectionPoints.length) {
                await this.$store.dispatch('getAllCollectionPoints')
            }
            let collectionPoints = this.$store.state.facilityStore.collectionPoints
            this.loadAllCollectionPoints(collectionPoints)
        },
        methods: {
            loadAllCollectionPoints(collectionPoints) {
                this.dataConfig.items = [];
                for (let collectionPoint of collectionPoints) {
                    this.dataConfig.rows.push({
                        id: collectionPoint.facilityId,
                        selected: false,
                        data: {
                            facilityName: collectionPoint.facilityName,
                            organizationName: collectionPoint.organizationName,
                        },
                        callback: () => {
                            this.$router.push({
                                name: 'view-collection-point',
                                params: {facilityId: collectionPoint.facilityId}
                            })
                        }
                    })
                }
            }
        },
        data() {
            return {
                dataConfig: {
                    columns: [
                        "Facility Name", "Organization Name",
                    ],
                    rows: [],
                    reload: () => {
                        this.loadAllCollectionPoints();
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
