<template>
    <div>

        <div v-if="multiSelect">
            <multiselect v-model="selectedFacilities" :options="matchedFacilities" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="facilityName" track-by="facilityId" :preselect-first="false">
                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
            </multiselect>

            <button v-on:click="selectCallback(selectedFacilities)">Select</button>
        </div>
        <div v-else>
            <input type="text" v-model="searchTerm">
            <div v-on:click="selectCallback(facility)" v-for="facility of matchedFacilities"
                 v-bind:key="facility.facilityId">
                {{facility.facilityName}} ({{facility.facilityId}})
            </div>
        </div>

    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
        name: "FacilitySearch",
        components: { Multiselect },
        props: {
            selectCallback: Function,
            organizationId: String,
            multiSelect: Boolean,
            isOpen: Boolean,
            facilitiesToIgnore: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            selectAllFacilities: Boolean,
        },
        created() {
            if (!this.$store.state.facilityStore.facilities.length) {
                this.$store.dispatch("getAllFacilities")
            }
            if (this.selectAllFacilities) {
                this.selectedFacilities = this.facilities
            }
        },
        data() {
            return {
                searchTerm: "",
                selectedFacilities: []
            }
        },
        computed: {
            facilities() {
                return this.$store.state.facilityStore.facilities
            },
            matchedFacilities: function() {
                if (this.selectAllFacilities) {
                    return this.facilities;
                }
                const matchedFacilities = [];
                const facilitiesToSearch = this.facilities
                    .filter(facility => !this.facilitiesToIgnore.some(
                        facilityToIgnore => facility.facilityId === facilityToIgnore.facilityId))
                    .filter(facility => {
                        if (this.organizationId) {
                            return facility.organizationId === this.organizationId;
                        } else {
                            return true
                        }
                    })


                for (const facility of facilitiesToSearch) {
                    if (!this.searchTerm) {
                        matchedFacilities.push(facility);
                    } else if (facility.facilityName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1) {
                        matchedFacilities.push(facility);
                    }
                }
                return matchedFacilities
            }
        },
    }
</script>

<style scoped>

</style>
