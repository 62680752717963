import { render, staticRenderFns } from "./PickUps.vue?vue&type=template&id=750bb7b3&scoped=true&"
import script from "./PickUps.vue?vue&type=script&lang=js&"
export * from "./PickUps.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "750bb7b3",
  null
  
)

export default component.exports