<template>
  <DataTable :data-config="dataConfig" />
</template>

<script>
import DataTable from "../DataTable";

export default {
  name: "Cleaned",
  components: { DataTable },
  filteredPosts: [],
  created() {
    this.loadCleanedReportPosts();
  },
  methods: {
    loadCleanedReportPosts() {
      this.$cleanCityService.getReportPosts().then((litterReports) => {
        this.dataConfig.items = [];
        this.filteredPosts = litterReports.litterReports.filter(
          (product) => product.cleaned == true
        );
        for (let post of this.filteredPosts) {
          this.dataConfig.rows.push({
            id: post.litterReportId,
            selected: false,
            data: {
              litterReportId: post.litterReportId,
              points: post.points,
              comment: post.comment,
            },
            callback: () => {
              this.$router.push({
                name: "view-report-cleaned",
                params: { litterReportId: post.litterReportId },
              });
            },
          });
        }
      });
    },
  },
  data() {
    return {
      dataConfig: {
        columns: ["LitterReportId", "Points", "Comment"],
        rows: [],
        reload: () => {
          this.loadCleanedReportPosts();
        },
      },
    };
  },
};
</script>

<style scoped>
</style>