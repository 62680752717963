import { render, staticRenderFns } from "./CreateScheme.vue?vue&type=template&id=63271ae0&scoped=true&"
import script from "./CreateScheme.vue?vue&type=script&lang=js&"
export * from "./CreateScheme.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63271ae0",
  null
  
)

export default component.exports