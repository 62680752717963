<template>
    <div id="app">
        <Loader :is-visible="isLoading"></Loader>
        <router-view></router-view>
    </div>
</template>

<script>
import axios from "axios";
import Loader from "./components/Loader";

export default {
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            axiosInterceptor: null,
        };
    },
    mounted() {
        this.$loadingService.registerActivateLoading(() => (this.isLoading = true));
        this.$loadingService.registerDeactivateLoading(
            () => (this.isLoading = false)
        );
        this.enableInterceptor();
        // this.$material.theming.theme = 'empowerColors'
    },
    methods: {
        enableInterceptor() {
            this.axiosInterceptor = axios.interceptors.request.use(
                (config) => {
                    this.$loadingService.addLoadingItem();
                    return config;
                },
                (error) => {
                    this.$loadingService.removeLoadingItem();
                    return Promise.reject(error);
                }
            );

            axios.interceptors.response.use(
                (response) => {
                    this.$loadingService.removeLoadingItem();
                    return response;
                },
                (error) => {
                    this.$loadingService.removeLoadingItem();
                    return Promise.reject(error);
                }
            );
        },

        disableInterceptor() {
            axios.interceptors.request.eject(this.axiosInterceptor);
        },
    },
};
</script>


<style lang="scss">
@import "buttons";

.content-container-info {
    display: flex;
    flex-direction: row;
    margin-bottom: .5rem;

    label {
        margin-right: 1rem;
        min-width: 150px;
    }

    table {
        display: table-header-group;

        th, td {
            width: 180px;
        }
    }
}
</style>

<style lang="scss" scoped>
@import "variables";

/* http://meyerweb.com/eric/tools/css/reset/
     v2.0 | 20110126
     License: none (public domain)
  */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.table-with-lines {
    border: 1px solid black;
    th {
        border: 1px solid black;
        font-weight: bold;
    }

    tr {
        border: 1px solid black !important;
    }

    td {
        border: 1px solid black;
    }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

html,
body {
    margin: 0;
    height: 100%;
    min-height: 100%;
    width: 100%;
}

#app {
    font-family: "Open Sans", sans-serif;
    color: #4a4a4a;
    background-color: #f6f5f5;
    // min-height: 100%;
    min-height: 100vh;

}

a {
    color: #4a4a4a;
}

.page-width {
    width: 950px;
    margin-left: auto;
    margin-right: auto;
}

h1 {
    font-size: 20px;
    font-weight: bold;
}

h2 {
    font-size: 18px;
    font-weight: bold;
}

h3 {
    font-size: 16px;
    font-weight: bold;
}

h4 {
    font-size: 14px;
    font-weight: bold;
}

::v-deep ::selection {
    background: $new-green !important;
}

@import "../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
</style>
