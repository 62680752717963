<template>
    <div>
        <div :class="{ 'is-hidden' : showSingleCertificateView }">
            <div class="filters-wrapper flex-align-center">
                <img @click="reloadDepositsPendingEmpowerSignature" class="pointer" src="@/assets/reload-grey.svg" alt="Reload icon">
                <div class="filters">
                    <p @click="clearFilters">All</p>
                    <Datetime v-model="selectedDate"
                              @input="setFilterFromDate"
                              :phrases="{ok: 'Select'}"
                              type="date"
                              placeholder="Filter from date"
                              format="dd-MM-yyyy"
                              input-style="
                                height: 30px;
                                padding: 7px 0 7px 3%;
                                border: none;
                                border-bottom: 1px solid #1B1B1B;
                                font-size: 12px;
                                line-height: 16px;
                                margin-right: 15px;
                                cursor: pointer">
                        <template slot="button-cancel">
                            <div @click="clearFromDate">Clear</div>
                        </template>
                    </Datetime>
                    <Datetime v-model="toDate"
                              @input="setFilterToDate"
                              :phrases="{ok: 'Select'}"
                              type="date"
                              placeholder="Filter to date"
                              format="dd-MM-yyyy"
                              input-style="
                              height: 30px;
                              padding: 7px 0 7px 3%;
                              border: none;
                              border-bottom: 1px solid #1B1B1B;
                              font-size: 12px;
                              line-height: 16px;
                              margin-right: 15px;
                              cursor: pointer">
                        <template slot="button-cancel">
                            <div @click="clearToDate">Clear</div>
                        </template>
                    </Datetime>
                    <select class="filters__select pointer" v-model="filterByOrg">
                        <option :value="null">Filter by Organization</option>
                        <option v-for="(organization, index) in getOrganizations"
                                :key="index"
                                :value="organization">
                            {{ organization }}
                        </option>
                    </select>
                    <select class="filters__select pointer" v-model="filterByStatus">
                        <option selected :value="null">Select the status</option>
                        <option value="SIGNED_BY_COLLECTOR">Unsigned</option>
                        <option value="SIGNED_BY_EMPOWER">Approved</option>
                        <option value="REJECTED">Rejected</option>
                    </select>
                </div>

            </div>
            <div v-if="filteredCertificates.length > 0">
                <md-table  :class="{  'is-selected' : selected.length > 0 }" :value="filteredCertificates" :md-selected-value.sync="selected" md-card @md-selected="onSelect" md-sort="signedDate">
                    <md-table-toolbar v-if="selected.length>0">
                        <h1 class="md-title"></h1>
                    </md-table-toolbar>
                    <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }" v-if="selected.length>0">
                        <div class="md-toolbar-section-start">{{ getAlternateLabel(count) }}</div>
                        <div>
                            <md-button class="button-select" :mdRipple=false  @click="showConfirmationMess">Approve</md-button>
                            <md-button class="button-select" :mdRipple=false  @click="showRejectionMess">Reject</md-button>
                            <md-button class="button-select button-disabled">Delete</md-button>
                            <md-button class="button-select button-select--cancel" @click="selected = []">Cancel</md-button>
                        </div>
                    </md-table-toolbar>
                    <md-table-row slot="md-table-row" style="z-index: 100" slot-scope="{ item }" :md-disabled="item.status === 'SIGNED_BY_EMPOWER' || item.status === 'REJECTED'" md-selectable="multiple" md-auto-select>
                        <md-table-cell class="table-text" md-label="Latest" md-sort-by="signedDate">{{ formattedDate(item.signedDate) }}</md-table-cell>
                        <md-table-cell class="table-text" md-label="Registered (+manual)">{{ formattedDate(item.timestamp) }} ({{ formattedDate(item.manualDateTime) }})</md-table-cell>
                        <md-table-cell class="table-text" md-label="From">{{ item.organizationName }}</md-table-cell>
                        <md-table-cell class="table-text" md-label="Facility">{{ item.facilityName }}</md-table-cell>
                        <md-table-cell class="table-text" md-label="KG">{{ item.amount }}</md-table-cell>
                        <md-table-cell class="table-text" md-label="Description">
                            <p class="md-table-cell__title bold">{{ item.massBalanceDefinition.plasticType }}</p>
                            <div class="md-table-cell__description">
                                <ul>
                                    <li v-for="(description, key) in transform(item.massBalanceDefinition)" :key="key" class="md-table-cell__description-item">
                                        <p>· {{description}}</p>
                                    </li>
                                </ul>
                            </div>
                        </md-table-cell>
                        <md-table-cell class="table-text" md-label="Status" :class="{ 'is-accepted' : item.status === 'SIGNED_BY_EMPOWER', 'is-rejected' : item.status === 'REJECTED' }">{{ formatStatus(item.certificationState) }}</md-table-cell>
                        <md-table-cell class="table-text bold pointer" md-label=""><span class="underline" @click="showItem(item)">View</span></md-table-cell>
                    </md-table-row>
                </md-table>
            </div>
        </div>
        <div>
            <div>
                <md-dialog :md-active.sync="showConfirmationMessage" class="md-dialog">
                    <div class="summary">
                        <div class="btn--close pointer" @click="showConfirmationMessage = false">
                            <img src="@/assets/close-icon.svg" alt="Close btn"/>
                        </div>
                        <md-dialog-title class="summary__title bold label" >Summary:</md-dialog-title>
                        <ul>
                            <li v-for="(item, index) in groupOrganizations" :key="index" style="border-bottom: 0.5px solid #9B9B9B; padding: 0;">
                                <p class="summary__org-name">{{index}}</p>
                                <ul>
                                    <li v-for="(waste, index) in item" :key="index">
                                        <div class="summary__details">
                                            <div style="position:relative;">
                                                <div>
                                                    <p class="label bold">{{waste.massBalanceDefinition.plasticType}}</p>
                                                    <ul>
                                                        <li v-for="(description, index) in transform(waste.massBalanceDefinition)" :key="index" class="summary__material">
                                                            <p class="label">· {{description}}</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <p  class="summary__amount label">{{waste.amount}} kg</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <md-dialog-actions class="btn__wrapper">
                            <button class="button__confirm" @click="confirmCertificate">CONFIRM</button>
                            <button class="button__cancel flex-center" @click="showConfirmationMessage = false">CANCEL</button>
                        </md-dialog-actions>
                    </div>
                </md-dialog>
            </div>
            <div>
                <md-dialog :md-active.sync="showRejectionMessage">
                    <div class="summary">
                        <div class="btn--close pointer" @click="closeRejectionMessage">
                            <img src="@/assets/close-icon.svg" alt="Close btn"/>
                        </div>
                        <textarea class="summary__rejection-reason" v-model="rejectionReason" placeholder="Please write the rejection reason"></textarea>
                        <md-dialog-actions class="btn__wrapper">
                            <button class="button__confirm" :class="{ 'button-disabled': !rejectionReason }"  @click="rejectCertificate">CONFIRM</button>
                            <button class="button__cancel flex-center" @click="closeRejectionMessage">CANCEL</button>
                        </md-dialog-actions>
                    </div>
                </md-dialog>
            </div>
        </div>

        <div class="certificate__single--view" v-if="showSingleCertificateView">
            <div class="flex-between">
                <button class="btn--back" @click="goBack">Back</button>
            </div>
            <ViewPlasticCreditDetails
                    :certificate="singleCertificateToDisplay"
                    :organizationName="organizationNameToSend"
                    @closeOneCertificateView="goBackToAllCertificates"
                    @certStatusUpdated="certStatusUpdated"
            />
        </div>
    </div>
</template>

<script>
import moment from "moment";
import {Datetime} from 'vue-datetime';
import ViewPlasticCreditDetails from "@/components/tracking/plasticCredits/ViewPlasticCreditDetails";
import {wasteService} from "@/main";
import transform from "@/mixins/transformWasteDefinitionForDisplay";

export default {
        name: "PlasticCreditsIssue",
        components: {
          ViewPlasticCreditDetails,
            Datetime,
        },
        mixins: [transform],
        data: () => ({
            selected: [],
            selectedDate: null,
            toDate: null,
            filterToDate: false,
            filterByOrg: null,
            filterByStatus: null,
            filterFromDate: false,
            showConfirmationMessage: false,
            showRejectionMessage: false,
            rejectionReason: null,
            userOrganizationId: null,
            showSingleCertificateView: false,
            singleCertificateToDisplay: {},
            organizationNameToSend: ''
        }),

        async mounted() {
            if (!this.$store.state.plasticCreditStore.depositsPendingEmpowerSignature.length || this.$store.state.plasticCreditStore.reloadPlasticCreditIssue) {
                await this.$store.dispatch('getDepositsPendingEmpowerSignature')
            }
          this.filterFromDate = false;
          this.filterToDate = false;
          if(this.certificates){
              return this.certificates.sort(function (x, y) {
                  return y.signedDate - x.signedDate;
              });
          }
        },

        computed: {
            certificates: {
                get(){
                    return this.$store.state.plasticCreditStore.depositsPendingEmpowerSignature
                },
                set(certificate){
                    return certificate
                }
            },
            filteredCertificates() {
                if (this.filterToDate || this.filterFromDate || this.filterByStatus || this.filterByOrg) {
                    return this.applyFilters();
                }
                return this.certificates;
            },
            getOrganizations(){
                if (this.certificates.length > 0){
                    const orgNames = []
                    for (const c of this.certificates) {
                        orgNames.push(c.organizationName)
                    }
                    return [...new Set(orgNames)]
                }
                return [];
            },
            groupOrganizations() {
                function groupBy(objectArray, property) {
                    return objectArray.reduce((acc, obj) => {
                        const key = obj[property];
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        const sameMassBalanceKeyIndex = acc[key].findIndex((entry) => {
                          if (!obj.massBalanceDefinition || !entry.massBalanceDefinition) {
                            return false;
                          }
                          return (wasteService.getMassBalanceDefinitionName(entry.massBalanceDefinition) === wasteService.getMassBalanceDefinitionName(obj.massBalanceDefinition))
                        })

                        if (sameMassBalanceKeyIndex > -1) {
                           let amount = acc[key][sameMassBalanceKeyIndex].amount
                           amount += obj.amount
                           acc[key][sameMassBalanceKeyIndex].amount = parseFloat(amount.toFixed(1))  // To prevent floating point issue on arithmatic operations
                        } else {
                          acc[key].push({...obj});
                        }
                        return acc;
                    }, {});
                }
                return groupBy([...this.selected], 'organizationName');
            },
        },

        methods: {
            async reloadDepositsPendingEmpowerSignature() {
              await this.$store.dispatch('getDepositsPendingEmpowerSignature');
            },
            showRejectionMess(){
                this.rejectionReason = null;
                this.showRejectionMessage = true;
            },
            showConfirmationMess(){
                this.showConfirmationMessage = true;
            },
            async confirmCertificate(){
                const selectedCertificates = this.selected;
                const selectedDepositsId = []
                for (const c of selectedCertificates){
                    selectedDepositsId.push(c.depositId)
                }
                await this.$plasticCreditService.signDepositsAndIssuePlasticCredits(selectedDepositsId).then(() => {
                    this.changeCertificateStatus(selectedDepositsId, 'SIGNED_BY_EMPOWER')
                    this.$store.dispatch('getPlasticCreditBalance')
                    this.$store.dispatch('getPlasticCreditTransactionHistory')
                    this.$store.commit('resetBalances')
                });
                this.selected = [];
                this.showConfirmationMessage = false;
            },
            rejectCertificate(){
                const selectedCertificates = this.selected;
                const selectedDepositsId = []
                for (const c of selectedCertificates){
                    selectedDepositsId.push(c.depositId)
                }
                this.$plasticCreditService.rejectDeposits(this.rejectionReason, selectedDepositsId).then(() => {
                    this.changeCertificateStatus(selectedDepositsId, 'REJECTED');
                    this.addRejectionReason(selectedDepositsId, this.rejectionReason);
                    this.$store.dispatch('getPlasticCreditBalance')
                    this.$store.dispatch('getPlasticCreditTransactionHistory')
                });
                this.selected = [];
                this.closeRejectionMessage();
            },
            changeCertificateStatus(depositIds, status) {
                for (let id of depositIds) {
                    let depositSigned = this.certificates.find(certificate => {
                        return certificate.depositId === id;
                    })
                    depositSigned.status = status;
                    let depositFound = this.certificates.findIndex(certificate => {
                        return certificate.depositId === id;
                    })
                    if (depositFound !== -1) {
                        this.certificates[depositFound].certificationState = status;
                    }
                }
            },
            addRejectionReason(depositIds, rejectReason){
                for (let id of depositIds) {
                    let depositRejected = this.certificates.find(certificate => {
                        return certificate.depositId === id;
                    })
                    depositRejected.rejectReason = rejectReason;
                    let depositFound = this.certificates.findIndex(certificate => {
                        return certificate.depositId === id;
                    })
                    if (depositFound !== -1) {
                        this.certificates[depositFound].rejectReason = rejectReason;
                    }
                }
            },
            applyFilters() {
                let certificates = [...this.certificates];
                if (this.filterToDate && this.filterFromDate) {
                    certificates = certificates.filter(c => {
                        const dateFormat = "MM-DD-YYYY";
                        let selectDate = moment(this.selectedDate).format(dateFormat);
                        let toDate = moment(this.toDate).format(dateFormat);
                        let signedDate = moment(c.signedDate).format(dateFormat);
                        return moment(signedDate, dateFormat).isBetween(moment(selectDate, dateFormat),moment(toDate, dateFormat), undefined, '[]');
                    });
                } else  if (this.filterFromDate) {
                    certificates = certificates.filter(c => {
                        return moment(this.selectedDate).format('MMMM-Do-YYYY') === moment.unix(c.signedDate / 1000).format('MMMM-Do-YYYY');
                    });
                } else if (this.filterToDate) {
                    certificates = certificates.filter(c => {
                        return moment(this.toDate).format('MMMM-Do-YYYY') === moment.unix(c.signedDate / 1000).format('MMMM-Do-YYYY');
                    });
                }
                if (this.filterByOrg !== null) {
                    certificates = certificates.filter(c => {
                        return c.organizationName === this.filterByOrg;
                    })
                }
                if (this.filterByStatus) {
                    certificates = certificates.filter(c => {
                        return c.certificationState === this.filterByStatus;
                    })
                }
                return certificates;
            },
            clearFilters(){
                this.selectedDate = null;
                this.filterFromDate = false;
                this.toDate = null;
                this.filterByOrg = null;
                this.filterByStatus = null;
                this.filterToDate = false;
                return this.certificates;
            },

            onSelect (items) {
                this.selected = items
            },
            formatStatus(certificationState) {
                if (certificationState === 'SIGNED_BY_COLLECTOR'){
                    return 'Unsigned'
                }
                else if (certificationState === 'SIGNED_BY_EMPOWER'){
                    return 'Approved'
                }
                else if (certificationState === 'REJECTED'){
                    return 'Rejected'
                }
                else if (certificationState === 'ISSUED'){
                    return 'Issued'
                }
                else{
                    return certificationState
                }
            },
            getAlternateLabel (count) {
                let plural = ''
                if (count > 1) {
                    plural = 's'
                }
                return `${count} certificate${plural} selected`
            },
            async goBackToAllCertificates() {
                this.selected = [];
                if (this.$store.state.plasticCreditStore.reloadPlasticCreditIssue) {
                  await this.$store.dispatch('getDepositsPendingEmpowerSignature');
                }
                setTimeout(() => {
                    this.showSingleCertificateView = false;
                    this.singleCertificateToDisplay = {};
                    this.$emit('toggleHeader');
                },50);
            },
            setFilterFromDate() {
                if (this.selectedDate.length > 0) {
                    this.filterFromDate = true;
                }
            },
            setFilterToDate() {
                if (this.toDate.length > 0) {
                    this.filterToDate = true;
                }
            },
            clearFromDate() {
                this.selectedDate = null;
                this.filterFromDate = false;
            },
            clearToDate() {
                this.toDate = null;
                this.filterToDate = false;
            },
            async showItem(item){
                if (item.status && item.status === "REJECTED" || item.status === "SIGNED_BY_EMPOWER") {
                    item = await this.$store.dispatch('getOneDepositRegistration', item.depositId)
                }
                this.showSingleCertificateView = true;
                this.singleCertificateToDisplay = item;
                this.organizationNameToSend = item.organizationName;
                this.$emit('toggleHeader');
            },
            goBack() {
                this.selected = [];
                setTimeout(() => {
                    this.showSingleCertificateView = false;
                    this.$emit('toggleHeader');
                },50);
            },
            formattedDate(date) {
                return moment(date).format('YYYY-MM-DD HH:mm')
            },
            closeRejectionMessage(){
                this.showRejectionMessage = false;
            },
            certStatusUpdated(certInfo) {
                this.changeCertificateStatus(certInfo.ids, certInfo.status)
                if(certInfo.status === 'REJECTED') {
                    this.addRejectionReason(certInfo.ids, certInfo.reason)
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/variables";

    .filters-wrapper{
        margin-bottom: 20px;
        justify-content: space-between;
    }

    .filters{
        display: flex;
        justify-content: flex-end;
        width: 80%;
        p{
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            text-decoration-line: underline;
            color: #1B1B1B;
            margin: 8px 25px 0 0;
            cursor: pointer;
        }
    }

    .filters__select{
        height: 30px;
        padding: 7px 3% 7px 0;
        color: $dark-text;
        border: none;
        border-bottom: 1px solid #1B1B1B;
        font-size: 12px;
        line-height: 16px;
        margin-right: 15px;
    }

    .md-table + .md-table {
        margin-top: 16px
    }

    .button-select{
        background: transparent !important;
        border-radius: 0 !important;
        width: 100px;
        font-weight: bold;
        font-size: 12px !important;
        line-height: 16px !important;
        text-decoration-line: underline;
        color: $dark-bg;
    }

    .button-select--cancel{
        font-weight: normal;
    }

    .button-disabled{
        opacity: 0.5;
        pointer-events: none;
    }

    .md-table-cell__title{
        margin-top: 0;
    }

    .md-table-cell__description{
        margin-top: 0;
        margin-bottom: 0;
    }

    .md-table-cell__description-item {
        padding: 0;
        border: none;
    }

    .table-text.bold.pointer{
        color: #00C5FF;
        z-index: 100;
    }

    ul{
        list-style-type: none;
        padding: 0;
        li{
            padding-bottom: 16px;
        }
    }

    .md-dialog {
        overflow: scroll
    }

    .btn--back{
        border: 0.5px solid #1B1B1B;
        width: 96px;
        height: 24px;
        text-align: center;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
        margin-bottom: 24px;
        color: #1B1B1B;
        display: inline-block;
        cursor: pointer;
        background-color: transparent;
    }

    .table-text{
        font-size: 12px;
        p{
            margin: 0;
        }
    }

    .md-table.is-selected ::v-deep .md-table-toolbar {
        background-color: #00E33A !important;
    }

    .md-table ::v-deep .md-theme-default.md-checked .md-checkbox-container{
        background-color: #00E33A;
        border-color:  #00E33A;
    }

    .md-card {
        box-shadow: none !important;
    }

    .md-toolbar-section-start{
        flex: unset;
        margin-right: 25px;
    }

    .summary{
        padding: 64px 120px;

        &__details{
            display: flex;
            justify-content: space-between;
        }

        &__title{
            padding: 0;
        }

        &__org-name{
            font-size: 12px;
            line-height: 16px;
            color: #9B9B9B;
            padding: 16px 0;
        }

        &__amount{
            position: absolute;
            top: 0;
            right: 0;
        }

        &__material {
            padding: 0 !important;
            border: none;
        }

        &__rejection-reason {
            font-size: 12px;
            padding: 8px;
            height: 64px;
            width: 400px;
            resize: none;
            border: 1px solid $placeholder-grey;
            color: $dark-bg;
            &::placeholder {
                color: $placeholder-grey;
            }
        }

        ul{
            width: 400px;
            list-style-type: none;
            padding: 0;
            li{
                padding-bottom: 16px;
            }
        }
    }

    .btn__wrapper{
        padding: 0;
        justify-content: unset;
        margin-top: 10px;
        & button:not(:last-child) {
          margin-right: 1rem;
        }
    }

    .table-text{
        font-size: 12px;
        p{
            margin: 0;
        }
    }

    .md-card {
        box-shadow: none !important;
    }

    .md-toolbar-section-start{
        flex: unset;
        margin-right: 25px;
    }

    .button__cancel{
        background: white;
        color: #1B1B1B !important;
        border: 1px solid #1B1B1B;
    }

    .btn--close{
        position: absolute;
        top: 8px;
        right: 8px;
        background-color: $dark-bg;
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 8px;
            height: 8px;
        }
    }

    ::v-deep .md-table-toolbar {
        background-color: #00E33A !important;
    }

    ::v-deep .md-theme-default.md-checked .md-checkbox-container{
        background-color: #00E33A;
        border-color:  #00E33A;
    }

    ::v-deep .md-table-head-label{
        font-weight: normal !important;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        color: #9b9b9b;
        align-items: center;
    }

    ::v-deep .md-table-head-container {
        display: flex;
    }

    ::v-deep .md-table-head-container svg {
        fill: #9b9b9b !important;
    }

</style>
