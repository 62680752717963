import {plasticCreditService} from "@/main";

export const plasticCreditStore = {
    state: {
        plasticCreditTransactionHistory: [],
        reloadPlasticCreditTransactionHistory: false,
        plasticCreditBalance: [],
        reloadPlasticCreditBalance: false,
        depositsPendingEmpowerSignature: [],
        reloadPlasticCreditIssue: [],
        allDeposits: [],
        balancesForAdminBuySingleView: [],

    },
    actions: {
        async getPlasticCreditTransactionHistory({commit}) {
            try {
                const plasticCreditTransactionHistory = await plasticCreditService.getPlasticCreditTransactionHistory();
                commit('setPlasticCreditTransactionHistory', plasticCreditTransactionHistory);
                commit('setShouldReloadPlasticCreditTransactionHistory', false);
            } catch {
                commit('setError')
            }
        },
        async getPlasticCreditBalance({commit}) {
            try {
                const plasticCreditBalance = await plasticCreditService.getPlasticCreditBalance();
                commit('setPlasticCreditBalance', plasticCreditBalance);
                commit('setShouldReloadPlasticCreditBalance', false);
            } catch {
                commit('setError')
            }
        },
        async getDepositsPendingEmpowerSignature({commit}) {
            try {
                const depositsPendingEmpowerSignature = await plasticCreditService.getDepositsPendingEmpowerSignature();
                commit('setDepositsPendingEmpowerSignature', depositsPendingEmpowerSignature);
                commit('setShouldReloadPlasticCreditIssue', false);
            } catch {
                commit('setError')
            }
        },
        async getOneDepositRegistration({commit}, payload) {
            try {
                return await plasticCreditService.getOneDepositRegistration(payload)
            } catch {
                commit('setError')
            }
        },
        async getAllDeposits({commit}) {
            try {
                const allDeposits = await plasticCreditService.getRejectedApprovedAndPendingEmpowerSignatureDeposits()
                commit('setAllDeposits', allDeposits)
            } catch {
                commit('setError')
            }
        },
        async getPlasticCreditBalanceForOneWasteTypeOfOneFacility({commit}, payload) {
            try {
                const oneCreditBalanceForOneWasteTypeOfOneFacility = await plasticCreditService.getPlasticCreditBalanceForOneWasteTypeOfOneFacility(payload.ownerId, payload.massBalanceDefinition)
                commit('addBalance', oneCreditBalanceForOneWasteTypeOfOneFacility)
            } catch {
                commit('setError')
            }
        },
        async shouldUpdatePlasticCredits({commit}) {
            commit('setShouldReloadPlasticCreditIssue', true);
            commit('setShouldReloadPlasticCreditBalance', true);
            commit('setShouldReloadPlasticCreditTransactionHistory', true);
        }
    },
    mutations: {
        setPlasticCreditTransactionHistory(state, plasticCreditTransactionHistory) {
            state.plasticCreditTransactionHistory = plasticCreditTransactionHistory
        },

        setPlasticCreditBalance(state, plasticCreditBalance) {
           state.plasticCreditBalance = plasticCreditBalance
        },

        setDepositsPendingEmpowerSignature(state, depositsPendingEmpowerSignature) {
            state.depositsPendingEmpowerSignature = depositsPendingEmpowerSignature
        },
        setAllDeposits(state, allDeposits) {
            state.allDeposits = allDeposits
        },
        addBalance(state, oneCreditBalanceForOneWasteTypeOfOneFacility) {
            state.balancesForAdminBuySingleView.push(oneCreditBalanceForOneWasteTypeOfOneFacility)
        },
        resetBalances(state) {
            state.balancesForAdminBuySingleView = []
        },
        setShouldReloadPlasticCreditIssue(state, status) {
            state.reloadPlasticCreditIssue = status;
        },
        setShouldReloadPlasticCreditBalance(state, status) {
            state.reloadPlasticCreditBalance = status;
        },
        setShouldReloadPlasticCreditTransactionHistory(state, status) {
            state.reloadPlasticCreditTransactionHistory = status;
        },
    },
    getters: {
        getSingleViewBalanceByMassBalanceDefinition: (state) => (certificate) => {
            return state.balancesForAdminBuySingleView.find(balance => balance.currentOwnerName === certificate.currentOwnerName && JSON.stringify(balance.massBalanceDefinition) === JSON.stringify(certificate.massBalanceDefinition))
        },

        depositsPendingEmpowerSignature: (state) => {
            return state.depositsPendingEmpowerSignature;
        }
    }
}
