<template>
    <ContentInput :content-config="contentConfig"/>
</template>

<script>
import ContentInput from "../ContentInput";

export default {
        name: "CreateNewOrganization",
        components: {ContentInput},
        data() {
            return {
                contentConfig: {
                    title: "Create New Organization",
                    buttons: [
                        {
                            title: "Save",
                            isDark: true,
                            callback: () => {
                                const orgInfoInputs = this.contentConfig.sections.organizationInfo.inputs;
                                this.$organizationsService.createOrganization({
                                    organizationName: orgInfoInputs.orgName.value,
                                    organizationNumber: orgInfoInputs.orgNumber.value
                                }).then(() => {
                                    this.$store.dispatch('getAllOrganizations').then(() => {
                                        this.$router.push({name: "all-organizations"});
                                    })
                                })
                            }
                        }
                    ],
                    sections: {
                        "organizationInfo": {
                            title: "Organization Info:",
                            type: "info",
                            inputs: {
                                "orgName": {
                                    title: "Organization Name",
                                    type: "text",
                                    value: "",
                                },
                                "orgNumber": {
                                    title: "Organization Number",
                                    type: "text",
                                    value: ""
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
