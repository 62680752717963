<template>
    <ContentView :content-config="contentConfig"/>
</template>

<script>
import ContentView from "../ContentView";

export default {
      name: "ViewUser",
      components: {ContentView},
      async created() {
        await this.setUser();
      },
      data() {
        return {
          contentConfig: {
            buttons: [
              {
                title: "Edit",
                isDark: true,
                callback: () => {
                  this.$router.push({
                    name: 'edit-user',
                    params: {userId: this.$route.params.userId}
                  });
                }
              }
            ],
            sections: [
              {
                type: "info",
                title: "User Info",
                data: {
                  "firstName": {
                    title: "First Name",
                    value: ""
                  },
                  "lastName": {
                    title: "Last Name",
                    value: ""
                  },
                  "email": {
                    title: "Email",
                    value: ""
                  },
                  "phoneNumber": {
                    title: "Phone number",
                    value: ""
                  }
                }
              },
              {
                type: "table",
                title: "Organizations",
                columns: ["Org Name", "Org Number", "Org ID"],
                rows: []
              }
            ]
          }
        }
      },
      computed: {
        userInfo() {
          return this.$store.state.userStore.userInfo;
        }
      },
      methods: {
          async setUser() {
            await this.$store.dispatch('getUserById', this.$route.params.userId);
            this.contentConfig.sections[0].data.firstName.value = this.userInfo.firstName;
            this.contentConfig.sections[0].data.lastName.value = this.userInfo.lastName;
            this.contentConfig.sections[0].data.email.value = this.userInfo.email;
            this.contentConfig.sections[0].data.phoneNumber.value = this.userInfo.phoneNumber;
              this.contentConfig.sections[1].rows.push({
                id: this.userInfo.organization.organizationId,
                data: [this.userInfo.organization.orgName, this.userInfo.organization.orgNumber, this.userInfo.organization.organizationId],
                callback: () => {
                  this.$router.push({
                    name: 'view-organization',
                    params: {organizationId: this.userInfo.organization.organizationId}
                  })
                }
              })
          },
      },

    }
</script>

<style lang="scss" scoped>

</style>
