<template>
    <div>
        <Modal :close-callback="closeCallback" v-if="showOrganizationModal">
            <OrganizationSearch :select-callback="organizationSelected"/>
        </Modal>
        <Modal :close-callback="closeCallback" v-if="showFacilityModal">
            <FacilitySearch :organization-id="organizationId" :select-callback="facilitySelected"/>
        </Modal>
        <ContentInput :content-config="contentConfig"/>
    </div>
</template>

<script>
import FacilitySearch from "../FacilitySearch";
import ContentInput from "../ContentInput";
import Modal from "../Modal";
import OrganizationSearch from "../OrganizationSearch";

export default {
        name: "CreateCollectionPoint",
        components: {OrganizationSearch, Modal, ContentInput, FacilitySearch},
        data() {
            return {
                showOrganizationModal: false,
                showFacilityModal: false,
                organizationId: undefined,
                contentConfig: {
                    title: "New Collection Point",
                    buttons: [
                        {
                            title: "Create Collection Point",
                            isDark: true,
                            callback: () => {
                                const facilityId = this.contentConfig.sections.collectionPointInfo.inputs.facilityId.value
                                this.$depositService.createCollectionPoint(facilityId).then(() => {
                                    this.$store.dispatch('getAllCollectionPoints').then(() => {
                                        this.$router.push({name: 'collection-points'})
                                    })
                                })
                            }
                        }
                    ],
                    sections: {
                        "collectionPointInfo": {
                            title: "Facility Info:",
                            type: "info",
                            inputs: {
                                "organizationId": {
                                    title: "OrganizationID",
                                    type: "text",
                                    value: undefined,
                                    onFocus: () => {
                                        this.showOrganizationModal = true
                                    }
                                },
                                "facilityId": {
                                    title: "FacilityID",
                                    type: "text",
                                    value: undefined,
                                    onFocus: () => {
                                        this.showFacilityModal = true
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        methods: {
            closeCallback() {
                this.showOrganizationModal = false;
                this.showFacilityModal = false;
            },
            organizationSelected(organization) {
                this.organizationId = organization.organizationId;
                this.contentConfig.sections.collectionPointInfo.inputs.organizationId.value = organization.organizationName;
                this.showOrganizationModal = false;
            },
            facilitySelected(facility) {
                this.contentConfig.sections.collectionPointInfo.inputs.facilityId.value = facility.facilityId;
                this.showFacilityModal = false;
            }
        }

    }
</script>

<style scoped>

</style>
