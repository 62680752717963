<template>
    <div>
        <h3 class="history-title">History:</h3>
        <div class="history-filters">
            <div class="organization-search">
                <img class="organization-search__refresh" @click="reloadPlasticCreditTransactionHistory" src="@/assets/reload-grey.svg" alt="refresh">
                <input v-model="searchOrganization"
                       id="orgSearch"
                       class="organization-search__input"
                       type="text"
                       placeholder="Search by Organization or Facility"
                       @keyup.enter="searchByOrganization = true"
                >
                <label class="organization-search__label" for="orgSearch" @click="searchByOrganization = true">Search</label>
            </div>

            <div class="date-and-action-filters">
                <p class="date-and-action-filters__clear" @click="clearFilters">All</p>
                <Datetime v-model="selectedDate"
                          :phrases="{ok: 'Select'}"
                          type="date"
                          placeholder="Filter by date"
                          format="yyyy-MM-dd"
                          input-class="date-and-action-filters__datetime">
                    <template slot="button-cancel">
                        <div @click="clearDate">
                            Clear
                        </div>
                    </template>
                </Datetime>
                <label>
                    <select class="date-and-action-filters__select" v-model="filterByTradeType">
                        <option selected :value="null">
                            Filter by Action
                        </option>
                        <option value="ISSUED">Issued</option>
                        <option value="REJECTED">Rejected</option>
                        <option value="ADMIN_PURCHASE">Admin Purchase</option>
                        <option value="CHANGE_HANDS">Ownership Change</option>
                    </select>
                </label>
            </div>
        </div>

        <md-divider class="divider"/>

        <div v-if="!loadingHistory">
            <md-table v-model="filteredPlasticCreditTransactionHistory">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell class="table-text" md-label="Date & Time">{{formattedDate(item.date)}}</md-table-cell>
                    <md-table-cell v-if="item.tradeType === 'ISSUED'" class="table-text table-text--adjust-width" md-label="Action">Issued</md-table-cell>
                    <md-table-cell v-else-if="item.tradeType === 'REJECTED'" class="table-text table-text--adjust-width" md-label="Action">Rejected</md-table-cell>
                    <md-table-cell v-else-if="item.tradeType === 'ADMIN_PURCHASE'" class="table-text table-text--adjust-width" md-label="Action">Admin purchase</md-table-cell>
                    <md-table-cell v-else class="table-text table-text--adjust-width" md-label="Action">Ownership change</md-table-cell>
                    <md-table-cell class="table-text" md-label="From">
                        <div v-if="item.seller">
                            <p> {{item.sellerFacilityOrganizationName}}</p>
                            <p> {{item.seller.ownerName}} </p>
                        </div>
                        <div v-else>
                            --
                        </div>
                    </md-table-cell>
                    <md-table-cell class="table-text" md-label="To">
                        <div v-if="item.buyer.ownerId !== adminOrganization.organizationId">
                            <p>{{item.buyerFacilityOrganizationName}}</p>
                            <p>{{item.buyer.ownerName}}</p>
                        </div>
                        <div v-else>
                            <p>{{item.buyer.ownerName}}</p>
                        </div>
                    </md-table-cell>
                    <md-table-cell class="table-text" md-label="Plastic Credits" v-if="item.amount > 0">{{ item.amount }}</md-table-cell>
                    <md-table-cell class="table-text" md-label="Plastic Credits" v-else>--</md-table-cell>
                    <md-table-cell class="table-text" md-label="Waste Type">
                        <p class="plastic-type">
                            {{ item.massBalanceDefinition.plasticType }}
                        </p>
                        <p class="plastic-type__property" v-for="(subCategory,index) in transform(item.massBalanceDefinition)" :key="index">
                            <span>· {{ subCategory }} </span>
                        </p>
                    </md-table-cell>
                    <md-table-cell class="table-text" md-label="Total Price">
                        <div v-if="item.payment">
                            {{item.payment.amount.toFixed(2)}}
                        </div>
                        <div v-else>
                            --
                        </div>
                    </md-table-cell>
                    <md-table-cell class="table-text" md-label="Currency">
                        <span v-if="item.payment">
                            {{item.payment.currency}}
                        </span>
                        <span v-else>
                            --
                        </span>
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </div>
    </div>
</template>

<script>
import {Datetime} from 'vue-datetime';
import moment from "moment";
import transform from "@/mixins/transformWasteDefinitionForDisplay";


export default {
        name: "PlasticCreditsHistory",
        components: { Datetime, },
        mixins: [transform],
        data() {
            return {
                searchOrganization: '',
                searchByOrganization: false,
                selectedDate: null,
                filterByTradeType: null,
                loadingHistory: true,
            }
        },
        async mounted() {
            if (!this.$store.state.plasticCreditStore.plasticCreditTransactionHistory.length || this.$store.state.plasticCreditStore.reloadPlasticCreditTransactionHistory) {
                await this.$store.dispatch('getPlasticCreditTransactionHistory')
            }
            if (!this.$store.state.organizationStore.adminOrganization) {
                await this.$store.dispatch('getAdminOrganization')
            }
            this.loadingHistory = false;
        },
        computed: {
            plasticCreditTransactionHistory() {
                return this.$store.state.plasticCreditStore.plasticCreditTransactionHistory;
            },
            filteredPlasticCreditTransactionHistory() {
                if (this.selectedDate || this.filterByTradeType || this.searchByOrganization) {
                    return this.applyFiltersToPlasticCreditTransactionHistory();
                }
                return this.plasticCreditTransactionHistory;
            },
            adminOrganization() {
                return this.$store.state.organizationStore.adminOrganization;
            }
        },
        watch: {
            searchOrganization() {
                if (this.searchOrganization === "") {
                    this.searchByOrganization = false
                }
            },
        },
        methods: {
            applyFiltersToPlasticCreditTransactionHistory() {
                let result = [...this.plasticCreditTransactionHistory]
                if (this.searchByOrganization) {
                    let userSearch = this.searchOrganization.toLowerCase().trim()
                    result = result.filter(historyItem => {
                        switch (historyItem.tradeType) {
                            case "REJECTED":
                                if (historyItem.buyer.ownerName) {
                                    return historyItem.buyer.ownerName.toLowerCase().trim().includes(userSearch) || historyItem.buyerFacilityOrganizationName.toLowerCase().trim().includes(userSearch);
                                } else break;

                            case "ISSUED":
                                if (historyItem.buyer.ownerName) {
                                    return historyItem.buyer.ownerName.toLowerCase().trim().includes(userSearch) || historyItem.buyerFacilityOrganizationName.toLowerCase().trim().includes(userSearch);
                                } else break;

                            case "ADMIN_PURCHASE":
                                // searching for Buyer's name (Empower), Seller's name (a facility), or the name of the selling organization
                               if (historyItem.buyer.ownerName) {
                                 return historyItem.buyer.ownerName.toLowerCase().trim().includes(userSearch)
                               } else if (historyItem.seller.ownerName) {
                                 return historyItem.seller.ownerName.toLowerCase().trim().includes(userSearch)
                               } else if (historyItem.sellerFacilityOrganizationName) {
                                 return historyItem.sellerFacilityOrganizationName.toLowerCase().trim().includes(userSearch)
                               } else break;

                            case "CHANGE_HANDS":
                                // if buyer and seller are both facilities
                                if (historyItem.buyerFacilityOrganizationName && historyItem.sellerFacilityOrganizationName) {
                                    return historyItem.buyer.ownerName.toLowerCase().trim().includes(userSearch) ||
                                           historyItem.seller.ownerName.toLowerCase().trim().includes(userSearch) ||
                                           historyItem.buyerFacilityOrganizationName.toLowerCase().trim().includes(userSearch) ||
                                           historyItem.sellerFacilityOrganizationName.toLowerCase().trim().includes(userSearch)
                                } else if (historyItem.buyerFacilityOrganizationName) {
                                    return historyItem.buyer.ownerName.toLowerCase().trim().includes(userSearch) ||
                                           historyItem.seller.ownerName.toLowerCase().trim().includes(userSearch) ||
                                           historyItem.buyerFacilityOrganizationName.toLowerCase().trim().includes(userSearch)
                                } else if (historyItem.sellerFaciltyOrganizationName) {
                                    return historyItem.buyer.ownerName.toLowerCase().trim().includes(userSearch) ||
                                           historyItem.seller.ownerName.toLowerCase().trim().includes(userSearch) ||
                                           historyItem.sellerFacilityOrganizationName.toLowerCase().trim().includes(userSearch)
                                } else {
                                    return historyItem.buyer.ownerName.toLowerCase().trim().includes(userSearch) ||
                                           historyItem.seller.ownerName.toLowerCase().trim().includes(userSearch)
                                }
                        }
                    })
                }
                if (this.filterByTradeType) {
                    result = result.filter(historyItem => {
                        return historyItem.tradeType === this.filterByTradeType
                    })
                }
                if (this.selectedDate) {
                    result = result.filter(historyItem => {
                        return moment(historyItem.date).format('YYYY-MM-DD') === moment(this.selectedDate).format('YYYY-MM-DD')
                    })
                }
                return result

            },
            async reloadPlasticCreditTransactionHistory() {
                await this.$store.dispatch('getPlasticCreditTransactionHistory')
            },
            formattedDate(date) {
                return moment(date).format('YYYY-MM-DD HH:mm')
            },
            clearDate() {
                this.selectedDate = null;
            },
            clearFilters(){
                this.searchByOrganization = false;
                this.searchOrganization = null;
                this.selectedDate = null;
                this.filterByTradeType = null;
            },
        },
    }
</script>

<style scoped lang="scss">
    @import "src/variables";

    .history-title {
        margin-bottom: 16px;
    }

    .history-filters {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .organization-search {
        display: flex;
        align-items: center;
        &__refresh {
            cursor: pointer;
        }
        &__input {
            height: 32px;
            width: 224px;
            border: 1px solid rgba(155, 155, 155, .5);
            font-size: 12px;
            line-height: 16px;
            margin: 0 16px 0 28px;
            padding: 8px;
            &::placeholder {
              color: $placeholder-grey;
            }
        }
        &__label  {
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            text-decoration-line: underline;
            color: $dark-bg;
            cursor: pointer;
        }
    }

    .date-and-action-filters{
        display: flex;
        width: 80%;
        justify-content: flex-end;

        &__clear {
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            text-decoration-line: underline;
            color: $dark-bg;
            margin: 8px 25px 0 0;
            cursor: pointer;
        }

        ::v-deep &__datetime {
            height: 30px;
            width: 150px;
            border: none;
            border-bottom: 1px solid #1B1B1B;
            font-size: 12px;
            line-height: 16px;
            margin-right: 15px;
            cursor: pointer;

            &::placeholder {
              color: black;
            }
        }

        &__select {
            height: 30px;
            width: 150px;
            color: $dark-bg;
            border: none;
            border-bottom: 1px solid $dark-bg;
            font-size: 12px;
            line-height: 16px;
            cursor: pointer;
        }
    }

    .divider {
        background-color: $placeholder-grey;
        margin-top: 16px;
    }

    .plastic-type {
        font-weight: bold;
    }

    // vue-material overrides //

    ::v-deep .md-table-head-container {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    ::v-deep .md-table-cell-container {
        white-space: nowrap;
        max-width: 200px;
    }

    ::v-deep .md-table-head-label {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: $placeholder-grey;
    }

    .table-text{
        font-size: 12px;
    }

    .table-text--adjust-width{
        min-width: 159px;
    }

</style>
