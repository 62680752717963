<template>
    <div>
        <ContentView :content-config="contentConfig"/>
    </div>
</template>

<script>
import ContentView from "../ContentView";

export default {
    name: "ViewDepositOrganization",
    components: {ContentView},
    created() {
        this.loadOrganization();
        this.loadSchemes();
        this.loadVoucherDefinitions();
        this.loadFacilities();
    },
    methods: {
        async loadOrganization() {
            this.depositOrg = await this.$depositAdminClient.getOrganization(this.$route.params.organizationId)
            this.contentConfig.sections[0].data.depositOrganizationId.value = this.depositOrg.id;
            this.contentConfig.sections[0].data.depositOrganizationName.value = this.depositOrg.name;
            this.contentConfig.sections[0].data.depositSigningPublicKey.value = this.depositOrg.signingPubKey;
            this.contentConfig.sections[0].data.depositEncryptionPublicKey.value = this.depositOrg.encryptionPubKey;

            this.org = await this.$organizationsService.getOrganization(this.$route.params.organizationId)
            this.contentConfig.sections[0].data.organizationId.value = this.org.organizationId;
            this.contentConfig.sections[0].data.organizationName.value = this.org.organizationName;
            this.contentConfig.sections[0].data.signingPublicKey.value = this.org.signingPublicKey;
            this.contentConfig.sections[0].data.encryptionPublicKey.value = this.org.encryptionPublicKey;
        },
        async loadSchemes() {
            const schemes = await this.$depositAdminClient.getSchemesForOrganization(this.$route.params.organizationId)
            this.contentConfig.sections[1].rows = [];
            for (const s of schemes) {
                this.contentConfig.sections[1].rows.push({
                    id: s.id,
                    data: [s.id, s.name],
                    callback: () => {
                        this.$router.push({
                            name: 'edit-scheme',
                            params: {
                                organizationId: this.$route.params.organizationId,
                                schemeId: s.id
                            }
                        })
                    }
                })
            }
        },
        async loadFacilities() {
            const facilities = await this.$facilityService.getOrganizationsFacilities(this.$route.params.organizationId)
            this.contentConfig.sections[3].rows = [];
            for (const f of facilities) {
                let isRegistered
                if (f.depositSchemeId && f.publicKey) {
                    try {
                        const scheme = await this.$depositAdminClient.getScheme(f.depositSchemeId)
                        isRegistered = (scheme.collectionPoints && scheme.collectionPoints.includes(f.publicKey))
                    } catch (err) {
                        console.error(err);
                        isRegistered = "FAILED TO FETCH SCHEMEID"
                    }

                }

                this.contentConfig.sections[3].rows.push({
                    id: f.facilityId,
                    data: [f.facilityId, f.facilityName, f.isDepositsEnabled, isRegistered, f.depositSchemeId, f.publicKey],
                    callback: () => {
                        const schemeId = prompt("Scheme ID?").trim()
                        if (!schemeId) {
                            alert("Invalid scheme ID, canceling")
                            return
                        }
                        this.$facilityService.enableDeposits(f.facilityId, true, schemeId)
                            .then(() => {
                                alert("SUCCESS!")
                                this.loadFacilities();
                            })
                            .catch(err => {
                                console.error(err)
                                alert(err);
                            })
                    }
                })
            }
        },
        async loadVoucherDefinitions() {
            const voucherDefs = await this.$depositAdminClient.getVoucherDefinitionsForOrganization(this.$route.params.organizationId)
            this.contentConfig.sections[2].rows = [];
            for (const vd of voucherDefs) {
                this.contentConfig.sections[2].rows.push({
                    id: vd.id,
                    data: [vd.id, vd.name, vd.pictureURL],
                    callback: () => {
                        this.$router.push({
                            name: 'edit-voucher-definition',
                            params: {
                                organizationId: this.$route.params.organizationId,
                                voucherDefinitionId: vd.id
                            }
                        })
                    }
                })
            }
        }
    },
    data() {
        return {
            depositOrg: undefined,
            org: undefined,
            contentConfig: {
                buttons: [
                    {
                        title: "Create Scheme",
                        isDark: true,
                        callback: () => {
                            this.$router.push({
                                name: 'create-scheme',
                                params: {organizationId: this.$route.params.organizationId}
                            })
                        }
                    },
                    {
                        title: "Create Voucher Definition",
                        isDark: true,
                        callback: () => {
                            this.$router.push({
                                name: 'create-voucher-definition',
                                params: {organizationId: this.$route.params.organizationId}
                            })
                        }
                    }
                ],
                sections: [
                    {
                        type: "info",
                        title: "Organization Info",
                        data: {
                            "depositOrganizationId": {
                                title: "Deposit BE: Organization ID",
                                value: ""
                            },
                            "depositOrganizationName": {
                                title: "Deposit BE: Organization Name",
                                value: ""
                            },
                            "depositSigningPublicKey": {
                                title: "Deposit BE: Signing public key",
                                value: "",
                            },
                            "depositEncryptionPublicKey": {
                                title: "Deposit BE: Encryption public key",
                                value: "",
                            },
                            "organizationId": {
                                title: "Platform BE: Organization ID",
                                value: ""
                            },
                            "organizationName": {
                                title: "Platform BE: Organization Name",
                                value: ""
                            },
                            "signingPublicKey": {
                                title: "Platform BE: Signing public key",
                                value: ""
                            },
                            "encryptionPublicKey": {
                                title: "Platform BE: Encryption public key",
                                value: ""
                            },
                        }
                    },
                    {
                        type: "table",
                        title: "Schemes",
                        columns: ["ID", "Name"],
                        rows: []
                    },
                    {
                        type: "table",
                        title: "Voucher Definitions",
                        columns: ["ID", "Name", "Picture URL"],
                        rows: []
                    },
                    {
                        type: "table",
                        title: "Facilities on Platform BE",
                        columns: ["ID", "Name", "Deposits enabled", "Registered on scheme", "Scheme ID", "Public key"],
                        rows: []
                    }
                ]
            }
        }
    }
}
</script>

<style scoped>

</style>
