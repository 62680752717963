<template>
    <ContentInput :content-config="contentConfig"/>
</template>

<script>
import ContentInput from "../ContentInput";

export default {
        name: "EditRedeemable",
        components: {ContentInput},
        created() {
           this.loadCoupons();
           this.loadRedeemable();
        },
        methods: {
            loadCoupons() {
                this.$cleanCityService.getCouponsByRedeemable(this.$route.params.redeemableId).then(coups => {
                    this.contentConfig.sections.redeemableInfo.inputs.availableCoupons.values = coups.coupons.map(coup => coup.code)
                })
            },
            loadRedeemable() {
                 this.$cleanCityService.getRedeemable(this.$route.params.redeemableId).then((redeemable) => {
                    this.contentConfig.sections.redeemableInfo.inputs.name.value = redeemable.name;
                    this.contentConfig.sections.redeemableInfo.inputs.goal.value = redeemable.goal;
                    this.contentConfig.sections.redeemableInfo.inputs.description.value = redeemable.description;
                    this.contentConfig.sections.redeemableInfo.inputs.type.value = redeemable.type;
                    this.contentConfig.sections.redeemableInfo.inputs.couponCost.value = redeemable.couponCost;
                    this.contentConfig.sections.redeemableInfo.inputs.availableCoupons.value = redeemable.availableCoupons;
                    this.contentConfig.sections.redeemableInfo.inputs.picture.value = redeemable.picture;
                    this.contentConfig.originalFileValue = redeemable.picture;
                }
            );
            }
        },
        data() {
            return {
                contentConfig: {
                    originalFileValue: undefined,
                    title: "Edit Redeemable",
                    buttons: [
                        {
                            title: "Update",
                            isDark: true,
                            callback: () => {

                                const redeemableInfoInputs = this.contentConfig.sections.redeemableInfo.inputs;
                                let redeemableClone =  {
                                        redeemableId: this.$route.params.redeemableId,
                                        name: redeemableInfoInputs.name.value,
                                        goal: redeemableInfoInputs.goal.value,
                                        description: redeemableInfoInputs.description.value,
                                        picture: redeemableInfoInputs.picture.value,
                                        type: redeemableInfoInputs.type.value,
                                        couponCost: 0,
                                        availableCoupons: []
                                }
                                if (redeemableClone.type.toUpperCase() === 'COUPON') {
                                    redeemableClone.couponCost = redeemableInfoInputs.couponCost.value;
                                    redeemableClone.availableCoupons = redeemableInfoInputs.availableCoupons.value;
                                }
                                if (redeemableClone.picture !== undefined && this.contentConfig.originalFileValue !== redeemableClone.picture) {
                                        this.$sharedFileUploadService.uploadFiles(redeemableClone.picture).then(files => {
                                            redeemableClone.picture = files[0].ssl_url
                                            this.$cleanCityService.editRedeemable(redeemableClone).then(() => {
                                                this.$router.push({
                                                    name: 'view-redeemable',
                                                    params: {redeemableId: this.$route.params.redeemableId}
                                                })
                                            })
                                        })
                                } else  {
                                        this.$cleanCityService.editRedeemable(redeemableClone).then(() => {
                                            this.$router.push({
                                                name: 'view-redeemable',
                                                params: {redeemableId: this.$route.params.redeemableId}
                                            })
                                         })
                                }
                            }
                        }
                    ],
                    sections: {
                        "redeemableInfo": {
                            title: "Redeemable Info:",
                            type: "info",
                            inputs: {
                                "name": {
                                    title: "name",
                                    type: "text",
                                    value: undefined
                                },
                                "goal": {
                                    title: "Goal",
                                    type: "number",
                                    value: 0
                                },
                                "description": {
                                    title: "Description",
                                    type: "text",
                                    value: undefined
                                },
                                "type": {
                                    title: "Type",
                                    type: "dropdown",
                                    values: ["DONATION", "COUPON"],
                                    value: ""
                                },
                                "availableCoupons": {
                                    title: "Available Coupons",
                                    type: "checkbox",
                                    value: [],
                                    values: []
                                },
                                "couponCost": {
                                    title: "Coupon Cost",
                                    type: "number",
                                    value: 0
                                },
                                "picture": {
                                    title: "Picture",
                                    value: undefined,
                                    type: "pictureupload",
                                    upload: (files) => {
                                       this.contentConfig.sections.redeemableInfo.inputs.picture.value = files
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
