import {apiService} from "@/main";

export default class {
    facilityEndpoint = process.env.VUE_APP_FACILITY_API_BASE_URL + '/api/v1/facility';

    async createFacility(facilityCreationObj) {
        return await apiService.call("POST", `${this.facilityEndpoint}/facilities/`,
            {
                    organizationId: facilityCreationObj.organizationId,
                    facilityName: facilityCreationObj.facilityName,
                    street: facilityCreationObj.street,
                    zip: facilityCreationObj.zip,
                    city: facilityCreationObj.city,
                    country: facilityCreationObj.country,
                    countryCode: facilityCreationObj.countryCode,
                    phoneNumber: facilityCreationObj.phoneNumber,
                    email: facilityCreationObj.email,
                    contactPerson: facilityCreationObj.contactPerson,
                    latitude: facilityCreationObj.lat,
                    longitude: facilityCreationObj.lng,
                    fromAdmin: true
            }
        )
    }

    async editFacility(facilityId, facilityUpdateObj) {
        return await apiService.call("PUT", `${this.facilityEndpoint}/facilities/${facilityId}`,
            {
                    facilityName: facilityUpdateObj.facilityName,
                    street: facilityUpdateObj.street,
                    zip: facilityUpdateObj.zip,
                    city: facilityUpdateObj.city,
                    country: facilityUpdateObj.country,
                    countryCode: facilityUpdateObj.countryCode,
                    phoneNumber: facilityUpdateObj.phoneNumber,
                    email: facilityUpdateObj.email,
                    contactPerson: facilityUpdateObj.contactPerson,
                    latitude: facilityUpdateObj.latitude,
                    longitude: facilityUpdateObj.longitude,
                    fromAdmin: true
            }
        )
    }

    async deleteFacility(facilityId) {
        await apiService.call("DELETE", `${this.facilityEndpoint}/facilities/${facilityId}`)
    }

    async getFacility(facilityId) {
        return await apiService.call("GET", `${this.facilityEndpoint}/facilities/${facilityId}`)
    }

    async getAllFacilities() {
        const response = await apiService.call("GET", `${this.facilityEndpoint}/facilities/`)
        return response.facilities
    }

    async getOrganizationsFacilities(organizationId) {
        const response = await apiService.call("GET", `${this.facilityEndpoint}/facilities?organizationId=${organizationId}`)
        return response.facilities
    }

    async enableDeposits(facilityId, enable, schemeId) {
        await apiService.call("POST", `${this.facilityEndpoint}/facilities/${facilityId}/enable-deposits`, {
            enable,
            schemeId
        })
    }

    async getOrganizationsFacilitiesId(organizationId) {
        const response = await apiService.call("GET",`${this.facilityEndpoint}/facilities?organizationId=${organizationId}`)
        let facilityIds = []
        for (const facility of response.facilities) {
            facilityIds.push(facility.facilityId)
        }
        return facilityIds
    }

    async uploadFacilityPicture(facilityId, url) {
        return await apiService.call("PUT",`${this.facilityEndpoint}/facilities/${facilityId}/picture`, {url: url})
    }

    async deleteFacilityPicture(facilityId, url) {
        const urlEncoded = encodeURIComponent(encodeURIComponent(url))
        return await apiService.call('DELETE',`${this.facilityEndpoint}/facilities/${facilityId}/picture/${urlEncoded}`)
    }

    async getMaterialTypeCategoryInfo() {
        return await apiService.call("GET", `${this.facilityEndpoint}/materialtypes/default`)
    }

    async getDelterraMaterialTypeCategories() {
        return await apiService.call("GET", `${this.facilityEndpoint}/materialtypes/delterra/default`)
    }

    async postMaterialTypeCategory(categoryTypes, isActive) {
        let url = (isActive) ? `${this.facilityEndpoint}/materialtypes/default/active` : `${this.facilityEndpoint}/materialtypes/default/optional`
        return await apiService.call("POST", url, {types: categoryTypes})
    }

    async postDelterraMaterialTypeCategory(categoryTypes, isActive) {
        let url = (isActive) ? `${this.facilityEndpoint}/materialtypes/delterra/default/active` : `${this.facilityEndpoint}/materialtypes/delterra/default/optional`
        return await apiService.call("POST", url, {types: categoryTypes})
    }

}
