import {depositService, facilityService} from "@/main";

export const facilityStore = {

    state: {
        facilities: [],
        collectionPoints: []
    },

    actions: {
        async getAllFacilities({commit}) {
            try {
                const allFacilities = await facilityService.getAllFacilities();
                commit('setAllFacilities', allFacilities);
            } catch {
                commit('setError');
            }

        },
        async getAllCollectionPoints({commit}) {
            try {
                const allCollectionPoints = await depositService.getAllCollectionPoints()
                commit('setAllCollectionPoints', allCollectionPoints)
            } catch {
                commit('setError')
            }

}
    },

    mutations: {
        setAllFacilities(state, allFacilities) {
            state.facilities = allFacilities;
        },
        setAllCollectionPoints(state, allCollectionPoints) {
            state.collectionPoints = allCollectionPoints
        }
    },

    getters: {
        returnOneFacilityDetails: state => (facId) => {
            return state.facilities.find(facility => facility.facilityId === facId)
        }
    }
}
