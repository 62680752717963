<template>
    <div>
        <input type="text" v-on:input="search($event)">
        <div v-on:click="selectCallback(user)" v-for="user of matchedUsers" v-bind:key="user.userId">
            {{user.firstName}} {{user.lastName}} ({{user.email}})
        </div>
    </div>
</template>

<script>
    export default {
        name: "UserSearch",
        props: {
            selectCallback: Function,
            usersToIgnore: {
                type: Array,
                default: function () {
                    return [];
                }
            }
        },
        methods: {
            search(event) {
                this.matchedUsers = [];
                const searchTerm = event.target.value;
                const usersToSearch = this.users.filter(
                    user => !this.usersToIgnore.some(
                        userToIgnore => user.userId === userToIgnore.userId));

                for (const user of usersToSearch) {
                    if ((user.firstName && user.firstName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) ||
                        (user.lastName && user.lastName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) ||
                        (user.email && user.email.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)) {
                        this.matchedUsers.push(user);
                    }
                }
            }
        },
        data() {
            return {
                matchedUsers: [],
                users: []
            }
        },
        created() {
            this.$userService.getAllUsers().then(users => {
                this.users = users;
            });
        }
    }
</script>

<style scoped>

</style>
