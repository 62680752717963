<template>
    <div>
        <Modal :close-callback="closeCallback" v-if="showOrganizationModal">
            <OrganizationSearch :select-callback="organizationSelected"/>
        </Modal>
        <Modal :close-callback="closeCallback" v-if="showFacilityModal">
            <FacilitySearch
                :select-callback="facilitiesSelected"
                :multi-select="true"
                :select-all-facilities="allFacilitiesSelected"
                :organization-id="contentConfig.sections.trackingFilters.inputs.organizationId.value"
                :is-open="showFacilityModal"/>
        </Modal>
        <ContentInput :content-config="contentConfig"/>
        <button @click="setAllOrganizationsSelected">Select all facilities</button>

        <div v-if="report">
            <table id='trackingReport'>
                <tr>
                    <td>
                        <hr>
                        <h2>Report</h2>
                        <div>Organization: {{ organizationName }}</div>
                        <div>Facilities <span v-for="(facilityName, facilityIndex) of facilityNames"
                                              v-bind:key="facilityIndex">{{ facilityName }}, </span>
                        </div>
                        <div>Period: {{ from.toDateString() }} - {{ to.toDateString() }}</div>
                        <br>
                        <h3>Inventory balance per {{ reportDate.toDateString() }}</h3>
                        <table class="table-with-lines">
                            <thead>
                            <th>Waste Definition</th>
                            <th>Balance</th>
                            </thead>
                            <tbody>
                            <tr v-for="(balance, balanceIndex) of report.balances" v-bind:key="balanceIndex">
                                <td v-if="balance.type === 'MASS_BALANCE'">
                                    {{ $wasteService.getMassBalanceDefinitionName(balance.massBalanceDefinition) }}
                                </td>
                                <td v-if="balance.type === 'MASS_BALANCE'">
                                    {{
                                        balance.amount.toLocaleString('en-US', {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 4
                                        })
                                    }} kg
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <br>
                        <h3>Summary from period {{ from.toDateString() }} - {{ to.toDateString() }}</h3>
                        <br>
                        <h4>Received</h4>
                        <table class="table-with-lines">
                            <thead>
                            <th>Waste Definition</th>
                            <th>Balance</th>
                            </thead>
                            <tbody>
                            <tr v-for="(received, receivedIndex) of report.received" v-bind:key="receivedIndex">
                                <td v-if="received.type === 'MASS_BALANCE'">
                                    {{ $wasteService.getMassBalanceDefinitionName(received.massBalanceDefinition) }}
                                </td>
                                <td v-if="received.type === 'MASS_BALANCE'">
                                    {{
                                        received.amount.toLocaleString('en-US', {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 4
                                        })
                                    }} kg
                                </td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>{{
                                        totalReceived.toLocaleString('en-US', {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 4
                                        })
                                    }} kg
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <br>
                        <h4>Sent</h4>
                        <table class="table-with-lines">
                            <thead>
                            <th>Waste Definition</th>
                            <th>Balance</th>
                            </thead>
                            <tbody>
                            <tr v-for="(sent, sentIndex) of report.sent" v-bind:key="sentIndex">
                                <td>{{ $wasteService.getMassBalanceDefinitionName(sent.massBalanceDefinition) }}</td>
                                <td>{{
                                        sent.amount.toLocaleString('en-US', {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 4
                                        })
                                    }} kg
                                </td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>{{
                                        totalSent.toLocaleString('en-US', {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 4
                                        })
                                    }} kg
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <br>
                        <h4>Processed</h4>
                        <table class="table-with-lines">
                            <thead>
                            <th>Input</th>
                            <th>Output</th>
                            <th>Production Loss</th>
                            </thead>
                            <tbody>
                            <tr v-for="(processed, processedIndex) of report.processed" v-bind:key="processedIndex">
                                <td>
                                    <table>
                                        <tr v-for="(waste, wasteIndex) of processed.input"
                                            v-bind:key="wasteIndex">
                                            <td>
                                                {{ $wasteService.getMassBalanceDefinitionName(waste.massBalanceDefinition) }}
                                            </td>
                                            <td>{{
                                                    waste.amount.toLocaleString('en-US', {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 4
                                                    })
                                                }} kg
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td>
                                    <table>
                                        <tr v-for="(waste, wasteIndex) of processed.output"
                                            v-bind:key="wasteIndex">
                                            <td>
                                                {{ $wasteService.getMassBalanceDefinitionName(waste.massBalanceDefinition) }}
                                            </td>
                                            <td>{{
                                                    waste.amount.toLocaleString('en-US', {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 4
                                                    })
                                                }} kg
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td>
                                    {{
                                        (processed.input.reduce((p, c) => p + c.amount, 0) - processed.output.reduce((p, c) => p + c.amount, 0)).toLocaleString('en-US', {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 4
                                        })
                                    }} kg
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <br>
                        <h4>Transaction History</h4>
                        <table class="table-with-lines">
                            <thead>
                            <th>Date</th>
                            <th>User selected date: Delivery</th>
                            <th>User selected date: Receive</th>
                            <th>Delivery</th>
                            <th>Receiver</th>
                            <th>Tx Type</th>
                            <th>Waste</th>
                            <th>Delivery Files</th>
                            <th>Reception Files</th>
                            <th>Process Waste Output</th>
                            </thead>
                            <tbody>
                            <tr v-for="(transaction, transactionIndex) of report.transactionHistory"
                                v-bind:key="transactionIndex">
                                <td>
                                    {{ getDateFormatted(transaction.transactionData.completedDateTime) }}
                                </td>
                                <td>
                                    {{ getUserSelectedDateForDelivery(transaction.transactionData) }}
                                </td>
                                <td>
                                    {{ getUserSelectedDateForReception(transaction.transactionData) }}
                                </td>
                                <td>
                                    {{ transaction.transactionData.delivererFacilityName ? "Facility: " + transaction.transactionData.delivererFacilityName : transaction.transactionData.delivererUserId ? "Verified UserId " + transaction.transactionData.delivererUserId : "Manual Deliverer: " + transaction.transactionData.manualDeliverer }}
                                </td>
                                <td>
                                    {{ transaction.transactionData.recipientFacilityName ? "Facility: " + transaction.transactionData.recipientFacilityName : "Manual Recipient: " + transaction.transactionData.manualRecipient }}
                                </td>
                                <td>
                                    {{ transaction.transactionType }}
                                </td>
                                <td>
                                    <table v-if="transaction.transactionData.delivererWasteAmounts.length > 0">
                                        <tr v-for="(waste, wasteIndex) of transaction.transactionData.delivererWasteAmounts"
                                            v-bind:key="wasteIndex">
                                            <td v-if="waste.type === 'MASS_BALANCE'">
                                                {{ $wasteService.getMassBalanceDefinitionName(waste.massBalanceDefinition) }}
                                            </td>
                                            <td v-if="waste.type === 'MASS_BALANCE'">{{
                                                    waste.amount.toLocaleString('en-US', {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 4
                                                    })
                                                }} kg
                                            </td>
                                            <td v-if="waste.type === 'DIGITAL_TWIN'">Digital Twins Name:
                                                {{ waste.digitalTwinsName }}
                                            </td>
                                            <td v-if="waste.type === 'DIGITAL_TWIN'">{{ waste.numberOfDigitalTwins }}
                                            </td>
                                            <td v-if="waste.type === 'DIGITAL_TWIN'">
                                                <table>
                                                    <tr v-for="(insideWaste, insideWasteIndex) of waste.digitalTwinsInventory"
                                                        v-bind:key="insideWasteIndex">
                                                        <td v-if="insideWaste.type === 'MASS_BALANCE'">
                                                            {{ $wasteService.getMassBalanceDefinitionName(insideWaste.massBalanceDefinition) }}
                                                        </td>
                                                        <td v-if="insideWaste.type === 'MASS_BALANCE'">{{
                                                                insideWaste.amount.toLocaleString('en-US', {
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 4
                                                                })
                                                            }} kg
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                    <table v-else>
                                        <tr v-for="(waste, wasteIndex) of transaction.transactionData.recipientWasteAmounts"
                                            v-bind:key="wasteIndex">
                                            <td>
                                                {{ $wasteService.getMassBalanceDefinitionName(waste.massBalanceDefinition) }}
                                            </td>
                                            <td v-if="waste.type === 'MASS_BALANCE'">
                                                {{
                                                    waste.amount.toLocaleString('en-US', {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 4
                                                    })
                                                }} kg
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td>
                                    <div v-if="transaction.transactionData.delivererTransactionMetadata">
                                        <div
                                            v-for="(file, urlIndex) of transaction.transactionData.delivererTransactionMetadata.files"
                                            v-bind:key="urlIndex">
                                            <a :href="file.url" target="_blank">{{
                                                    getImageNameWithTime(file, transaction.transactionData)
                                                }}</a>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="transaction.transactionData.recipientTransactionMetadata">
                                        <div
                                            v-for="(file, urlIndex) of transaction.transactionData.recipientTransactionMetadata.files"
                                            v-bind:key="urlIndex">
                                            <a :href="file.url" target="_blank">{{
                                                    getImageNameWithTime(file, transaction.transactionData)
                                                }}</a>
                                        </div>
                                    </div>

                                </td>
                                <td>
                                    <div v-if="transaction.transactionType === 'PROCESS'">
                                        <table>
                                            <tr v-for="(waste, wasteIndex) of transaction.transactionData.recipientWasteAmounts"
                                                v-bind:key="wasteIndex">
                                                <td v-if="waste.type === 'MASS_BALANCE'">
                                                    {{ $wasteService.getMassBalanceDefinitionName(waste.massBalanceDefinition) }}
                                                </td>
                                                <td v-if="waste.type === 'MASS_BALANCE'">{{
                                                        waste.amount.toLocaleString('en-US', {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 4
                                                        })
                                                    }} kg
                                                </td>
                                                <td v-if="waste.type === 'DIGITAL_TWIN'">Digital Twins Name:
                                                    {{ waste.digitalTwinsName }}
                                                </td>
                                                <td v-if="waste.type === 'DIGITAL_TWIN'">
                                                    {{ waste.numberOfDigitalTwins }}
                                                </td>
                                                <td v-if="waste.type === 'DIGITAL_TWIN'">
                                                    <table>
                                                        <tr v-for="(insideWaste, insideWasteIndex) of waste.digitalTwinsInventory"
                                                            v-bind:key="insideWasteIndex">
                                                            <td v-if="insideWaste.type === 'MASS_BALANCE'">
                                                                {{ $wasteService.getMassBalanceDefinitionName(insideWaste.massBalanceDefinition) }}
                                                            </td>
                                                            <td v-if="insideWaste.type === 'MASS_BALANCE'">{{
                                                                    insideWaste.amount.toLocaleString('en-US', {
                                                                        minimumFractionDigits: 0,
                                                                        maximumFractionDigits: 4
                                                                    })
                                                                }} kg
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>

<script>
import OrganizationSearch from "../OrganizationSearch";
import ContentInput from "../ContentInput";
import Modal from "../Modal";
import FacilitySearch from "../FacilitySearch";
import moment from 'moment';

export default {
    name: "TrackingReports",
    components: {OrganizationSearch, FacilitySearch, ContentInput, Modal},
    methods: {
        async setAllOrganizationsSelected() {
            this.contentConfig.sections.trackingFilters.inputs.organizationId.value = this.adminOrganization.organizationId;
            this.contentConfig.sections.trackingFilters.inputs.facilities.values = await this.$facilityService.getAllFacilities();
            this.allFacilitiesSelected = true;
        },
        closeCallback() {
            this.showOrganizationModal = false;
            this.showFacilityModal = false;
        },
        organizationSelected(organization) {
            this.showOrganizationModal = false;
            this.contentConfig.sections.trackingFilters.inputs.organizationId.value = organization.organizationId
        },
        facilitiesSelected(facilities) {
            this.showFacilityModal = false;
            this.contentConfig.sections.trackingFilters.inputs.facilities.values = facilities
            this.allFacilitiesSelected = false;
        },
        dosomething: function (event) {
            tableToExcel('trackingReport', 'Summary', 'TestExport');
        },
        getIEVersion: function ()
            // Returns the version of Windows Internet Explorer or a -1
            // (indicating the use of another browser).
        {
            var rv = -1; // Return value assumes failure.
            if (navigator.appName == 'Microsoft Internet Explorer') {
                var ua = navigator.userAgent;
                var re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
                if (re.exec(ua) != null)
                    rv = parseFloat(RegExp.$1);
            }
            return rv;
        },
        tableToExcel: function (table, sheetName, fileName) {


            var ua = window.navigator.userAgent;
            var msie = ua.indexOf("MSIE ");
            if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))      // If Internet Explorer
            {
                return this.fnExcelReport(table, fileName);
            }

            var uri = 'data:application/vnd.ms-excel;base64,',
                templateData = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
                base64Conversion = function (s) {
                    return window.btoa(unescape(encodeURIComponent(s)))
                },
                formatExcelData = function (s, c) {
                    return s.replace(/{(\w+)}/g, function (m, p) {
                        return c[p];
                    })
                }

            $("tbody > tr[data-level='0']").show();

            if (!table.nodeType)
                table = document.getElementById(table)

            var ctx = {worksheet: sheetName || 'Worksheet', table: table.innerHTML}

            var element = document.createElement('a');
            element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + base64Conversion(formatExcelData(templateData, ctx)));
            element.setAttribute('download', fileName);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);

            $("tbody > tr[data-level='0']").hide();
        },
        fnExcelReport: function (table, fileName) {

            var tab_text = "<table border='2px'>";
            var textRange;

            if (!table.nodeType)
                table = document.getElementById(table)

            $("tbody > tr[data-level='0']").show();
            tab_text = tab_text + table.innerHTML;

            tab_text = tab_text + "</table>";
            tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, "");//remove if u want links in your table
            tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
            tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

            txtArea1.document.open("txt/html", "replace");
            txtArea1.document.write(tab_text);
            txtArea1.document.close();
            txtArea1.focus();
            sa = txtArea1.document.execCommand("SaveAs", false, fileName + ".xls");
            $("tbody > tr[data-level='0']").hide();
            return (sa);
        },

        getImageNameWithTime(file, transaction) {
            let name = file.timeStampedTitle || file.title || file.url;
            let time = transaction.completedDateTime ? transaction.completedDateTime : transaction.initiatedDateTime;
            time = new Date(time).getTime();
            return `${time}_${name}`;
        },

        getUserSelectedDateForDelivery(metaData) {
            if (!metaData.delivererTransactionMetadata) {
                return null;
            }
            const date = metaData.delivererTransactionMetadata.manualDateTime;
            return this.getDateFormatted(date);
        },

        getUserSelectedDateForReception(metaData) {
            if (!metaData.recipientTransactionMetadata) {
                return null;
            }
            const date = metaData.recipientTransactionMetadata.manualDateTime;
            return this.getDateFormatted(date);
        },

        getDateFormatted(date) {
            return moment(date).format('MMMM Do YYYY, h:mm');
        }
    },
    async mounted() {
        await this.$store.dispatch('getAdminOrganization')
        this.adminOrganization = this.$store.state.organizationStore.adminOrganization
    },
    data() {
        return {
            showOrganizationModal: false,
            showFacilityModal: false,
            totalReceived: 0,
            totalSent: 0,
            report: undefined,
            reportDate: undefined,
            organizationName: undefined,
            facilityNames: [],
            adminOrganization: undefined,
            to: undefined,
            from: undefined,
            allFacilitiesSelected: false,
            contentConfig: {

                title: "Tracking Reports",
                buttons: [
                    {
                        title: "Fetch Report",
                        isDark: true,
                        callback: () => {
                            const toEndOfDay = new Date(this.contentConfig.sections.trackingFilters.inputs.period.to)

                            if (new Date(this.contentConfig.sections.trackingFilters.inputs.period.from) > toEndOfDay) {
                                alert("Invalid date range")
                                return
                            }
                            toEndOfDay.setHours(23, 59, 59, 999);
                            const filters = {
                                organizationId: this.contentConfig.sections.trackingFilters.inputs.organizationId.value,
                                facilities: this.contentConfig.sections.trackingFilters.inputs.facilities.values.map(facility => facility.facilityId),
                                from: new Date(this.contentConfig.sections.trackingFilters.inputs.period.from),
                                to: toEndOfDay
                            }
                            this.from = filters.from;
                            this.to = filters.to;
                            this.$organizationsService.getOrganization(filters.organizationId).then(organization => {
                                this.organizationName = organization.organizationName;
                            });
                            this.facilityNames = this.contentConfig.sections.trackingFilters.inputs.facilities.values.map(facility => facility.facilityName)
                            this.$reportService.createReport(filters.facilities, filters.from, filters.to).then(report => {
                                this.report = report
                                this.reportDate = new Date()
                                this.totalReceived = report.received.reduce((accumulator, currentValue) => {
                                    return accumulator + currentValue.amount
                                }, 0)
                                this.totalSent = report.sent.reduce((accumulator, currentValue) => {
                                    return accumulator + currentValue.amount
                                }, 0)
                            });


                        }
                    },
                    {
                        title: "Export to xls",
                        isDark: true,
                        callback: () => {
                            this.tableToExcel('trackingReport', 'Summary', 'Tracking Report')
                        }
                    }
                ],
                sections: {
                    "trackingFilters": {
                        title: "Tracking Report Filters",
                        type: "info",
                        inputs: {
                            "organizationId": {
                                title: "OrganizationID",
                                type: "text",
                                value: undefined,
                                onFocus: () => {
                                    this.showOrganizationModal = true
                                }
                            },
                            "facilities": {
                                title: "Facilities",
                                type: "array",
                                label: "facilityName",
                                values: undefined,
                                selectCallback: () => {
                                    if (this.contentConfig.sections.trackingFilters.inputs.organizationId.value) {
                                        this.showFacilityModal = true
                                    } else {
                                        alert("Need to select organization first")
                                    }
                                }
                            },
                            "period": {
                                title: "Period",
                                type: "date-range",
                                to: undefined,
                                from: undefined
                            }
                        }
                    }
                }
            }
        }
    }

}
</script>

<style lang="scss" scoped>
table {
    border-collapse: collapse;
    border-spacing: 0;
}

.table-with-lines {
    th {
        border: 1px solid black;
        font-weight: bold;
    }

    tr {
        border: 1px solid black;
    }

    td {
        border: 1px solid black;
    }
}
</style>
