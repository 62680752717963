export default class {
    numberOfRequestsRunning = 0;
    activateLoading = () => {};
    deactivateLoading = () => {};

    constructor() {

    }

    addLoadingItem() {
        this.numberOfRequestsRunning++;
        this.checkLoading();
    }

    removeLoadingItem() {
        this.numberOfRequestsRunning--;
        this.checkLoading();
    }

    checkLoading() {
        if (this.numberOfRequestsRunning === 0) {
            this.deactivateLoading();
        } else {
            this.activateLoading();
        }
    }

    registerActivateLoading(fun) {
        this.activateLoading = fun;
    }

    registerDeactivateLoading(fun) {
        this.deactivateLoading = fun;
    }



}
