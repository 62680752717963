import {organizationsService, userService} from "@/main";
import moment from "moment";

export const organizationStore = {
    state: {
        organizations: [],
        adminOrganization: null,
        organizationDetails: null,
    },
    actions: {

        async getAllOrganizations({commit}) {
            try {
                const allOrganizations = await organizationsService.getAllOrganizations();
                commit('setAllOrganizations', allOrganizations);
            } catch {
                commit('setError');
            }

        },

        async getAdminOrganization({commit}) {
            try {
                const adminOrganization = await userService.getOrganization();
                commit('setAdminOrganization', adminOrganization);
            } catch {
                commit('setError');
            }
        },

        async getOneOrganization({commit}, organizationId) {
            try {
                const organization = await organizationsService.getOrganization(organizationId);
                commit('setOrganizationDetails', organization);
            }   catch (e) {
                commit('setError', e.message);
            }
        },

        async getVerificationDocuments({commit}, organizationId) {
            try {
                const response = await organizationsService.getDueDiligenceDocument(organizationId);
                if (response != null) {
                    commit('setDueDiligenceDocuments', response);
                }
            } catch (e) {
                commit('setError', e.message)
            }
        },
    },
    mutations: {
        setAllOrganizations(state, allOrganizations) {
            state.organizations = allOrganizations;
        },

        setAdminOrganization(state, adminOrganization) {
            state.adminOrganization = adminOrganization
        },

        setOrganizationDetails(state, organization) {
            state.organizationDetails = organization;
        },

        setDueDiligenceDocuments(state, verification) {
            state.organizationDetails.verificationDocument = verification;
        },
    },
    getters: {
        organizationsWithRegisteredDate: state => {
            if (state.organizations.length > 0) {
                return state.organizations.map(org => {
                    org.date = org.registered ? moment(org.registered).format("DD-MM-YYYY HH:mm") : null;
                    return org;
                })
            }
        },
        returnOneOrganizationDetails: state => (orgId) => {
            return state.organizations.find(organization => organization.organizationId === orgId)
        }
    }
}
