<template>
    <div>
        <ContentInput :content-config="contentConfig"/>
    </div>
</template>

<script>
import ContentInput from "../ContentInput";

export default {
        name: "CreateCoupon",
        components: {ContentInput},
        data() {
            return {
                 contentConfig: {
                    title: "New Coupon",
                    buttons: [
                        {
                            title: "Create Coupon",
                            isDark: true,
                            callback: () => {
                                const couponInfoInputs = this.contentConfig.sections.couponInfo.inputs;
                                let couponClone =  {
                                        code: couponInfoInputs.code.value,
                                     }
                                     this.$cleanCityService.createCoupon(couponClone).then(() => this.$router.push({name: 'all-coupons'}))
                            }
                        }
                    ],
                    sections: {
                        "couponInfo": {
                            title: "Coupon Info:",
                            type: "info",
                            inputs: {                           
                                "code": {
                                    title: "Coupon Code",
                                    type: "text",
                                    value: undefined
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
