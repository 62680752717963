<template>
    <DataTable :data-config="dataConfig" @reload="reloadUsers"/>
</template>

<script>
import DataTable from "../DataTable";

export default {
        name: "AllUsers",
        components: {DataTable},
        async mounted() {
          if (this.allUsers.length === 0) {
            await this.$store.dispatch('getAllUsers');
          }
          await this.setUsers();
        },
        data() {
            return {
                dataConfig: {
                    columns: [
                        "Name", "E-mail", "Country Code", "Mobile", "Country"
                    ],
                    rows: []
                }
            }
        },
        computed: {
            allUsers() {
              return this.$store.state.userStore.users;
            }
        },
        methods: {
            async reloadUsers() {
              await this.$store.dispatch('getAllUsers');
              await this.setUsers();
            },
            async setUsers() {
              this.dataConfig.rows = [];
              for (let user of this.allUsers) {
                this.dataConfig.rows.push({
                  id: user.userId,
                  selected: false,
                  data: {
                    name: `${user.firstName} ${user.lastName}`,
                    email: user.email,
                    countryCode: user.countryCode,
                    phoneNumber: user.phoneNumber,
                    country: user.country
                  },
                  callback: () => {
                    this.$router.push({
                      name: 'view-user',
                      params: {userId: user.userId}
                    })
                  }
                })
              }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
