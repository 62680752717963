<template>
  <ContentView :content-config="contentConfig" />
</template>

<script>
import ContentView from "../ContentView";

export default {
  name: "ViewPickUpPost",
  components: { ContentView },
  created() {
    this.$cleanCityService
      .getPickUpPost(this.$route.params.litterPickUpId)
      .then((pickUpPost) => {
        this.contentConfig.sections[0].data.litterPickUpId.value =
          pickUpPost.litterPickUpId;
        this.contentConfig.sections[0].data.points.value = pickUpPost.points;
        this.contentConfig.sections[0].data.category.value =
          pickUpPost.category;
        this.contentConfig.sections[0].data.comment.value = pickUpPost.comment;
        //this.contentConfig.sections[0].data.contactPerson.value = facility.contactPerson;
        this.contentConfig.sections[0].data.lat.value = pickUpPost.lat;
        this.contentConfig.sections[0].data.lng.value = pickUpPost.lng;
        this.contentConfig.sections[0].data.pictures.values =
          pickUpPost.pictures;
      });
  },
  data() {
    return {
      contentConfig: {
        buttons: [
          {
            title: "Remove",
            isDark: true,
            callback: () => {
              const remove = confirm(
                "Are you sure you want to remove this post?"
              );
              if (remove) {
                this.$cleanCityService
                  .removePickUpPost(this.$route.params.litterPickUpId)
                  .then(() => {
                    return this.$router.push({
                      name: "litter-pickups",
                      params: {},
                    });
                  });
              }
            },
          },
        ],
        sections: [
          {
            type: "info",
            title: "PickUp Post Info",
            data: {
              litterPickUpId: {
                title: "PickUp Post ID",
                value: "",
              },
              points: {
                title: "Points",
                value: "",
              },
              category: {
                title: "Category",
                value: "",
              },
              comment: {
                title: "Comment",
                value: "",
              },
              lat: {
                title: "Latitude",
                value: "",
              },
              lng: {
                title: "Longitude",
                value: "",
              },
              test: {
                title: "Pictures",
                type: "multiple",
                values: [],
                typeDefinition: "img",
              },
              pictures: {
                title: "",
                type: "multiple",
                values: [],
                typeDefinition: "img",
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
</style>
