import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import AllUsers from "./components/users/AllUsers";
import ViewUser from "./components/users/ViewUser";
import EditUser from './components/users/EditUser';
import CreateNewUser from "./components/users/CreateNewUser";
import AllOrganizations from "./components/users/AllOrganizations";
import CreateNewOrganization from "./components/users/CreateNewOrganization";
import ViewOrganization from "./components/users/ViewOrganization";
import EditOrganization from "./components/users/EditOrganization";
import TrackingOrganizations from "./components/tracking/TrackingOrganizations";
import Facilities from "./components/tracking/Facilities";
import ViewFacility from "./components/tracking/ViewFacility";
import EditFacility from "./components/tracking/EditFacility";
import CreateFacility from "./components/tracking/CreateFacility";
import ViewTrackingOrganization from "./components/tracking/ViewTrackingOrganization";
import History from "./components/tracking/History";
import PickUps from "./components/cleancity/PickUps"
import Cleaned from "./components/cleancity/Cleaned"
import ViewPickUpPost from "./components/cleancity/ViewPickUpPost"
import ViewReportPost from "./components/cleancity/ViewReportPost"
import CreateRedeemable from "./components/cleancity/CreateRedeemable"
import EditRedeemable from "./components/cleancity/EditRedeemable"
import ViewRedeemable from "./components/cleancity/ViewRedeemable"
import Redeemables from "./components/cleancity/Redeemables"
import CreateGroup from "./components/cleancity/CreateGroup"
import ViewGroup from "./components/cleancity/ViewGroup"
import Groups from "./components/cleancity/Groups"
import CreateCoupon from "./components/cleancity/CreateCoupon"
import EditCoupon from "./components/cleancity/EditCoupon"
import ViewCoupon from "./components/cleancity/ViewCoupon"
import Coupons from "./components/cleancity/Coupons"
import Reports from "./components/cleancity/Reports"
import Login from "./views/Login";
import TrackingReports from "./components/tracking/TrackingReports";
import PlasticCredits from "./components/tracking/plasticCredits/PlasticCredits";
import AllCollectionPoints from "./components/deposit/AllCollectionPoints";
import ViewCollectionPoint from "./components/deposit/ViewCollectionPoint";
import CreateCollectionPoint from "./components/deposit/CreateCollectionPoint";
import ViewCertificate from "@/components/deposit/ViewCertificate";
import ViewCleanedPost from "./components/cleancity/ViewCleanedPost";
import CategoryManager from "./components/tracking/CategoryManager"
import CategoryManagerDelterra from "./components/tracking/CategoryManagerDelterra"
import UsersEmpact from "./components/cleancity/UsersEmpact";
import DepositOrganizations from "./components/newDeposit/DepositOrganizations";
import CreateDepositOrganization from "./components/newDeposit/CreateDepositOrganization";
import ViewDepositOrganization from "./components/newDeposit/ViewDepositOrganization";
import CreateVoucherDefinition from "./components/newDeposit/CreateVoucherDefinition";
import CreateScheme from "./components/newDeposit/CreateScheme";
import EditVoucherDefinition from "./components/newDeposit/EditVoucherDefinition";
import EditScheme from "./components/newDeposit/EditScheme";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                requiresLogin: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/users',
            redirect: '/users/all-users',
            component: () => import('./views/Users.vue'),
            meta: {
                requiresLogin: true
            },
            children: [
                {
                    name: 'all-users',
                    path: 'all-users',
                    component: AllUsers
                },
                {
                    name: 'view-user',
                    path: 'all-users/:userId',
                    component: ViewUser
                },
                {
                    name: 'create-user',
                    path: 'create-user',
                    component: CreateNewUser
                },
                {
                    name: 'edit-user',
                    path: 'edit-user/:userId',
                    component: EditUser
                },
                {
                    name: 'all-organizations',
                    path: 'all-organizations',
                    component: AllOrganizations
                },
                {
                    name: "view-organization",
                    path: "all-organizations/:organizationId",
                    component: ViewOrganization
                },
                {
                    name: "create-organization",
                    path: "create-organization",
                    component: CreateNewOrganization
                },
                {
                    name: "edit-organization",
                    path: "edit-organization/:organizationId",
                    component: EditOrganization
                }
            ]
        },
        {
            path: '/new-deposit',
            redirect: '/new-deposit/deposit-organizations',
            component: () => import('./views/NewDeposit.vue'),
            meta: {
                requiresLogin: true
            },
            children: [
                {
                    name: 'create-deposit-organization',
                    path: 'create-deposit-organization',
                    component: CreateDepositOrganization
                },
                {
                    name: 'deposit-organizations',
                    path: 'deposit-organizations',
                    component: DepositOrganizations
                },
                {
                    name: 'view-deposit-organization',
                    path: 'view-deposit-organization/:organizationId',
                    component: ViewDepositOrganization
                },
                {
                    name: 'create-voucher-definition',
                    path: 'view-deposit-organization/:organizationId/create-voucher-definition',
                    component: CreateVoucherDefinition
                },
                {
                    name: 'edit-voucher-definition',
                    path: 'view-deposit-organization/:organizationId/edit-voucher-definition/:voucherDefinitionId',
                    component: EditVoucherDefinition
                },
                {
                    name: 'create-scheme',
                    path: 'view-deposit-organization/:organizationId/create-scheme',
                    component: CreateScheme
                },
                {
                    name: 'edit-scheme',
                    path: 'view-deposit-organization/:organizationId/edit-scheme/:schemeId',
                    component: EditScheme
                }
            ]
        },
        {
            path: '/deposit',
            redirect: '/deposit/collection-points',
            component: () => import('./views/Deposit.vue'),
            meta: {
                requiresLogin: true
            },
            children: [
                {
                    name: 'collection-points',
                    path: 'collection-points',
                    component: AllCollectionPoints
                },
                {
                    name: 'view-collection-point',
                    path: 'collection-points/:facilityId',
                    component: ViewCollectionPoint
                },
                {
                    name: 'create-collection-point',
                    path: "create-collection-point",
                    component: CreateCollectionPoint
                },
                {
                    name: "view-certificate",
                    path: 'certificates/:depositId',
                    component: ViewCertificate
                },
            ]
        },
        {
            path: '/tracking',
            redirect: '/tracking/organizations',
            component: () => import('./views/Tracking.vue'),
            meta: {
                requiresLogin: true
            },
            children: [
                {
                    name: 'tracking-organizations',
                    path: 'organizations',
                    component: TrackingOrganizations
                },
                {
                    name: 'view-tracking-organization',
                    path: 'organizations/:organizationId',
                    component: ViewTrackingOrganization
                },
                {
                    name: "waste-movement-tracking-organization",
                    path: "organizations/:organizationId/history",
                    component: History
                },
                {
                    name: "facilities",
                    path: "facilities",
                    component: Facilities
                },
                {
                    name: "view-facility",
                    path: "facilities/:facilityId",
                    component: ViewFacility
                },
                {
                    name: "create-facility",
                    path: "create-facility",
                    component: CreateFacility
                },
                {
                    name: "edit-facility",
                    path: "facilities/:facilityId/edit",
                    component: EditFacility
                },
                {
                    name: "tracking-reports",
                    path: "tracking-reports",
                    component: TrackingReports
                },
                {
                    name: "category-manager",
                    path: "category-manager",
                    component: CategoryManager
                },
                {
                    name: "category-manager-delterra",
                    path: "category-manager-delterra",
                    component: CategoryManagerDelterra
                },
                {
                    name: "plastic-credits",
                    path: "plastic-credits",
                    component: PlasticCredits,
                },
            ]
        },
        {
            path: '/cleancity',
            redirect: '/cleancity/all-redeemables',
            component: () => import('./views/Posts.vue'),
            meta: {
                requiresLogin: true
            },
            children: [
                {
                    name: 'litter-pickups',
                    path: 'litter-pickups',
                    component: PickUps
                },
                {
                    name: 'litter-reports',
                    path: 'litter-reports',
                    component: Reports
                },
                {
                    name: 'litter-cleaned',
                    path: 'litter-cleaned',
                    component: Cleaned
                },
                {
                    name: 'view-pickup-post',
                    path: 'view-pickup-post',
                    component: ViewPickUpPost
                },
                {
                    name: 'view-report-post',
                    path: 'view-report-post',
                    component: ViewReportPost
                },
                {
                    name: 'all-redeemables',
                    path: 'all-redeemables',
                    component: Redeemables
                },
                {
                    name: 'create-redeemable',
                    path: 'create-redeemable',
                    component: CreateRedeemable
                },
                {
                    name: 'all-groups',
                    path: 'all-groups',
                    component: Groups
                },
                {
                    name: 'create-group',
                    path: 'create-group',
                    component: CreateGroup
                },
                {
                    name: 'edit-redeemable',
                    path: 'edit-redeemable',
                    component: EditRedeemable
                },
                {
                    name: 'view-redeemable',
                    path: 'view-redeemable',
                    component: ViewRedeemable
                },
                {
                    name: 'view-group',
                    path: 'view-group',
                    component: ViewGroup
                },
                {
                    name: 'all-coupons',
                    path: 'all-coupons',
                    component: Coupons
                },
                {
                    name: 'create-coupon',
                    path: 'create-coupon',
                    component: CreateCoupon
                },
                {
                    name: 'edit-coupon',
                    path: 'edit-coupon',
                    component: EditCoupon
                },
                {
                    name: 'view-coupon',
                    path: 'view-coupon',
                    component: ViewCoupon
                },
                {
                    name: 'view-report-cleaned',
                    path: 'view-report-cleaned',
                    component: ViewCleanedPost
                },
                {
                    name: 'users-emapct',
                    path: 'users-emapct',
                    component: UsersEmpact
                }
            ]
        }
        ]
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(m => m.meta.requiresLogin)) {
        if (router.app.$userService.isLoggedIn()) {
            next();
        } else {
            next("/login");
        }
    } else {
        next();
    }
});


export default router;
