<template>
    <DataTable :data-config="dataConfig"/>
</template>

<script>
import DataTable from "../DataTable";

export default {
        name: "PickUps",
        components: {DataTable},
        created() {
            this.loadPickUpPosts();
        },
        methods: {
            loadPickUpPosts() {
                this.$cleanCityService.getPickUpPosts()
                    .then(litterPickups => {
                            this.dataConfig.items = [];
                            for (let post of litterPickups.litterPickups) {
                                this.dataConfig.rows.push({
                                    id: post.litterPickUpId,
                                    selected: false,
                                    data: {
                                        litterPickUpId: post.litterPickUpId,
                                        points: post.points,
                                        category: post.category,
                                        comment: post.comment
                                    },
                                    callback: () => {
                                        this.$router.push({
                                            name: 'view-pickup-post',
                                            params: {litterPickUpId: post.litterPickUpId}
                                        });
                                    }

                                })
                            }

                        }
                    )
            }
        },
        data() {
            return {
                dataConfig: {
                    columns: [
                        "LitterPickUpId", "Points", "Category", "Comment"
                    ],
                    rows: [],
                    reload: () => {
                        this.loadPickUpPosts();
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
