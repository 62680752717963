<template>
    <div>
        <form novalidate class="md-layout">

          <md-card class="md-layout-item md-size-100 md-small-size-100" v-if="savedAsDraft">
            <md-card-content>
              <div class="md-title text-ms bold" style="margin-top: 1rem;"> <span class="required"> Saved as DRAFT </span> </div>
            </md-card-content>
          </md-card>

            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">Name of organisation <span class="required">*</span> </div>
                    <div class="md-title text-ms">Provide the full legal name of the organisation</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field class="md-field">
                                <md-input class="md-input" placeholder="Your answer" type="text"  name="organizationName" id="organizationName" v-model="verificationValues.organizationName.value.value" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>

            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">Organisation type <span class="required">*</span> (Single selection) </div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <div>
                                <md-radio v-model="verificationValues.organizationType.value.value" :disabled="blockInputs" value="ngo">Non-Governmental Organization (NGO)</md-radio>
                                <md-radio v-model="verificationValues.organizationType.value.value" :disabled="blockInputs" value="nonProfit">Non-Profit Organization</md-radio>
                                <md-radio v-model="verificationValues.organizationType.value.value" :disabled="blockInputs" value="forProfit">For-Profit Organization</md-radio>
                                <div style="display: flex;">
                                    <md-radio style="margin-top: 0; margin-bottom: 0" v-model="orgType" :disabled="blockInputs" :value="form.otherOrganizationType">Other:</md-radio>
                                    <md-field class="md-field" style="padding-top: 0; min-height: unset; height: 20px;" >
                                        <md-input style="height: 21px;" class="md-input" type="text"  name="otherOrganizationType" id="otherOrganizationType"  v-model="form.otherOrganizationType" :disabled="blockInputs"  />
                                    </md-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </md-card-content>
            </md-card>

            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">
                        Year of establishment <span class="required">*</span> </div>
                    <div class="md-title text-ms">Enter the year the organisation was officially established</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field class="md-field">
                                <md-input class="md-input" placeholder="Your answer" type="text"  name="yearOfEstablishment" id="yearOfEstablishment" v-model="verificationValues.yearOfEstablishment.value.value" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>


            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">Organisation description<span class="required">*</span> </div>
                    <div class="md-title text-ms">Please give a short description of the organisation; what are your current activities and focus?</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field class="md-field">
                                <md-input class="md-input" placeholder="Your answer" type="text"  name="orgDescription" id="orgDescription" v-model="verificationValues.organizationDescription.value.value" :disabled="blockInputs"/>
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>


            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">Country and region<span class="required">*</span> </div>
                    <div class="md-title text-ms">Please list the country and region in which the organisation is operating</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field class="md-field">
                                <md-input class="md-input" placeholder="Your answer" type="text"  name="countryAndRegion" id="countryAndRegion" v-model="verificationValues.countryAndRegion.value.value" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>


            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">Address<span class="required">*</span></div>
                    <div class="md-title text-ms">Please provide the address of the organisation's main office/site</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field class="md-field">
                                <md-input class="md-input" placeholder="Your answer" type="text"  name="address" id="address" v-model="verificationValues.address.value.value" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>


            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">Public record of organisation<span class="required">*</span></div>
                    <div class="md-title text-ms"> If your country have an official registry of organisation online, please add a link to the record of your organisation here. Alternatively, if applicable, upload a scanned copy of a physical Business Registration document, marked with your organisation's name</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field class="md-field">
                                <md-input class="md-input" placeholder="Your answer" type="text"  name="address" id="publicRecordOfOrganization" v-model="verificationValues.publicRecordOfOrganization.values[0].value.value" :disabled="blockInputs" />

                            </md-field>
                            <div v-if="verificationValues.publicRecordOfOrganization.values[1]">Uploaded files: <br>
                              <a v-for="(publicRecord, index) in verificationValues.publicRecordOfOrganization.values[1].value.values" :key="index" :src="publicRecord.value" target="_blank" @click="viewFile(publicRecord.value)" style="cursor: pointer;">{{publicRecord.title}}</a>
                            </div>
                        </div>
                    </div>
                </md-card-content>
            </md-card>

            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">Key owner information<span class="required">*</span></div>
                    <div class="md-title text-ms">Please provide basic ownership information of the organisation, meaning name of the founder(s), name of the CEO and name of board chairman (skip the ones that aren't applicable)</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field class="md-field">
                                <md-input class="md-input" placeholder="Your answer" type="text"  name="keyOwnerInfo" id="keyOwnerInfo" v-model="verificationValues.keyOwnerInfo.value.value" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>

            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">Webpage of organisation</div>
                    <div class="md-title text-ms"> If your organisation has a webpage, please provide a link. Alternatively, provide the profile address of the organization on LinkedIn (<span class="link">www.linkedin.com</span>)</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field class="md-field">
                                <md-input class="md-input" placeholder="Your answer" type="text"  name="webpage" id="webpage" v-model="verificationValues.webpage.value.value" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>

            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">Relevant manager LinkedIn profile</div>
                    <div class="md-title text-ms">If the manager of the entity related to a collaboration with Empower has a LinkedIn profile (<span class="link">www.linkedin.com</span>), please provide the link to the profile here</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field class="md-field">
                                <md-input class="md-input" placeholder="Your answer" type="text"  name="managersLinkedin" id="managersLinkedin" v-model="verificationValues.managersLinkedin.value.value" :disabled="blockInputs"/>
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>

            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">Number of employees</div>
                    <div class="md-title text-ms">How many employees and people are involved in the organisation? Please include both, but differentate between employeed and unemployeed involved personnel</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field class="md-field" >
                                <md-input class="md-input" placeholder="Your answer" type="text"  name="numberOfEmployees" id="numberOfEmployees" v-model="verificationValues.numberOfEmployees.value.value" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>

            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">Does you organization have any of the following policies in place?<span class="required" style="margin-right: 5px">*</span>(Multiple selection)</div>
                    <div class="md-title text-ms">Please list all that apply. Upload documentation of all that apply, marked with your organisation's name and title</div>
                </md-card-header>
                <md-card-content>
                    <div style="display: flex; flex-direction: column;">
                        <md-checkbox v-model="policyValues" :disabled="blockInputs" value="Health, Safety & Environment policies">Health, Safety & Environment policies</md-checkbox>
                        <md-checkbox v-model="policyValues" :disabled="blockInputs" value="Policy against Child Labour">Policy against Child Labour</md-checkbox>
                        <md-checkbox v-model="policyValues" :disabled="blockInputs" value="Policy for combatting Corruption">Policy for combatting Corruption</md-checkbox>
                        <div style="display: flex;  align-items: center;" v-if="otherPolicies">
                            <md-checkbox v-model="otherPolicies.value" :disabled="blockInputs" :value="otherPolicies.value">Other:</md-checkbox>
                            <md-field style="padding-top: 3px; min-height: unset" class="md-field">
                                <md-input class="md-input"  type="text"  name="usersOtherPolicy" id="other"  v-model="otherPolicies.value" :disabled="blockInputs" />
                            </md-field>
                        </div>
                        <div v-if="verificationValues.policies.values[1]">Uploaded files: <br>
                          <a v-for="(file, index) in verificationValues.policies.values[1].value.values" :key="index" :src="file.value" target="_blank" @click="viewFile(file.value)" style="cursor: pointer;">{{file.title}}</a>
                        </div>
                    </div>
                </md-card-content>
            </md-card>

            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">Please list any local organisation or community your organisation is cooperating with </div>
                    <div class="md-title text-ms">Write the name of the organisation(s) and include a short description of the cooperation, or write "not applicable"</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field class="md-field">
                                <md-input class="md-input" placeholder="Your answer" type="text"  name="localOrgCooperatorsList" id="localOrgCooperatorsList" v-model="verificationValues.localOrgCooperatorsList.value.value" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>

            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">Please list any international organisations your organisation is cooperating with </div>
                    <div class="md-title text-ms">Write the name of the organisation(s) and include a short description of the cooperation, or write "not applicable</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field class="md-field">
                                <md-input class="md-input" placeholder="Your answer" type="text"  name="internationalOrgCooperatorsList" id="internationalOrgCooperatorsList" v-model="verificationValues.internationalOrgCooperatorsList.value.value" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>

            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">Please list any public bodies your organisation is cooperating with </div>
                    <div class="md-title text-ms">Write the name of the organisation(s) and include a short description of the cooperation, or write "not applicable</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field class="md-field">
                                <md-input class="md-input" placeholder="Your answer" type="text"  name="publicCooperatorsList" id="publicCooperatorsList" v-model="verificationValues.publicCooperatorsList.value.value" :disabled="blockInputs"/>
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>

<!--            <md-card style="padding: 24px 7%;" class="md-layout-item md-size-100 md-small-size-100">-->
<!--                <md-checkbox class="bold" style="margin: 0"  value="" :disabled="blockInputs">I have read and understood <span class="link">Empower's Code of Conduct </span><span class="required"> *</span></md-checkbox>-->
<!--            </md-card>-->

            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title text-ms bold">Please confirm your full name, as the representative filling out and confirming the truthfulness of the content of this form  <span class="required">*</span></div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field class="md-field">
                                <md-input class="md-input" placeholder="Your answer" type="text"  name="confirmation" id="confirmation" v-model="verificationValues.confirmedBy.value.value" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </form>
    </div>
</template>

<script>
    export default {
        name: "ShowVerificationDocuments",
        props: {
            organization: {
              type: Object
            }
        },
        data(){
            return {
                form: {
                    organizationName: null,
                    otherOrganizationType: null,
                    yearOfEstablishment: null,
                    orgDescription: null,
                    countryAndRegion: null,
                    address: null,
                    keyOwnerInfo: null,
                    webpage: null,
                    menagersLinkedin: null,
                    numberOfEmployees: null,
                    localOrgCooperatorsList: null,
                    internationalOrgCooperatorsList: null,
                    publicCooperatorsList: null,
                    confirmation: null,
                    usersOtherPolicy: null,
                },
                orgType: 'ngo',
                files: [],
                fileUrls: [],
                policyFileUrls: [],
                policyFiles: [],
                healthPolicy: null,
                childLabourPolicy: null,
                corruptionPolicy: null,
                otherPolicy: null,
                confirmEmpowersCodeOfConduct: false,
                blockInputs: true
            }
        },
      computed: {
          verificationValues() {
              return this.organization.verificationDocument.checklist;
          },
          policies() {
              let policies = [];
              for (let policy of this.verificationValues.policies.values[0].value.values) {
                policies.push(policy);
              }
              return policies;
          },
          otherPolicies() {
              let otherPolicies = null;
              if (this.policies.includes('Other')) {
                  otherPolicies = this.policies.find(p => p.title == 'Other');
              }
              return otherPolicies;
          },
          policyValues() {
            let values = []
            for (let policy of this.policies) {
              values.push(policy.value)
            }
            return values;
          },
          savedAsDraft() {
            return this.organization.verificationDocument.state == "DRAFT"
          }
      },
      methods: {
          viewFile(url) {
            window.open(url);
          }
      }
    }
</script>

<style scoped lang="scss">
    @import "../../variables";

    .md-field{
        width: 50%;
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    .md-input{
        border-bottom:  0.5px solid #979797;
        &::placeholder{
            color: #828282;
            font-size: 14px;
            line-height: 19px;
        }
    }

    .md-card{
        background: #F6F5F5 !important;
        box-shadow: none;
        margin-top: 32px;
    }

    .md-card-header, .md-card-content{
        padding: 24px 7% 0;
    }

    .md-card-content{
        padding: 0 7% 24px;
    }

    .md-card-header:first-child>.md-title:first-child {
        margin-bottom: 5px;
        font-weight: bold;
    }

    .md-radio {
        display: flex;
    }

    .md-field.md-has-file label{
        left: 0;
    }

    .md-checkbox{
        margin: 16px 16px 0 0 !important;
    }

    .md-checkbox ::v-deep .md-checkbox-container{
        border: 1px solid #9B9B9B;
        background: #FFFFFF;
        border-radius: 0;
    }
    .md-checkbox.md-checked ::v-deep .md-checkbox-container{
        background: #00E33A !important;
        border: 1px solid #00E33A !important;
    }

    .md-checkbox ::v-deep .md-checkbox-container:after{
        content: '';
        color: white;
        -ms-transform: scaleX(-1) rotate(-35deg); /* IE 9 */
        -webkit-transform: scaleX(-1) rotate(-35deg); /* Chrome, Safari, Opera */
        transform: scaleX(-1) rotate(-35deg);
        top: 0;
        left: 7px;
    }

    .md-card-header {
        div {
            font-size: 14px !important;
            line-height: 19px !important;
        }
    }

    .md-radio ::v-deep .md-radio-container{
        border: 1px solid #00E33A !important;
        background-color: white;
    }
    .md-radio.md-checked ::v-deep .md-radio-container:after{
        background: #00E33A !important;
    }

</style>
