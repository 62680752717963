<template>
    <div class="tracking__certificates">
        <header v-if="showHeader">
            <h1 class="text-ls bold">Plastic credits</h1>
            <nav>
                <span class="bold label" :class="{'is-active': showIssued}" @click="displayIssued">Issue</span>
                <span class="bold label"  :class="{'is-active': showBuy}"  @click="displayBuy">Buy</span>
                <span class="bold label"  :class="{'is-active': showHistory}" @click="displayHistory">History</span>
            </nav>
        </header>
        <PlasticCreditsIssue @toggleHeader="toggleHeader" v-if="showIssued"/>
        <PlasticCreditsBuy @toggleHeader="toggleHeader" v-if="showBuy"/>
        <PlasticCreditsHistory v-if="showHistory"/>
    </div>
</template>

<script>
import PlasticCreditsIssue from "./PlasticCreditsIssue";
import PlasticCreditsBuy from "./PlasticCreditsBuy";
import PlasticCreditsHistory from "./PlasticCreditsHistory";

export default {
        name: "PlasticCredits",
        components: {
            PlasticCreditsBuy,
            PlasticCreditsHistory,
            PlasticCreditsIssue
        },
        async mounted() {
            if (!this.$store.state.plasticCreditStore.plasticCreditTransactionHistory.length) {
                await this.$store.dispatch('getPlasticCreditTransactionHistory')
            }
            if (!this.$store.state.plasticCreditStore.plasticCreditBalance.length) {
                await this.$store.dispatch('getPlasticCreditBalance')
            }
            // when admin comes to plastic credits, set org as admin org for admin purchase plastic credits
            // should be clear with operations team they need to be with empower organization before buying
            if (!this.$store.state.organizationStore.adminOrganization) {
                await this.$store.dispatch('getAdminOrganization')
            }
            if (!this.$store.state.organizationStore.organizations.length) {
                await this.$store.dispatch('getAllOrganizations')
            }
        },
        data(){
            return{
                showIssued: true,
                showBuy: false,
                showHistory: false,
                showHeader: true,
            }
        },
        methods: {
            displayIssued(){
                this.showIssued = true;
                this.showBuy = false;
                this.showHistory = false;
            },
            displayBuy(){
                this.showIssued = false;
                this.showBuy = true;
                this.showHistory = false;
            },
            displayHistory(){
                this.showIssued = false;
                this.showBuy = false;
                this.showHistory = true;
            },
            toggleHeader(){
                this.showHeader = !this.showHeader
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/variables";

    .tracking__certificates{
        padding: 24px;
        &--filters{
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .filters{
            display: flex;
            width: 80%;
            justify-content: flex-end;
            p{
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                text-decoration-line: underline;
                color: #1B1B1B;
                margin: 8px 25px 0 0;
                cursor: pointer;
            }
        }
    }

    header {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        h1{
            color: #1B1B1B;
            margin-right: 40px;
        }
        nav{
            span{
                margin-right: 25px;
                cursor: pointer;
            }
        }
    }

    .is-active{
        color: #00E33A;
        border-bottom: 1px solid #00E33A;
        padding-bottom: 8px;
    }

</style>
