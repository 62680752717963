import {apiService} from "@/main";

export default class {
    wasteEndpoint = process.env.VUE_APP_WASTE_API_BASE_URL + '/api/v1/waste';

    async createReport(facilities, from, to) {
        return await apiService.call("POST", `${this.wasteEndpoint}/transactions/reports`, {facilities, from, to})
    }


}
