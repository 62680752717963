<template>
    <ContentView :content-config="contentConfig"/>
</template>

<script>
import ContentView from "../ContentView";

export default {
        name: "ViewGroup",
        components: {ContentView},
        created() {
            this.loadGroup();
        },
        methods: {
          loadGroup() {
              this.$cleanCityService.getGroup(this.$route.params.groupId).then((group) => {
                    this.contentConfig.sections[0].data.groupId.value = group.groupId;
                    this.contentConfig.sections[0].data.name.value = group.name;
                    this.contentConfig.sections[0].data.code.value = group.groupCode;
                    this.contentConfig.sections[0].data.organizationId.value = group.organizationId;
                    this.contentConfig.sections[0].data.userIds.values = [group.userIds];
                }
            );
          }
        },
        data() {
            return {
                contentConfig: {
                    sections: [
                        {
                            type: "info",
                            title: "Group Info",
                            data: {
                                "groupId": {
                                    title: "Group ID",
                                    value: ""
                                },
                                "name": {
                                    title: "Name",
                                    value: ""
                                },
                                "code": {
                                    title: "Group Code",
                                    value: ""
                                },
                                "organizationId": {
                                    title: "Organization ID",
                                    value: ""
                                },
                                "userIds": {
                                    title: "User Ids",
                                    type: "multiple",
                                    typeDefinition: "text",
                                    value: ""
                                }                              
                            }
                        }
                    ]
                }
            }
        }
    }
</script>

<style scoped>

</style>
