<template>
    <div>
        <section class="buy-plastic-credits"  v-if="!showSingleCertificateView">
            <h3>Balance: {{totalPlasticCreditBalance}}</h3>
            <div class="buy-plastic-credits__filters flex-align-center">
                <div>
                    <img alt="Reload icon" @click="reloadPlasticCreditsInventory" src="@/assets/reload-grey.svg">
                    <input type="search"
                           id="search-owner"
                           name="search owner"
                           placeholder="Search by Organization or Facility"
                           v-model="searchOwner"
                           @keyup.enter="searchOwner = true"
                    />
                    <label class="label bold pointer" for="search-owner">Search</label>
                </div>
            </div>
            <md-divider class="divider"/>
            <div>
                <article v-if="plasticCreditBalance.length > 0 && filteredPlasticCreditsData.length > 0">
                    <md-table class="md-table" :class="{  'is-selected' : selected.length > 0 }" v-model="filteredPlasticCreditsData" md-card @md-selected="onSelect">
                        <md-table-row class="md-table-row" slot="md-table-row" slot-scope="{ item }" :md-disabled="false" md-auto-select>
                            <md-table-cell class="table-text label" md-label="Plastic credits" md-sort-by="organization">{{item.balance}}</md-table-cell>
                            <md-table-cell class="table-text label" md-label="Organization" md-sort-by="organization">{{item.orgName}}</md-table-cell>
                            <md-table-cell class="table-text label" md-label="Facility">{{ item.currentOwnerName }}</md-table-cell>
                            <md-table-cell class="table-text label" md-label="Country">
                                <span v-if="item.country">{{item.country}}</span>
                                <span v-else>--</span>
                            </md-table-cell>
                            <md-table-cell class="table-text label" md-label="Material Type" md-sort-by="amount">
                                <div class="waste-definition">
                                    <p class="label" @mouseover="displayMassBalanceDefinitionDetails(item)" @mouseleave="showItem = false; displayMassBalanceDefinitionItemDetails = null" >{{ item.massBalanceDefinition.plasticType }}</p>
                                    <ul class="waste-definition__list" v-if="displayMassBalanceDefinitionItemDetails === item">
                                        <p class="label bold waste-definition__title">{{item.massBalanceDefinition.plasticType}}</p>
                                        <li v-for="(description, index) in transform(displayMassBalanceDefinitionItemDetails.massBalanceDefinition)" :key="index" class="waste-definition__list-item" >
                                            <p class="label no-wrap" v-if="description !== item.massBalanceDefinition['material origin']">· {{description}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </md-table-cell>
                            <md-table-cell class="table-text label" md-label="Material origin" >
                                <span v-if="item.massBalanceDefinition.materialOrigin || item.massBalanceDefinition['material origin']">{{ item.massBalanceDefinition.materialOrigin || item.massBalanceDefinition[ 'material origin' ]  }}</span>
                                <span v-else>--</span>
                            </md-table-cell>
                            <md-table-cell class="table-text blue-bold-text" @click.native="viewCertificate(item)" md-label="">View</md-table-cell>
                            <md-table-cell class="table-text blue-bold-text" md-label="" @click.native="buyCertificate(item)">Buy</md-table-cell>
                        </md-table-row>
                    </md-table>
                    <md-dialog :md-active.sync="showChangeOwnerDialog">
                        <div class="dialog">
                            <div class="btn-close pointer">
                                <img src="@/assets/close-icon.svg" alt="Close btn" @click="cancelPurchase"/>
                            </div>
                            <section v-if="showChangeOwnerDialogStepOne">
                                <div>
                                    <div class="md-layout md-gutter">
                                        <div class="md-layout-item">
                                            <section>
                                                <p class="label bold">Buy plastic credit from:</p>
                                                <p class="label label--org-name" >{{selected.orgName}}, {{selected.currentOwnerName}}</p>
                                            </section>
                                            <section class="dialog__amount">
                                                <p class="label">
                                                    <span class="bold">Amount</span><span class="required">*</span>: (Available {{selected.balance}})
                                                </p>
                                                <md-field>
                                                    <md-input v-model.number="amount" :max="selected.balance" placeholder="0" type="number"></md-input>
                                                </md-field>
                                            </section>
                                            <section class="dialog__total-price">
                                                <p class="label bold">Total price<span class="required">*</span>:</p>
                                                <p class="label bold reset-padding-top">{{totalPurchasePrice}}</p>
                                                <md-field>
                                                    <md-input v-model.number="pricePerCredit" max="100000000000" type="number" placeholder="Price per credit"></md-input>
                                                </md-field>
                                                <md-field>
                                                    <md-select v-model="currency" name="currency" id="currency">
                                                        <md-option value="USD">USD</md-option>
                                                    </md-select>
                                                </md-field>
                                            </section>
                                            <section class="dialog__total-price">
                                                <p class="label bold">Total pool price<span class="required">*</span>:</p>
                                                <p class="label bold reset-padding-top">{{ totalPoolPrice }}</p>
                                                <p class="label dialog__price dialog__pool-price">Pool price per credit: 0.5</p>
                                                <p class="label dialog__price">USD(locked)</p>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                                <div class="md-dialog-actions">
                                    <md-button class="md-primary button-confirm"
                                               :disabled="!letUserGoToSecondStep"
                                               :class="{'is-disabled' : !letUserGoToSecondStep}"
                                               @click="showNext">
                                        NEXT</md-button>
                                    <md-button class=" button-cancel"
                                               @click="cancelPurchase">
                                        CANCEL</md-button>
                                </div>
                            </section>
                            <section v-if="showChangeOwnerDialogStepTwo">
                                <div>
                                    <div class="md-layout md-gutter">
                                        <div class="md-layout-item">
                                            <h2 class="text-m bold">Overview</h2>
                                            <article>
                                                <ul class="dialog-overview__list">
                                                    <li class="label dialog-overview__list-item">
                                                        <p>Buy from:</p>
                                                        <p class="dialog-overview__list-item--change-alignment">{{selected.orgName}}, {{selected.currentOwnerName}} </p>
                                                    </li>

                                                    <li class="label dialog-overview__list-item">
                                                        <p>Country:</p>
                                                        <p>{{selected.country}}</p>
                                                    </li>

                                                    <li class="label dialog-overview__list-item">
                                                        <p>Material Type:</p>
                                                        <div class="dialog-overview__material-type">
                                                            <p class="label bold">{{ selected.massBalanceDefinition.plasticType }}</p>
                                                            <ul class="dialog-overview__material-type--list">
                                                                <li v-for="(description, index) in transform(selected.massBalanceDefinition)" :key="index" class="dialog-overview__material-type--list-item" style="display: block; margin-right: 0;" >
                                                                    <p class="label" style="white-space: nowrap;">· {{description}}</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li class="label dialog-overview__list-item">
                                                        <p>Amount:</p>
                                                        <p>{{amount}}</p>
                                                    </li>

                                                    <li class="label dialog-overview__list-item">
                                                        <p>Price per credit:</p>
                                                        <p>{{pricePerCredit}} USD</p>
                                                    </li>

                                                    <li class="label dialog-overview__list-item">
                                                        <p>Total price: </p>
                                                        <p>{{totalPurchasePrice}} USD</p>
                                                    </li>

                                                    <li class="label dialog-overview__list-item">
                                                        <p>Pool price per credit: </p>
                                                        <p>0.5 USD</p>
                                                    </li>

                                                    <li class="label dialog-overview__list-item">
                                                        <p>Total pool price: </p>
                                                        <p>{{totalPoolPrice}} USD</p>
                                                    </li>
                                                </ul>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                                <div  class="md-dialog-actions">
                                    <md-button class="md-primary button-confirm" @click="confirm">CONFIRM</md-button>
                                    <md-button class=" button-cancel" @click="cancelPurchase">CANCEL</md-button>
                                </div>
                            </section>
                            <section v-if="showChangeOwnerDialogStepThree">
                                <div>
                                    <div class="md-layout md-gutter">
                                        <div class="md-layout-item">
                                            <h2 class="text-m bold" style="text-align: center">Purchase Successful</h2>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </md-dialog>
                </article>
                <p v-if="searchOwner && filteredPlasticCreditsData.length === 0" class="search-owner__not-found">The owner name is not found. Please try again.</p>
            </div>
        </section>

        <section class="buy-plastic-credits__single-view" v-if="showSingleCertificateView">
            <div>
                <button class="btn-back text-xs pointer bold" @click="closeSingleCertificateView">Back</button>
            </div>
            <PlasticCreditsBuySingleView
                    v-if="showSingleCertificateView"
                    :certificate="singleCertificateToDisplay"
                    :orgId="viewCertificateOrgId"
                    :facilityId="viewCertificateFacilityId"
            />
        </section>
    </div>
</template>

<script>
import { Decimal } from 'decimal.js';
import PlasticCreditsBuySingleView from "./PlasticCreditsBuySingleView";
import transform from "@/mixins/transformWasteDefinitionForDisplay";

export default {
        name: "PlasticCreditsBuy",
        components: {
            PlasticCreditsBuySingleView
        },
        mixins: [transform],
        data(){
            return{
                selected: [],
                searchOwner: '',
                showChangeOwnerDialog: false,
                amount: null,
                pricePerCredit: null,
                poolPrice: 0.5,
                currency: 'USD',
                facility: null,
                deliverTo: "",
                disableButton: false,
                selectedOrganization: null,
                selectedFacility: null,
                showSingleCertificateView: false,
                singleCertificateToDisplay: null,
                showChangeOwnerDialogStepOne: true,
                showChangeOwnerDialogStepTwo: false,
                showChangeOwnerDialogStepThree: false,
                buyPlasticCredits: null,
                viewCertificateOrgId: null,
                viewCertificateFacilityId: null,
                showItem: false,
                displayMassBalanceDefinitionItemDetails: null
            }
        },
        async mounted() {
            if (!this.$store.state.plasticCreditStore.plasticCreditBalance.length || this.$store.state.plasticCreditStore.reloadPlasticCreditBalance) {
                await this.$store.dispatch('getPlasticCreditBalance')
            }
            if (!this.$store.state.organizationStore.adminOrganization) {
                await this.$store.dispatch('getAdminOrganization')
            }
        },
        computed: {
            plasticCreditBalance() {
                return this.$store.state.plasticCreditStore.plasticCreditBalance.filter(certificate => certificate.balance > 0);
            },
            filteredPlasticCreditsData(){
                if (this.searchOwner) {
                    return this.applyFilters();
                } else return this.plasticCreditBalance
            },
            adminOrganization() {
                return this.$store.state.organizationStore.adminOrganization;
            },
            organizations() {
                return this.$store.getters.organizationsWithRegisteredDate;
            },
            decimalAmount() {
              if (!this.amount) {
                return new Decimal(0);
              } else {
                return new Decimal(this.amount);
              }
            },
            decimalPricePerCredit() {
              if (!this.pricePerCredit) {
                return new Decimal(0);
              } else {
                return new Decimal(this.pricePerCredit);
              }
            },
            decimalPoolPrice() {
              if (!this.poolPrice) {
                return new Decimal(0);
              } else {
                return new Decimal(this.poolPrice);
              }
            },
            totalPurchasePrice() {
              return this.decimalAmount.times(this.decimalPricePerCredit).toDP(2);
            },
            totalPoolPrice() {
              return this.decimalAmount.times(this.decimalPoolPrice).toDP(2);
            },
            totalPlasticCreditBalance(){
                return this.plasticCreditBalance.reduce((acc, item) => acc + item.balance, 0);
            },
            letUserGoToSecondStep(){
                return (this.amount > 0.1 && this.amount <= this.selected.balance) && this.pricePerCredit > 0 && this.pricePerCredit < 1000000000000;
            }
        },
        methods: {
            async reloadPlasticCreditsInventory() {
                await this.$store.dispatch('getPlasticCreditBalance');
            },
            applyFilters(){
                let result = [...this.plasticCreditBalance];
                if (this.searchOwner && (typeof this.searchOwner === "string")) {
                    let userSearch = this.searchOwner.toLowerCase()
                    result = result.filter(plasticCredit => {
                        if(plasticCredit.currentOwnerName && plasticCredit.orgName){
                            return (plasticCredit.currentOwnerName.toLowerCase().includes(userSearch) || plasticCredit.orgName.toLowerCase().includes(userSearch)) ;
                        } else if (plasticCredit.currentOwnerName){
                            return (plasticCredit.currentOwnerName.toLowerCase().includes(userSearch));
                        } else if (plasticCredit.orgName){
                            plasticCredit.orgName.toLowerCase().includes(userSearch)
                        }
                    })
                } else this.searchOwner = ''
               return result
            },

            onSelect (items) {
                this.selected = items
            },
            getAlternateLabel (count) {
                let plural = ''
                if (count > 1) {
                    plural = 's'
                }
                return `${count} item${plural} selected`
            },
            buyCertificate(selected){
                this.selected = selected;
                this.selectedOrganization = selected.organization;
                this.selectedFacility = selected.facility;
                this.showChangeOwnerDialog = true
            },
            showNext(){
                this.showChangeOwnerDialogStepOne = false;
                this.showChangeOwnerDialogStepTwo = true;
            },
            async viewCertificate(certificate){
                let foundBalance = this.$store.getters.getSingleViewBalanceByMassBalanceDefinition(certificate);
                if (!foundBalance) {
                    let payload = {ownerId: certificate.currentOwnerId, massBalanceDefinition: certificate.massBalanceDefinition}
                    await this.$store.dispatch('getPlasticCreditBalanceForOneWasteTypeOfOneFacility', payload)
                    foundBalance = this.$store.getters.getSingleViewBalanceByMassBalanceDefinition(certificate)
                }
                this.viewCertificateOrgId = certificate.orgId;
                this.viewCertificateFacilityId = certificate.currentOwnerId;
                this.showSingleCertificateView = true;
                this.singleCertificateToDisplay = foundBalance;
                this.$emit('toggleHeader');
            },
            cancelPurchase(){
                this.amount = null;
                this.pricePerCredit = null;
                this.showChangeOwnerDialogStepOne = true;
                this.showChangeOwnerDialogStepTwo = false;
                this.showChangeOwnerDialogStepThree = false;
                this.showChangeOwnerDialog = false;
            },
            confirm(){
                let buyPlasticCredits = {
                    "from":{
                        "ownerCategory": "FACILITY",
                        "ownerId": this.selected.currentOwnerId,
                        "ownerName": this.selected.currentOwnerName,
                        "ownerType": "INTERNAL",
                    },
                    "to":{
                        "ownerCategory": "ORGANIZATION",
                        "ownerId": this.adminOrganization.organizationId,
                        "ownerName": this.adminOrganization.orgName,
                        "ownerType": "INTERNAL",
                    },
                    "amount": this.amount,
                    "massBalanceDefinition": this.selected.massBalanceDefinition,
                    "payment":{
                        "amount": this.totalPurchasePrice,
                        "pricePerKg": this.pricePerCredit,
                        "currency": this.currency,
                    },
                    "poolPrice":{
                        "amount": this.totalPoolPrice,
                        "pricePerKg": this.poolPrice,
                        "currency": "USD"
                    }
                }
                this.$plasticCreditService.adminBuyPlasticCredits(buyPlasticCredits.from,
                                                                  buyPlasticCredits.to,
                                                                  buyPlasticCredits.amount,
                                                                  buyPlasticCredits.massBalanceDefinition,
                                                                  buyPlasticCredits.payment,
                                                                  buyPlasticCredits.poolPrice)
                    .then( () => {
                        this.showChangeOwnerDialogStepTwo = false;
                        this.showChangeOwnerDialogStepThree = true;
                        this.$store.dispatch('getPlasticCreditBalance')
                        this.$store.dispatch('getPlasticCreditTransactionHistory')
                        this.$store.commit('resetBalances')
                    })
                    .catch( () => {
                        alert("Something went wrong!")
                        this.showChangeOwnerDialog = false;
                    })
            },
            closeSingleCertificateView() {
                this.$emit('toggleHeader')
                this.showSingleCertificateView = false
            },
            displayMassBalanceDefinitionDetails(item){
                this.displayMassBalanceDefinitionItemDetails = item
                this.showItem = true
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/variables";

    h2{
        color: #1B1B1B
    }

    ul {
        padding: 16px 0;
        display: flex;
        li{
            margin-right: 24px;
            .circle{
                background: #00E33A;
                height: 8px;
                width: 8px;
                border-radius: 100%;
                margin-right: 8px;
            }
        }
    }

    .buy-plastic-credits__filters{
        justify-content: space-between;
        padding: 16px 0 24px;
        input{
            height: 32px;
            min-width: 206px;
            border: 1px solid rgba(155, 155, 155, 0.5);
            font-size: 12px;
            line-height: 16px;
            margin: 0 16px 0 28px;
            padding: 8px 0 8px 8px;
            &::placeholder{
                font-size: 12px;
                line-height: 16px;
                color: #9B9B9B;
            }
        }
        label{
            text-decoration-line: underline;
        }
    }

    .md-table.is-selected ::v-deep .md-table-toolbar {
        background-color: #00E33A !important;
    }

    .md-table ::v-deep .md-theme-default.md-checked .md-checkbox-container{
        background-color: #00E33A;
        border-color:  #00E33A;
    }

    .md-card {
        box-shadow: none ;
    }

    .buy-plastic-credits__label{
        text-decoration: none !important;
        margin-right: 17px;
    }

    .dialog{
        padding: 64px 120px;
    }

    .btn-close{
        position: absolute;
        top: 8px;
        right: 8px;
        background-color: #1B1B1B;
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 8px;
            height: 8px;
        }
    }

    .button-select{
        background: transparent !important;
        border-radius: 0 !important;
        font-weight: bold;
        font-size: 12px !important;
        line-height: 16px !important;
        text-decoration-line: underline;
        color: #1B1B1B;
        min-width: 50px;
    }

    .md-toolbar-section-start{
        flex: unset;
        margin-right: 25px;
    }

    .md-field ::v-deep .md-theme-default.md-has-value {
        color: pink !important;
    }

    .md-field.md-theme-default:before {
        background-color: #1B1B1B !important;
    }

    .md-select ::v-deep .md-input.md-input.md-select-value {
        font-size: 12px;
        line-height: 16px;
        color: #1B1B1B;
    }

    .md-dialog .md-gutter{
        display: flex;
        flex-direction: column;
        min-width: 400px;
    }

    .md-dialog .md-select {
        outline: 0.5px solid #1B1B1B;
        padding-left: 8px;
    }

    .md-dialog .md-input, .md-field.md-focused.md-theme-default.md-has-placeholder .md-input {
        outline: 0.5px solid #1B1B1B;
        padding-left: 8px;
        font-size: 12px !important;
        line-height: 16px !important;
        &::placeholder{
            font-size: 12px !important;
            line-height: 16px !important;
            color: #9B9B9B;
        }
    }

    .md-dialog .md-select ::v-deep .md-input.md-input.md-select-value {
        &::placeholder{
            font-size: 12px !important;
            line-height: 16px !important;
            color: #9B9B9B;
        }
    }

    .md-dialog .md-field{
        max-height: unset !important;
        min-height: unset !important;
        margin: 0;
        padding-top: 8px;
    }

    .md-dialog .md-field ::v-deep .md-input, .md-dialog .md-field ::v-deep .md-select{
        height: 24px;
    }

    .dialog__amount, .dialog__total-price{
        .label{
            padding: 16px 0 8px;
        }
    }

    .md-dialog-actions{
        padding: 0;
        justify-content: unset;
        margin-top: 8px;
    }

    .button-confirm, .button-cancel {
        padding: 12px 24px;
        max-width: 105px;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
    }

    .button-confirm{
        background: #1B1B1B;
        color: #00E33A !important;
    }

    .button-cancel{
        background-color: white;
        border: 1px solid #1B1B1B;
    }

    .divider {
        background-color: $placeholder-grey;
    }

    .search-owner__not-found{
        font-size: 16px;
        line-height: 22px;
        color: #1B1B1B;
        padding-top: 24px;
    }

    .btn-back{
        border: 0.5px solid #1B1B1B;
        width: 96px;
        height: 24px;
        text-align: center;
        margin-bottom: 24px;
        color: #1B1B1B;
        display: inline-block;
        background-color: transparent;
    }

    .is-disabled{
        opacity: 0.5;
    }

    .waste-definition{
        padding: 10px 0;
        position: relative;
    }

    .waste-definition__title{
        white-space: nowrap;
    }

    .waste-definition__list{
        list-style: none;
        display: flex;
        flex-direction: column;
        position: absolute;
        padding: 12px 24px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        background-color: white;
        z-index: 10;
        top: 20px;
        left: 20px;
    }

    .waste-definition__list-item{
        margin-right: 0;
    }

    .label--org-name {
        padding-top: 8px;
    }

    .reset-padding-top.bold{
        padding-top: 0;
    }

    .label.dialog__price{
        border: 0.5px solid #1B1B1B;
        padding: 4px 8px;
    }

    .dialog__pool-price{
        margin-bottom: 8px;
    }

    .dialog-overview__list{
        list-style: none;
        max-width: 400px;
        display: flex;
        flex-direction: column;
    }

    .dialog-overview__list-item{
        display: flex;
        justify-content: space-between;
        font-weight: bold;
    }

    .dialog-overview__list-item--change-alignment{
        max-width: 50%;
        text-align: right;
    }

    .dialog-overview__material-type .label{
        text-align: right;
    }

    .dialog-overview__material-type--list{
        flex-direction: column;
        display: flex;
        padding: 0;
        min-width: 60%;
    }

    .dialog-overview__material-type--list-item{
        display: block;
        margin-right: 0;
        .label{
            width: 100%;
            font-weight: normal;
        }
    }

    .md-table ::v-deep .md-table-head-label{
        font-weight: normal !important;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        color: #9b9b9b;
        align-items: center;
    }

</style>

