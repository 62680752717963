<template>
    <div>
        <div class="input__heading">{{title}}</div>
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "ContentInputSection",
        props: {
            title: String
        }
    }
</script>

<style lang="scss">
  .input__heading {
    margin-bottom: .5rem;
  }
</style>

