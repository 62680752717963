<template>
    <ContentView :content-config="contentConfig"/>
</template>

<script>
import ContentView from "../ContentView";

export default {
        name: "ViewOrganization",
        components: {ContentView},
        methods: {
            loadOrganization() {
                return this.$organizationsService.getOrganization(this.$route.params.organizationId)
                    .then(organization => {
                        this.organization = organization;
                        this.contentConfig.sections[0].data.organizationName.value = organization.organizationName;
                        this.contentConfig.sections[0].data.organizationNumber.value = organization.organizationNumber;
                        this.contentConfig.sections[0].data.aboutOrganization.value = organization.aboutOrganization;
                        this.contentConfig.sections[0].data.organizationPicture.values = [organization.organizationPicture];
                        this.contentConfig.sections[0].data.customDashboardUrl.value = organization.customDashboardUrl;
                        this.contentConfig.sections[0].data.customDashboardEmbedUrl.value = organization.customDashboardEmbedUrl;
                        this.contentConfig.sections[0].data.signingPublicKey.value = organization.signingPublicKey;
                        this.contentConfig.sections[0].data.encryptionPublicKey.value = organization.encryptionPublicKey;
                    })
            },
            loadUsersInOrganization() {
                this.$organizationsService.getUsersInOrganization(this.$route.params.organizationId)
                    .then(usersInSelectedOrganization => {
                        this.contentConfig.sections[1].rows = [];

                        for (const user of usersInSelectedOrganization) {
                            this.contentConfig.sections[1].rows.push({
                                id: user.userId,
                                data: [`${user.firstName} ${user.lastName}`, user.email, user.countryCode, user.phoneNumber],
                                callback: () => {
                                  this.$router.push({
                                    name: 'view-user',
                                    params: {userId: user.userId}
                                  })
                                }
                            })
                        }
                    })
            }
        },
        created() {
            this.loadOrganization();
            this.loadUsersInOrganization();
        },
        data() {
            return {
                organization: undefined,
                contentConfig: {
                    buttons: [
                        {
                            title: "Edit",
                            isDark: true,
                            callback: () => {
                                this.$router.push({
                                    name: 'edit-organization',
                                    params: {organizationId: this.$route.params.organizationId}
                                });
                            }
                        },
                        {
                            title: "Generate key",
                            isDark: true,
                            callback: () => {
                                this.$organizationsService.generateKey(this.$route.params.organizationId).then(() => {
                                    this.loadOrganization();
                                });
                                this.$store.dispatch('getAllOrganizations');
                            }
                        }
                    ],
                    sections: [
                        {
                            type: "info",
                            title: "Organization Info",
                            data: {
                                "organizationName": {
                                    title: "Organization Name",
                                    value: ""
                                },
                                "organizationNumber": {
                                    title: "Organization Number",
                                    value: ""
                                },
                                "aboutOrganization": {
                                    title: "About",
                                    value: ""
                                },
                                "customDashboardUrl": {
                                    "title": "Full Datastudio Dashboard URL",
                                    value: "",
                                },
                                "customDashboardEmbedUrl": {
                                    title: "Embedded Datastudio Dashboard URL",
                                    value: "",
                                },
                                "signingPublicKey": {
                                    title: "Signing public key",
                                    value: "",
                                },
                                "encryptionPublicKey": {
                                    title: "Encryption public key",
                                    value: "",
                                },
                                "organizationPicture": {
                                    title: "Pictures",
                                    type: "multiple",
                                    typeDefinition: "img",
                                    values: []
                                }
                            }
                        },
                        {
                            type: "table",
                            title: "Users",
                            columns: ["Name", "Email", "Country Code", "Phone Number"],
                            rows: []
                        }
                    ]
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
