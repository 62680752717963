<template>
    <div>
        <Modal :close-callback="closeCallback" v-if="showOrganizationModal">
            <OrganizationSearch :select-callback="organizationSelected"/>
        </Modal>
        <ContentInput :content-config="contentConfig"/>
    </div>
</template>

<script>
import ContentInput from "../ContentInput";
import OrganizationSearch from "../OrganizationSearch";
import Modal from "../Modal";

export default {
        name: "CreateGroup",
        components: {OrganizationSearch, ContentInput, Modal},
        methods: {
            closeCallback() {
                this.showOrganizationModal = false;
            },
            organizationSelected(organization) {
                this.showOrganizationModal = false;
                this.contentConfig.sections.groupInfo.inputs.organizationId.value = organization.organizationId
            }
        },
        created() {
            if (this.$route.query.organizationId) {
                this.contentConfig.sections.groupInfo.inputs.organizationId.value = this.$route.query.organizationId;
                this.contentConfig.sections.groupInfo.inputs.organizationId.disabled = true;
            }
        },
        data() {
            return {
                 showOrganizationModal: false,
                 contentConfig: {
                    title: "New Group",
                    buttons: [
                        {
                            title: "Create Group",
                            isDark: true,
                            callback: () => {
                                const groupInfoInputs = this.contentConfig.sections.groupInfo.inputs;
                                let groupClone =  {
                                        name: groupInfoInputs.name.value,
                                        code: groupInfoInputs.code.value,
                                        organizationId: groupInfoInputs.organizationId.value,
                                }
                                this.$cleanCityService.createGroup(groupClone).then(() => this.$router.push({name: 'all-groups'}))
                            }
                        }
                    ],
                    sections: {
                        "groupInfo": {
                            title: "Group Info:",
                            type: "info",
                            inputs: {                           
                                "name": {
                                    title: "Name",
                                    type: "text",
                                    value: undefined
                                },
                                "code": {
                                    title: "Code",
                                    type: "text",
                                    value: undefined
                                },
                                "organizationId": {
                                    title: "OrganizationID",
                                    type: "text",
                                    value: undefined,
                                    onFocus: () => {
                                        this.showOrganizationModal = true
                                    }
                                },
                            }
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
