<template>
    <div>
        <div class="my-certificates__wrapper">
            <div class="board__details" >
                <div class="board__item" v-if="certificateClone.organizationName">
                    <p class="board__item--header">Organization</p>
                    <div class="board__item--content">
                        <p class="board__item--text">{{ certificateClone.organizationName }}</p>
                    </div>
                </div>
                <div class="board__item" v-if="certificateClone.facilityName">
                    <p class="board__item--header">Facility</p>
                    <div class="board__item--content">
                        <p class="board__item--text">{{ certificateClone.facilityName }}</p>
                    </div>
                </div>
                <div class="board__item">
                    <p class="board__item--header" style="margin-top: 0;">Material type</p>
                    <div class="board__item--content">
                        <div>
                            <p class="bold label">{{ certificateClone.massBalanceDefinition.plasticType }}</p>
                            <ul>
                                <li v-for="(description, index) in transform(certificateClone.massBalanceDefinition)" :key="index" >
                                    <p class="label">· {{description}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="board__item">
                    <p class="board__item--header">Quantity (KG)</p>
                    <div class="board__item--content">
                        <p class="board__item--text">{{ certificateClone.amount }} kg</p>
                    </div>
                </div>
                 <div class="board__item">
                   <p class="board__item--header">Payment</p>
                   <div class="board__item--content">
                      <p class="board__item--text" v-if="certificateClone.payment">
                     {{
                     certificateClone.payment.amount +
                     " " +
                    certificateClone.payment.currency
                    }}
                      </p>
                   <p v-else>No payment available</p>
                  </div>
                </div>
                <div class="board__item extra-padding">
                    <div class="board__section">
                        <img src="../../assets/files.svg" alt="Files"/>
                        <p class="board__item--text bold" style="padding: 0">Files:</p>
                    </div>

                    <div class="board__section--files">
                        <div class="board__item--align">
                            <p class="board__item--header">Registration document scan</p>
                            <label for="registrationDocuments" class="btn--confirm btn__text" style="margin-left: 10px;" v-if="letUserEditCertificate">Upload</label>
                            <input type="file" id="registrationDocuments" style="display:none;" @change="addRegistrationDocuments"
                                   multiple>
                        </div>
                        <div>
                            <div v-for="(file, key) in registrationDocuments"
                                 :key="key"
                                 :name="file.title"
                                 class="data__wrapper"
                            >
                                <span class="btn--confirm btn__text" @click="openFile(file.url)">View</span>
                                <p class="board__item--text"> File0{{ key+1 }}: </p>
                                <p class="file-name board__item--text">{{ file.title }}</p>
                                <span v-if="letUserEditCertificate" class="btn--delete btn__text" @click="deleteFile(file.url)">Delete</span>
                            </div>
                        </div>
                    </div>

                    <div class="board__section--files">
                        <div class="board__item--align">
                            <p class="board__item--header">Clean up images</p>
                            <label for="cleanupImages" class="btn--confirm btn__text" style="margin-left: 10px;" v-if="letUserEditCertificate">Upload</label>
                            <input type="file" id="cleanupImages" style="display:none;" @change="addCleanupImages"
                                   multiple>
                        </div>
                        <div>
                            <div v-for="(file, key) in cleanupImages"
                                 v-bind:key="key"
                                 v-bind:name="file.title"
                                 class="data__wrapper"
                            >
                                <span class="btn--confirm btn__text" @click="openFile(file.url)">View</span>
                                <p class="board__item--text"> File0{{ key+1 }}: </p>
                                <p class="file-name board__item--text">{{ file.title }}</p>
                                <span v-if="letUserEditCertificate" class="btn--delete btn__text" @click="deleteFile(file.url)">Delete</span>
                            </div>
                        </div>
                    </div>

                    <div class="board__section--files">
                        <div class="board__item--align">
                            <p class="board__item--header">Other files</p>
                            <label for="otherFiles" class="btn--confirm btn__text" style="margin-left: 10px;" v-if="letUserEditCertificate">Upload</label>
                            <input type="file" id="otherFiles" style="display:none;" @change="addOtherFiles"
                                   multiple>
                        </div>
                        <div>
                            <div v-for="(file, key) in otherFiles"
                                 v-bind:key="key"
                                 v-bind:name="file.title"
                                 @click="openFile(file.url)"
                                 class="data__wrapper"
                            >
                                <span class="btn--confirm btn__text" @click="openFile(file.url)">View</span>
                                <p class="board__item--text"> File0{{ key+1 }}: </p>
                                <p class="file-name board__item--text">{{ file.title }}</p>
                                <span v-if="letUserEditCertificate" class="btn--delete btn__text" @click="deleteFile(file.url)">Delete</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="board__details" >
                <div class="board__item board__item--map" >
                    <p class="board__item--text bold">
                        <img src="../../assets/location.svg" alt="Location image"/>
                        Receive Location <span class="btn--delete">*</span>:
                    </p>
                    <div class="map__container">
                        <GoogleMap
                                :getGeoPosition="false"
                                :lng="longitude"
                                :lat="latitude"
                                @location-changed="updateLocation">
                        </GoogleMap>
                    </div>
                </div>
                <div class="board__item" :class="{ 'block-events': !letUserEditCertificate }">
                    <div class="board__item--datetime">
                        <p class="board__item--text bold">
                            <img src="../../assets/calendar.svg" alt="Calendar"/>
                            Receive Date and Time <span class="btn--delete">*</span>:
                        </p>
                    </div>
                    <div class="input-wrapper-delivery">
                        <div v-if="selectedDate">
                            <Datetime
                                      v-model="selectedDate"
                                      input-class="my-class"
                                      type="datetime"
                                      format="yyyy MMMM dd HH:mm"
                                      input-style="height: 30px;
                                               background: #F6F5F5 !important;
                                               border: none;
                                               width: 75%;
                                               letter-spacing: 0.275px;
                                               color: #1B1B1B !important;
                                               margin-bottom: 10px;
                                               font-size: 12px;
                                               line-height: 16px;
                                               margin-top: 5px;
                                               text-align: left;"
                            ></Datetime>
                        </div>
                        <p v-else class="datepicker--unavailable">Time and date not available</p>
                    </div>
                </div>
                <div class="board__item" style="border-bottom: none">
                    <div class="board__item--other">
                        <p class="board__item--text bold">
                            <img src="../../assets/writing.svg" alt="Notebook"/>
                            Other Information:
                        </p>
                    </div>
                    <textarea placeholder="No content" v-model="certificateClone.message"></textarea>
                </div>
            </div>
        </div>
        <div class="decision" v-if="certificateClone.certificationState === 'SIGNED_BY_COLLECTOR'">
            <form class="decision__form">
                <p class="decision__radio">
                    <input type="radio" v-model="decision" value="Approve" id="approve" name="approve">
                    <label class="decision__radio--approve bold" for="approve">Approve</label>
                </p>
                <p class="decision__radio">
                    <input type="radio" class="rejection"  id="reject" v-model="decision" value="Reject" name="reject"  >
                    <label class="decision__radio--reject rejection bold" for="reject">Reject</label>
                </p>
            </form>
            <textarea class="decision__rejection" placeholder="Please write the rejection reason" v-if="decision === 'Reject' " v-model="rejectionMessage"/>
            <button class="button__confirm" :disabled="decision === null" @click.prevent="confirm">Confirm</button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import GoogleMap from "../GoogleMap";
import {Datetime} from "vue-datetime";
import transform from "@/mixins/transformWasteDefinitionForDisplay";

export default {
        name: "TrackingDetails",
        components: {GoogleMap, Datetime},
        mixins: [transform],
        props: {
            certificate: {
                type: Object
            },
            isEditable: Boolean
        },
        data() {
            return {
                certificateClone: {
                    ...this.certificate,
                },
                selectedDate: null,
                longitude: null,
                latitude: null,
                letUserEditCertificate: this.isEditable,
                rejectionMessage: null,
                decision: null
            }
        },
        mounted() {
            if(this.certificateClone && this.certificateClone.manualDateTime) {
                this.selectedDate = new Date(this.certificateClone.manualDateTime).toISOString()
            } else {
                this.selectedDate = null
            }
            this.longitude = this.certificateClone.location.coordinates[0];
            this.latitude = this.certificateClone.location.coordinates[1];
        },
        updated() {
            if (this.selectedDate || this.longitude || this.latitude || this.certificateClone.message ){
                this.sendUpdatedData();
            }
        },
        computed: {
            registrationDocuments() {
                return this.certificateClone.files.filter(file => {
                    return file.fileType === 'REGISTRATION_DOCUMENT';
                });
            },
            certificates() {
              return this.$store.state.plasticCreditStore.depositsPendingEmpowerSignature
            },
            cleanupImages() {
                return this.certificateClone.files.filter(file => {
                    return file.fileType === 'CLEAN_UP_IMAGE';
                });
            },
            otherFiles() {
                return this.certificateClone.files.filter(file => {
                    return file.fileType === 'OTHER_FILES';
                });
            },
            facilities() {
                return this.$store.state.facilities;
            }
        },
        methods: {
            async updateAndBack() {
                const payload = {
                    depositId: this.certificateClone.depositId,
                    files: [...this.certificateClone.files],
                    longitude: this.longitude,
                    latitude: this.latitude,
                    receiveDateTime: this.selectedDate,
                    message: this.certificateClone.message || ''
                }
                await this.$store.dispatch("updateDeposit", payload);
                await this.$emit('closeView');
                this.$router.push('/dashboard/empower-tracking/certificates/')
            },
            sendUpdatedData(){
                if (this.letUserEditCertificate) {
                    const payload = {
                        depositId: this.certificateClone.depositId,
                        files: [...this.certificateClone.files],
                        longitude: this.longitude,
                        latitude: this.latitude,
                        receiveDateTime: this.selectedDate,
                        message: this.certificateClone.message || ''
                    }
                    this.$emit('sendDetails', payload);
                }
            },
            async goBack() {
                await this.$emit('closeView');
                this.$router.push('/dashboard/empower-tracking/certificates/')
            },
            formatDate(date) {
                return moment.unix(date / 1000).format('Do-MM-YYYY HH:mm')
            },
            async addRegistrationDocuments(e) {
                const files = e.target.files || e.dataTransfer.files;
                if (files.length > 0) {
                    await this.uploadFiles(files, "REGISTRATION_DOCUMENT");
                }
                this.sendUpdatedData();
            },
            async addCleanupImages(e) {
                const files = e.target.files || e.dataTransfer.files;
                if (files.length > 0) {
                    await this.uploadFiles(files, "CLEAN_UP_IMAGE");
                }
                this.sendUpdatedData();
            },
            async addOtherFiles(e) {
                const files = e.target.files || e.dataTransfer.files;
                if (files.length > 0) {
                    await this.uploadFiles(files, "OTHER_FILES");
                }
                this.sendUpdatedData();
            },
            async uploadFiles(files, type) {
                this.$loadingService.addLoadingItem()
                try {
                    const uploadedFiles = await this.$sharedFileUploadService.uploadFiles(files);
                    let file = {};
                    let strippedDownFiles = [];
                    for (let uploadedFile of uploadedFiles) {
                        file.fileType = type;
                        file.url = uploadedFile.ssl_url;
                        file.title = uploadedFile.name;
                        strippedDownFiles.push(file);
                        file = {};
                    }
                    this.certificateClone.files.push(...uploadedFiles);
                    this.sendUpdatedData();
                } catch (err) {
                    alert("Something went wrong, please check your network connection and try again.")
                    this.$rollbar.error(err)
                } finally {
                    this.$loadingService.removeLoadingItem();
                }
            },
            deleteFile(url) {
                this.certificateClone.files = this.certificateClone.files.filter(file => {
                    return file.url !== url;
                })
            },
            openFile(url) {
                window.open(url);
            },
            updateLocation(newLocation) {
                if (this.letUserEditCertificate){
                    this.longitude = newLocation.lng;
                    this.latitude = newLocation.lat;
                }
            },

            async confirm(){
                if (this.decision === 'Reject') {
                    await this.rejectCertificate();
                } else if (this.decision === 'Approve') {
                    await this.confirmCertificate();
                }
                this.$emit('closeView', true);
            },

            async confirmCertificate() {
               const selectedDepositsId = []
                selectedDepositsId.push(this.certificateClone.depositId)
                await this.$plasticCreditService.signDepositsAndIssuePlasticCredits(selectedDepositsId).then(() => {
                    this.$emit("certStatusUpdated", {status: 'SIGNED_BY_EMPOWER', ids: selectedDepositsId});
                });
            },
            async rejectCertificate() {
              const selectedDepositsId = []
              selectedDepositsId.push(this.certificateClone.depositId)
              await this.$plasticCreditService.rejectDeposits(this.rejectionMessage, selectedDepositsId).then(() => {
                  this.$emit("certStatusUpdated", {status: 'REJECTED', ids: selectedDepositsId, reason: this.rejectionMessage});
              });
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "src/variables";

    .my-certificates__wrapper{
        display: flex;
        justify-content: space-between;
        background: #F6F5F5;
    }
    .board__details{
        width: calc(50% - 2.5rem);
        height: 100%;
        padding: 24px 48px;
    }
    .board__item{
        border-bottom: 0.5px solid #979797;
    }
    .board__item--header{
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        color: #1B1B1B;
        margin-top: 16px;
    }
    .board__item--text{
        font-size: 12px;
        line-height: 16px;
        color: #1B1B1B;
    }
    .board__section{
        padding: 14px 0 0;
        display: flex;
        img{
            margin-right: 15px;
        }
    }
    .btn--confirm{
        color: #00C5FF;
        display: flex;
        align-items: center;
    }
    .btn--delete{
        color: #FF6336;
    }
    .btn__text{
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        text-decoration-line: underline;
        cursor: pointer;
    }
    .board__item--content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0 16px;
    }
    .board__item--align{
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;
    }
    .data__wrapper{
        display: flex;
        margin-top: 10px;
        span, p {
            margin-right: 15px;
            padding: 0 !important;
        }
        :hover {
            cursor: pointer;
        }
    }
    .board__section--files {
        margin-bottom: 15px;
    }
    .text--plain{
        font-size: 10px;
        line-height: 14px;
    }
    .btn-save{
        width: 120px;
        height: 30px;
        background: #1B1B1B;
        border: 0.5px solid #1B1B1B;
        color: white;
        font-weight: bold;
        margin: 30px 0 20px;
    }
    .btn--align{
        padding-top: 15px;
        padding-left: 10px;
    }

    .extra-padding{
        padding-bottom: 10px;
    }
    .width--change{
        width: 70%;
        margin-top: 15px;
    }
    .flex{
        display: flex;
    }
    .correct__text {
        font-size: 12px;
        line-height: 16px;
        color: #9B9B9B;
    }
    .board__item--map{
        padding-bottom: 15px;
        img{
            margin-bottom: 8px;
            margin-right: 10px;
        }
        .map__container{
            width: 100%;
            height: 120px;
            margin: 8px 0;
        }
    }
    .board__item--datetime {
        padding-bottom: 2px;
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        img{
            margin-bottom: 5px;
            margin-right: 10px;
        }
        .correct__text{
            padding-left: 15px;
            margin-bottom: 15px;
        }
    }
    .board__item--other {
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        img{
            margin-bottom: 5px;
            margin-right: 10px;
        }
    }
    textarea{
        width: 100%;
        height: 120px;
        padding: 20px 15px;
        border: 0.5px solid #4A4A4A;
        &::placeholder{
            color: #9B9B9B;
        }
    }
    .block-events{
        pointer-events: none !important;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #00E33A;
        border-radius: 100%;
        background: #fff;
    }

    .rejection:before{
        border: 1px solid #B00020 !important;
    }
    .rejection:after{
        background-color: #B00020 !important;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 12px;
        height: 12px;
        background: #00E33A;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    .decision__rejection{
        width: 320px;
        height: 64px;
        margin-top: 8px;
        padding: 8px;
        color: #1B1B1B;
        font-size: 16px;
    }

    .button__confirm {
      width: 120px;
      height: 32px;
      background: #1b1b1b;
      font-weight: bold;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff !important;
      border: none;
      margin-top: 16px;
    }

    .textarea__rejection {
      width: 320px;
      height: 64px;
      margin-bottom: 16px;
      margin-top: 8px;
    }

    ul {
        list-style-type: none;
    }

    .decision__form {
        margin-top: 24px;
    }

    .decision__radio {
        padding: 4px 0;
        &--approve {
            color: $new-green;
        }
        &--reject {
            color: $error-red;
        }
    }

    .datepicker--unavailable{
        height: 30px;
        background: #F6F5F5;
        border: none;
        width: 75%;
        letter-spacing: 0.275px;
        color: #1B1B1B;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 16px;
        margin-top: 5px;
    }

</style>
