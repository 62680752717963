<template>
    <span>{{ address }}</span>
</template>

<script>
import { getGoogleMaps } from '@empower-platform/google-maps-shared';

export default {
        name: "ReverseGeoCoding",
        props: {
            lat: {
                type: Number
            },
            lng: {
                type: Number
            }
        },
        data() {
            return {
                address: null
            }
        },
        async mounted() {
            if(this.lat && this.lng){
                const location = {lat: Number(this.lat), lng: Number(this.lng)};
                const google = await getGoogleMaps();
                await this.reverseGeocoding(google, location);
            }
        },
        methods: {
            async reverseGeocoding(google, location) {
                const geocoder = new google.maps.Geocoder();
                await geocoder.geocode({location: location}, (results, status) => {
                    if (status === 'OK') {
                        if (results[0]) {
                            this.address= results[0].formatted_address;
                        }
                    } else {
                        this.alert('Could not find the address for the given coordinates');
                        return ''
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>
