import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import UserService from "./services/UserService";
import FacilitiesService from "./services/FacilitiesService";
import EventBusService from "./services/EventBusService";
import LoadingService from "./services/LoadingService";
import ApiService from "./services/ApiService";
import OrganizationsService from "./services/OrganizationsService";
import CleanCityService from "./services/CleanCityService";
import ReportService from "./services/ReportService";
import WasteService from "./services/WasteService";
import DepositService from "./services/DepositService";
import PlasticCreditService from "./services/PlasticCreditService";
import RollbarSetup from "@empower-platform/general-frontend-shared-rollbar-setup"
import SharedFileUploadService from "@empower-platform/general-frontend-shared-upload-files";

import VueMaterial from 'vue-material'
import {Datetime} from 'vue-datetime'
import Vuesax from 'vuesax'

import transform from '@/mixins/transformWasteDefinitionForDisplay'
import {DepositAdminClient} from "@empower-platform/general-frontend-shared-empowerchain";

export default {
    name: 'transform',
    mixins: [transform]
}

import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import 'vue-datetime/dist/vue-datetime.css'
import 'vuesax/dist/vuesax.css'

Vue.use(VueMaterial)
Vue.use(Datetime)
Vue.use(Vuesax)

RollbarSetup.setupRollbar(Vue)

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;


Vue.config.productionTip = false;

export const userService          = new UserService(EventBusService);
export const loadingService       = new LoadingService();
export const apiService           = new ApiService();
export const organizationsService = new OrganizationsService();
export const facilityService      = new FacilitiesService();
export const depositService       = new DepositService();
export const plasticCreditService = new PlasticCreditService();
export const cleanCityService     = new CleanCityService();
export const reportService        = new ReportService();
export const wasteService         = new WasteService();
export const depositAdminClient   = new DepositAdminClient(process.env.VUE_APP_EMPOWER_ENVIRONMENT)


userService.initUserService().finally(() => {
  apiService.setToken();

  Vue.prototype.$userService             = userService;
  Vue.prototype.$loadingService          = loadingService;
  Vue.prototype.$apiService              = apiService;
  Vue.prototype.$organizationsService    = organizationsService;
  Vue.prototype.$facilityService         = facilityService;
  Vue.prototype.$depositService          = depositService;
  Vue.prototype.$plasticCreditService    = plasticCreditService;
  Vue.prototype.$cleanCityService        = cleanCityService;
  Vue.prototype.$reportService           = reportService;
  Vue.prototype.$wasteService            = wasteService;
  Vue.prototype.$eventBus                = EventBusService;
  Vue.prototype.$sharedFileUploadService = new SharedFileUploadService()
  Vue.prototype.$depositAdminClient      = depositAdminClient;

  const vueInstance = new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
  apiService.setVue(vueInstance);
}).catch(err => {
  alert(err);
})
