import {apiService} from "@/main";

export default class {
    wasteEndpoint = process.env.VUE_APP_WASTE_API_BASE_URL + '/api/v1/waste';

    getMassBalanceDefinitionName(massBalanceDefinition) {
        if (!massBalanceDefinition) return;
        let balanceName = ""
        for (let [key, value] of Object.entries(massBalanceDefinition)) {
            balanceName = balanceName.concat(`${key.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); })}: ${value} `)
        }
        return balanceName
    }

    async getTransactionHistory(organizationId) {
        return await apiService.call("GET", `${this.wasteEndpoint}/transactions?organizationId=${organizationId}`)
    }
}
