<template>
    <nav v-if="navigation && navigation.items">
        <div v-for="item of navigation.items"
             class="menu-item"
             v-bind:class="{ 'is-active': $route.name === item.routeName }"
             v-bind:key="item.name">
            <router-link :to="{ name: item.routeName }">
                {{item.title}}
                <div class="number bold" v-if="item.name === 'plastic-credits'">
                    <div class="flex-center">{{unsignedCertificatesNumber}}</div>
                </div>
            </router-link>
        </div>
    </nav>
</template>

<script>
    export default {

        name: "LeftNavigation",
        props: {
            navigation: Object
        },
        async mounted() {
            if (!this.$store.state.plasticCreditStore.depositsPendingEmpowerSignature.length || this.$store.state.plasticCreditStore.reloadPlasticCreditIssue) {
                await this.$store.dispatch('getDepositsPendingEmpowerSignature')
            }
        },
        computed: {
            unsignedCertificatesNumber() {
                let certificates = this.$store.state.plasticCreditStore.depositsPendingEmpowerSignature;
                return certificates.length
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/variables";

    nav {
        min-width: 244px;
        background-color: #fff;
        padding: 15px 0;
        margin-right: 20px;

        .menu-item {
            padding: 0 25px;
            height: 32px;
            border-bottom: 0.5px solid #9B9B9B !important;
        }

        .menu-item.is-section.is-active::after {
            content: "❯";
            float: right;
            line-height: 32px;
            padding-right: 5px;
            color: #00E33A !important;
        }

        .menu-item.is-active::after {
            content: "❯";
            float: right;
            line-height: 32px;
            padding-right: 5px;
            color: #00E33A;
        }
        .router-link-active{
            color: #00E33A !important;
        }

        a {
            text-decoration: none;
            line-height: 32px;
            font-size: 12px;
        }

        a, a:visited, a:focus, a:hover, a:active{
            font-weight: normal;
            font-size: 12px;
            line-height: 34px;
            color: #1B1B1B !important;
            text-decoration: none;
        }

        a:hover{
            color: #00E33A !important;
        }
        a.router-link-exact-active.router-link-active{
            color: #00E33A !important;
        }

        .number{
            display: inline-block;
            background-color: $new-green;
            color: white;
            border-radius: 100%;
            margin-left: 8px;
            line-height: 20px;
            padding: 0 4px;
            min-width: 20px;
        }
    }
</style>
