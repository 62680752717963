<template>
    <div>
        <ContentView :content-config="contentConfig"/>
        <ContentView v-for="(config, index) of productConfigs"
                     :content-config="config"
                     v-bind:key="`product-content-view-${index}`"/>
        <ContentView v-for="(config, index) of certificationConfigs"
                     :content-config="config"
                     v-bind:key="`certification-content-view-${index}`"/>
        <ContentView v-for="(config, index) of fileConfigs"
                     :content-config="config"
                     v-bind:key="`file-content-view-${index}`"/>

      <div style="margin-top: 50px; margin-left: 50px;">
        <input type="radio" id="approved" value="APPROVED" v-model="verificationStatus">
        <label for="approved"> Approve </label>
        <input type="radio" id="rejected" value="REJECTED" v-model="verificationStatus">
        <label for="rejected">Reject</label>
        <input type="radio" id="waiting" value="WAITING" v-model="verificationStatus">
        <label for="waiting">Waiting List</label>

        <textarea v-model="verificationReason" style="display:flex; flex-direction:column; margin: 20px;" placeholder="Enter your verification reason"></textarea>

      </div>
    </div>
</template>

<script>
import ContentView from "../ContentView";

export default {
        name: "ViewTrackingOrganization",
        components: {ContentView},
        created() {
            this.loadOrganization();
            this.loadOrganizationsFacilities();
        },
        methods: {
            loadOrganizationsFacilities() {
                return this.$facilityService.getOrganizationsFacilities(this.$route.params.organizationId)
                    .then(facilities => {
                            this.contentConfig.sections[1].rows = [];

                            for (let facility of facilities) {
                                this.contentConfig.sections[1].rows.push({
                                    id: facility.facilityId,
                                    data: {
                                        facilityId: facility.facilityId,
                                        facilityName: facility.facilityName,
                                        country: facility.country,
                                    },
                                    callback: () => {
                                        this.$router.push({
                                            name: 'view-facility',
                                            params: {facilityId: facility.facilityId}
                                        })
                                    }
                                })
                            }
                        }
                    )
            },
            loadOrganization() {
                return this.$organizationsService.getOrganization(this.$route.params.organizationId)
                    .then(organization => {
                            this.organization = organization;
                            this.verificationStatus = organization.verificationStatus
                            this.verificationReason = organization.verificationReason
                            this.contentConfig.sections[0].data.orgName.value = organization.organizationName;
                            this.contentConfig.sections[0].data.orgNumber.value = organization.organizationNumber;
                        }
                    )
            },
        },
        data() {
            return {
                organization: undefined,
                verificationStatus: null,
                verificationReason: null,
                contentConfig: {
                    buttons: [
                        {
                            title: "Edit",
                            isDark: true,
                            callback: () => {
                                alert("This functionality has been removed!");
                            }
                        },
                        {
                            title: "See Waste Movements",
                            isDark: true,
                            callback: () => {
                                this.$router.push({
                                    name: 'waste-movement-tracking-organization',
                                    params: {organizationId: this.$route.params.organizationId}
                                });
                            }
                        },
                    ],
                    sections: [
                        {
                            type: "info",
                            title: "Organization Info",
                            data: {
                                "orgName": {
                                    title: "Organization Name",
                                    value: ""
                                },
                                "orgNumber": {
                                    title: "Organization Number",
                                    value: ""
                                }
                            }
                        },
                        {
                            type: "table",
                            title: "Facilites",
                            columns: ["FacilityId", "Facility Name", "Country"],
                            rows: []
                        }
                    ]
                },
                productConfigs: [],
                certificationConfigs: [],
                fileConfigs: []
            }
        }
    }
</script>

<style scoped>

</style>
