<template>
    <div>
        <Modal :close-callback="closeCallback" v-if="showOrganizationModal">
            <OrganizationSearch :select-callback="organizationSelected"/>
        </Modal>
        <ContentInput :content-config="contentConfig"/>
    </div>

</template>

<script>
import ContentInput from "../ContentInput";
import OrganizationSearch from "../OrganizationSearch";
import Modal from "../Modal";

export default {
        name: "CreateFacility",
        components: {OrganizationSearch, ContentInput, Modal},
        methods: {
            closeCallback() {
                this.showOrganizationModal = false;
            },
            organizationSelected(organization) {
                this.showOrganizationModal = false;
                this.contentConfig.sections.facilityInfo.inputs.organizationId.value = organization.organizationId
            }
        },
        created() {
            if (this.$route.query.organizationId) {
                this.contentConfig.sections.facilityInfo.inputs.organizationId.value = this.$route.query.organizationId;
                this.contentConfig.sections.facilityInfo.inputs.organizationId.disabled = true;
            }
        },
        data() {
            return {
                showOrganizationModal: false,
                contentConfig: {
                    title: "New Facility",
                    buttons: [
                        {
                            title: "Create Facility",
                            isDark: true,
                            callback: () => {
                                const facilityInfoInputs = this.contentConfig.sections.facilityInfo.inputs;

                                const facilityCreationObj = {
                                    organizationId: facilityInfoInputs.organizationId.value,
                                    facilityName: facilityInfoInputs.facilityName.value,
                                    street: facilityInfoInputs.street.value,
                                    zip: facilityInfoInputs.zip.value,
                                    city: facilityInfoInputs.city.value,
                                    country: facilityInfoInputs.country.value,
                                    countryCode: facilityInfoInputs.countryCode.value,
                                    phoneNumber: facilityInfoInputs.phoneNumber.value,
                                    email: facilityInfoInputs.email.value,
                                    contactPerson: facilityInfoInputs.contactPerson.value,
                                    lat: parseFloat(facilityInfoInputs.latitude.value),
                                    lng: parseFloat(facilityInfoInputs.longitude.value)
                                };

                                this.$facilityService.createFacility(facilityCreationObj)
                                    .then(response => {
                                        this.$router.push({
                                            name: 'view-facility',
                                            params: {facilityId: response.facilityId}
                                        })
                                    })
                            }
                        }
                    ],
                    sections: {
                        "facilityInfo": {
                            title: "Facility Info:",
                            type: "info",
                            inputs: {
                                "organizationId": {
                                    title: "OrganizationID",
                                    type: "text",
                                    value: undefined,
                                    onFocus: () => {
                                        this.showOrganizationModal = true
                                    }
                                },
                                "facilityName": {
                                    title: "Facility Name",
                                    type: "text",
                                    value: undefined
                                },
                                "street": {
                                    title: "Street",
                                    type: "text",
                                    value: undefined
                                },
                                "city": {
                                    title: "City",
                                    type: "text",
                                    value: undefined
                                },
                                "zip": {
                                    title: "Zip",
                                    type: "text",
                                    value: undefined
                                },
                                "country": {
                                    title: "Country",
                                    type: "text",
                                    value: undefined
                                },
                                "countryCode": {
                                    title: "Country Code",
                                    type: "text",
                                    value: undefined
                                },
                                "phoneNumber": {
                                    title: "Phone Number",
                                    type: "text",
                                    value: undefined
                                },
                                "email": {
                                    title: "Email",
                                    type: "text",
                                    value: undefined
                                },
                                "contactPerson": {
                                    title: "Contact Person",
                                    type: "text",
                                    value: undefined
                                },
                                "latitude": {
                                    title: "Latitude",
                                    type: "text",
                                    value: undefined
                                },
                                "longitude": {
                                    title: "Longitude",
                                    type: "text",
                                    value: undefined
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
