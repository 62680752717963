<template>
    <DataTable :data-config="dataConfig"/>
</template>

<script>
import DataTable from "../DataTable";

export default {
        name: "Facilities",
        components: {DataTable},
        async created() {
            if (typeof this.$route.params.organizationId != "undefined") {
                this.loadOrganizationFacilities();
            } else {
                if (!this.$store.state.facilityStore.facilities.length) {
                    await this.$store.dispatch('getAllFacilities')
                }
                let facilities = this.$store.state.facilityStore.facilities
                this.handleFacilities(facilities)
            }

        },
        methods: {
            loadOrganizationFacilities() {
                this.$facilityService.getOrganizationsFacilities(this.$route.params.organizationId)
                    .then(this.handleFacilities);
            },
            reloadAllFacilities() {
                this.$facilityService.getAllFacilities()
                    .then(this.handleFacilities);
            },
            handleFacilities(facilities) {
                this.dataConfig.items = [];
                for (let facility of facilities) {
                    this.dataConfig.rows.push({
                        id: facility.facilityId,
                        selected: false,
                        data: {
                            facilityId: facility.facilityId,
                            facilityName: facility.facilityName,
                            country: facility.country,
                        },
                        callback: () => {
                            this.$router.push({
                                name: 'view-facility',
                                params: {facilityId: facility.facilityId}
                            })
                        }
                    })
                }


            }
        },
        data() {
            return {
                dataConfig: {
                    columns: [
                        "FacilityId", "Facility Name", "Country"
                    ],
                    rows: [],
                    reload: () => {
                        if (typeof this.$route.params.organizationId != "undefined") {
                            this.loadOrganizationFacilities();
                        } else {
                            this.reloadAllFacilities();
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
