<template>
    <ContentInput :content-config="contentConfig"/>
</template>

<script>
import ContentInput from "../ContentInput";

export default {
        name: "EditFacility",
        components: {ContentInput},
        created() {
            this.$facilityService.getFacility(this.$route.params.facilityId).then(facility => {
                    this.contentConfig.sections.facilityInfo.inputs.facilityName.value = facility.facilityName;
                    this.contentConfig.sections.facilityInfo.inputs.street.value = facility.street;
                    this.contentConfig.sections.facilityInfo.inputs.zip.value = facility.zip;
                    this.contentConfig.sections.facilityInfo.inputs.city.value = facility.city;
                    this.contentConfig.sections.facilityInfo.inputs.country.value = facility.country;
                    this.contentConfig.sections.facilityInfo.inputs.countryCode.value = facility.countryCode;
                    this.contentConfig.sections.facilityInfo.inputs.phoneNumber.value = facility.phoneNumber;
                    this.contentConfig.sections.facilityInfo.inputs.email.value = facility.email;
                    this.contentConfig.sections.facilityInfo.inputs.contactPerson.value = facility.contactPerson;
                    this.contentConfig.sections.facilityInfo.inputs.latitude.value = facility.latitude;
                    this.contentConfig.sections.facilityInfo.inputs.longitude.value = facility.longitude;
                    this.contentConfig.sections.facilityInfo.inputs.pictures.values = facility.pictures;
                }
            );
        },
        data() {
            return {
                contentConfig: {
                    title: "Edit Facility",
                    buttons: [
                        {
                            title: "Save",
                            isDark: true,
                            callback: () => {
                                const facilityInfoInputs = this.contentConfig.sections.facilityInfo.inputs;

                                const facilityUpdateObj = {
                                    facilityName: facilityInfoInputs.facilityName.value,
                                    street: facilityInfoInputs.street.value,
                                    zip: facilityInfoInputs.zip.value,
                                    city: facilityInfoInputs.city.value,
                                    country: facilityInfoInputs.country.value,
                                    countryCode: facilityInfoInputs.countryCode.value,
                                    phoneNumber: facilityInfoInputs.phoneNumber.value,
                                    email: facilityInfoInputs.email.value,
                                    contactPerson: facilityInfoInputs.contactPerson.value,
                                    pictures: facilityInfoInputs.pictures.values,
                                    latitude: parseFloat(facilityInfoInputs.latitude.value),
                                    longitude: parseFloat(facilityInfoInputs.longitude.value)
                                };

                                this.$facilityService.editFacility(this.$route.params.facilityId, facilityUpdateObj)
                                    .then(() => {
                                        this.$router.push({
                                            name: 'view-facility',
                                            params: {facilityId: this.$route.params.facilityId}
                                        })
                                    })
                            }
                        }
                    ],
                    sections: {
                        "facilityInfo": {
                            title: "Facility Info:",
                            type: "info",
                            inputs: {
                                "facilityName": {
                                    title: "Facility Name",
                                    type: "text",
                                    value: undefined
                                },
                                "street": {
                                    title: "Street",
                                    type: "text",
                                    value: undefined
                                },
                                "city": {
                                    title: "City",
                                    type: "text",
                                    value: undefined
                                },
                                "zip": {
                                    title: "Zip",
                                    type: "text",
                                    value: undefined
                                },
                                "country": {
                                    title: "Country",
                                    type: "text",
                                    value: undefined
                                },
                                "countryCode": {
                                    title: "Country Code",
                                    type: "text",
                                    value: undefined
                                },
                                "phoneNumber": {
                                    title: "Phone Number",
                                    type: "text",
                                    value: undefined
                                },
                                "email": {
                                    title: "Email",
                                    type: "text",
                                    value: undefined
                                },
                                "contactPerson": {
                                    title: "Contact Person",
                                    type: "text",
                                    value: undefined
                                },
                                "latitude": {
                                    title: "Latitude",
                                    type: "text",
                                    value: undefined
                                },
                                "longitude": {
                                    title: "Longitude",
                                    type: "text",
                                    value: undefined
                                },
                                "pictures": {
                                    title: "Pictures",
                                    type: "multiple",
                                    typeDefinition: "pictureupload",
                                    values: [],
                                    upload: (files) => {
                                        event.target.disabled = true
                                        this.$sharedFileUploadService.uploadFiles(files).then(files => {
                                        this.$facilityService.uploadFacilityPicture(this.$route.params.facilityId, files[0].ssl_url);
                                        })
                                    },
                                    delete: (url) => {
                                        event.target.disabled = true
                                        this.$facilityService.deleteFacilityPicture(this.$route.params.facilityId, url);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
