<template>
    <ContentView :content-config="contentConfig"/>
</template>

<script>

import ContentView from "../ContentView";

export default {
        name: "ViewCollectionPoint",
        components: {ContentView},
        async created() {
            await this.loadCollectionPoint()
        },
        methods: {
            async loadCollectionPoint() {
              try {
                const collectionPoint = await this.$depositService.getCollectionPoint(this.$route.params.facilityId);
                this.contentConfig.sections[0].data.facilityId.value = collectionPoint.facilityId;
                this.contentConfig.sections[0].data.facilityName.value = collectionPoint.facilityName;
                this.contentConfig.sections[0].data.organizationId.value = collectionPoint.organizationId;
                this.contentConfig.sections[0].data.organizationName.value = collectionPoint.organizationName;
              } catch (e) {
                alert('Error getting collection point');
              }
            }
        },
        data() {
            return {
                contentConfig: {
                    buttons: [],
                    sections: [
                        {
                            type: "info",
                            title: "Collection Point Info",
                            data: {
                                "facilityId": {
                                    title: "Facility ID",
                                    value: ""
                                },
                                "facilityName": {
                                    title: "Facility Name",
                                    value: ""
                                },
                                "organizationId": {
                                    title: "Organization ID",
                                    value: ""
                                },
                                "organizationName": {
                                    title: "Organization Name",
                                    value: ""
                                }
                            }
                        }
                    ]
                }
            }
        }
    }
</script>

<style scoped>

</style>
