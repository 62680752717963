<template>
  <ContentView :content-config="contentConfig"/>
</template>

<script>

import ContentView from "../ContentView";

export default {
  name: "ViewCollectionPoint",
  components: {ContentView},
  created() {
    this.loadCertificates()
  },
  methods: {
    async loadCertificates() {
      const {certificates} = await this.$plasticCreditService.getDepositsPendingEmpowerSignature();
      const certificate = await certificates.find(c => {
        return c.depositId === this.$route.params.depositId;
      });
      this.setData(certificate);
    },

    setData(c) {
      this.contentConfig.sections[0].data.facilityId.value = c.facilityId;
      this.contentConfig.sections[0].data.depositId.value = c.depositId;
      this.contentConfig.sections[0].data.date.value = new Date(c.manualDateTime).toLocaleDateString();
      this.contentConfig.sections[0].data.status.value = c.certificationState;
      this.contentConfig.buttons[0].title = "Back";
      this.contentConfig.buttons[0].hidden = false;
    }
  },
  data() {
    return {
      contentConfig: {
        buttons: [
          {
            title: "Back",
            isDark: true,
            callback: () => {
              this.$router.push({name: "all-certificates"});
            }
          },
          {
            title: "Sign Certificate",
            isDark: true,
            callback: () => {
              this.$plasticCreditService.signDepositsAndIssuePlasticCredits([this.$route.params.depositId]).then(() => {
                alert("Certificate Signed")
                this.loadCertificates()
              })
            }
          },
        ],
        sections: [
          {
            type: "info",
            title: "Certification Info",
            data: {
              "facilityId": {
                title: "Facility ID",
                value: ""
              },
              "depositId": {
                title: "Deposit ID",
                value: ""
              },
              "date": {
                title: "Date",
                value: ""
              },
              "status": {
                title: "Status",
                value: ""
              },
            }
          }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>
