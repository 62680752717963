import {apiService} from "@/main";

export default class {
    balances;
    certificationEndpoint = process.env.VUE_APP_PLASTIC_CREDITS_API_BASE_URL + '/api/v1/cleanup-certificates';
    depositEndpoint = process.env.VUE_APP_COLLECTION_API_BASE_URL + '/api/v1/deposit';

    async getOneDepositRegistration(depositId) {
        return await apiService.call('get', `${this.certificationEndpoint}/deposit-registrations/${depositId}`);
    }

    async getDepositsPendingEmpowerSignature() {
        let result = await apiService.call("GET", `${this.certificationEndpoint}/deposit-registrations/collectors/signed`)
        return result.certificates
    }

    async signDepositsAndIssuePlasticCredits(depositId){
        return await apiService.call("POST",`${this.depositEndpoint}/deposits/sign/empower`, {deposits: depositId})
    }

    async rejectDeposits(reason, depositsArray){
        return await apiService.call("POST", `${this.depositEndpoint}/deposits/reject`,
            {
                    reason: reason,
                    deposits: depositsArray
            }
        )
    }

    async getRejectedDeposits(){
        return await apiService.call("GET", `${this.depositEndpoint}/deposits/reject`)
    }

    // this returns plastic credits issued to facilities, available for purchase by admin
    async getPlasticCreditBalance() {
        let result = await apiService.call("GET", `${this.certificationEndpoint}/plastic-credits/balance`)
        return result.balances
    }

    async getPlasticCreditBalanceForOneWasteTypeOfOneFacility(ownerId, massBalanceDefinition) {
        let result = await apiService.call("POST", `${this.certificationEndpoint}/plastic-credits/single/balance`,
            {
                ownerId,
                massBalanceDefinition
            }
        )
        return result.balance
    }

    async adminBuyPlasticCredits(from, to, amount, massBalanceDefinition, payment, poolPrice) {
        return await apiService.call("POST",`${this.certificationEndpoint}/plastic-credits/admin/purchase`,
            {
                from,
                to,
                amount,
                massBalanceDefinition,
                payment,
                poolPrice
            }
        )
    }

    async getPlasticCreditTransactionHistory() {
        let result = await apiService.call("GET", `${this.certificationEndpoint}/plastic-credits/history`)
        return result.history
    }

    async getRejectedApprovedAndPendingEmpowerSignatureDeposits(){
        let results = await apiService.call("GET", `${this.certificationEndpoint}/deposit-registrations/admin`)
        return results.certificates
    }


}
