<template>
    <div>
        <ContentInput :content-config="contentConfig"/>
    </div>
</template>

<script>
import ContentInput from "../ContentInput";

export default {
        name: "CreateScheme",
        components: {ContentInput},
        data() {
            return {
                contentConfig: {
                    title: "Create scheme",
                    buttons: [
                        {
                            title: "Create scheme",
                            isDark: true,
                            callback: () => {
                                const organizationId = this.contentConfig.sections.schemeInfo.inputs.organizationId.value
                                const schemeName = this.contentConfig.sections.schemeInfo.inputs.schemeName.value
                                const materialType = this.contentConfig.sections.schemeInfo.inputs.materialType.value
                                const magnitudeStr = this.contentConfig.sections.schemeInfo.inputs.magnitude.value
                                const rewardTypeStr = this.contentConfig.sections.schemeInfo.inputs.rewardType.value
                                const rewardId = this.contentConfig.sections.schemeInfo.inputs.rewardId.value
                                const perItem = this.contentConfig.sections.schemeInfo.inputs.perItem.value
                                const adminToken = this.contentConfig.sections.schemeInfo.inputs.adminToken.value

                                if (!organizationId || !schemeName || !materialType || !magnitudeStr || !rewardTypeStr || !rewardId) {
                                    alert('Missing field(s)')
                                    return
                                }

                                const magnitude = magnitudeStr.toLowerCase() === "count" ? 1 : 0
                                const rewardType = rewardTypeStr.toLowerCase() === "voucher" ? 1 : 0

                                this.$depositAdminClient.createScheme({
                                    name: schemeName,
                                    organizationID: organizationId,
                                    rewardDefinitions: [{
                                        itemDefinition: {
                                            magnitude,
                                            materialDefinition: JSON.parse(materialType)
                                        },
                                        rewardType,
                                        rewardTypeID: rewardId,
                                        perItem
                                    }]
                                }, adminToken)
                                    .then(() => {
                                        alert("Success!")
                                        this.$router.push({name: 'deposit-organizations'})
                                    })
                                    .catch(err => {
                                        console.error(err)
                                        alert(err);
                                    })
                            }
                        }
                    ],
                    sections: {
                        "schemeInfo": {
                            title: "Scheme Info:",
                            type: "info",
                            inputs: {
                                "organizationId": {
                                    title: "OrganizationID",
                                    type: "text",
                                    value: this.$route.params.organizationId,
                                },
                                "schemeName": {
                                    title: "Scheme Name",
                                    type: "text",
                                    value: undefined,
                                },
                                "materialType": {
                                    title: "Material type (JSON)",
                                    type: "text",
                                    value: undefined,
                                },
                                "magnitude": {
                                    title: "Magnitude",
                                    type: "dropdown",
                                    values: ["WEIGHT", "COUNT"],
                                    value: "WEIGHT"
                                },
                                "rewardType": {
                                    title: "Reward type",
                                    type: "dropdown",
                                    values: ["TOKEN", "VOUCHER"],
                                    value: "VOUCHER"
                                },
                                "rewardId": {
                                    title: "Reward ID",
                                    type: "text",
                                    value: undefined,
                                },
                                "perItem": {
                                    title: "Per itme",
                                    type: "number",
                                    value: 1.0
                                },
                                "adminToken": {
                                    title: "Admin token",
                                    type: "text",
                                    value: undefined,
                                }
                            }
                        }
                    }
                }
            }
        },
    }
</script>

<style scoped>

</style>
