<template>
    <ContentView :content-config="contentConfig"/>
</template>

<script>
import ContentView from "../ContentView";

export default {
        name: "ViewCoupon",
        components: {ContentView},
        created() {
            this.loadCoupon();
        },
        methods: {
          loadCoupon() {
              this.$cleanCityService.getCoupon(this.$route.params.couponId).then((coupon) => {
                    this.contentConfig.sections[0].data.couponId.value = coupon.couponId;
                    this.contentConfig.sections[0].data.code.value = coupon.code;
                }
            );
          }
        },
        data() {
            return {
                contentConfig: {
                    buttons: [
                        {
                            title: "Remove",
                            isDark: true,
                            callback: () => {
                                const remove = confirm("Are you sure you want to remove this coupon?");
                                if (remove) {
                                    this.$cleanCityService.removeCoupon(this.$route.params.couponId).then(() => {
                                        return this.$router.push({
                                            name: 'all-coupons',
                                            params: {}
                                        });
                                    })
                                }
                            }
                        },
                         {
                            title: "Edit",
                            isDark: true,
                            callback: () => {
                                this.$router.push({
                                    name: 'edit-coupon',
                                    params: {couponId: this.$route.params.couponId}
                                });
                            }
                        }
                    ],
                    sections: [
                        {
                            type: "info",
                            title: "Coupon Info",
                            data: {
                                "couponId": {
                                    title: "Coupon ID",
                                    value: ""
                                },
                                "code": {
                                    title: "Coupon Code",
                                    value: ""
                                }
                            }
                        }
                    ]
                }
            }
        }
    }
</script>

<style scoped>

</style>
