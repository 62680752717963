<template>
    <div>
        <div v-if="certificate" >
            <div class="my-certificates__top-info" :class="{ 'is-issued': certificateClone.certificationState === 'SIGNED_BY_EMPOWER', 'is-rejected': certificateClone.certificationState === 'REJECTED'}" v-if="certificateClone.certificationState !== 'SIGNED_BY_COLLECTOR'">
                <div class="my-certificates__top-info--item">
                    <h2>{{formatStatus(certificateClone.certificationState)}} <span v-if="certificateClone.certificationState !== 'REJECTED'">plastic credits(KG)</span></h2>
                    <p v-if="certificateClone.certificationState === 'REJECTED'">{{certificateClone.rejectReason}} </p>
                    <p v-else> {{certificateClone.amount}}</p>
                </div>
                <div class="my-certificates__top-info--item">
                    <h2>{{formatStatus(certificateClone.certificationState)}} by</h2>
                    <p>Empower</p>
                </div>
                <div class="my-certificates__top-info--item">
                    <h2>{{formatStatus(certificateClone.certificationState)}} date</h2>
                    <p>{{ formatDate(certificateClone.signedDate) }}</p>
                </div>
            </div>
            <TrackingDetails
                    :certificate="certificateClone"
                    :isEditable="trackingDetailsAreEditable"
                    @closeView="closeView"
                    @certStatusUpdated="certStatusUpdated"
            />
        </div>
    </div>
</template>

<script>
import moment from "moment";
import TrackingDetails from "../TrackingDetails";

export default {
        name: "ViewPlasticCreditDetails",
        components: {TrackingDetails},
        props: {
            certificate: {
                type: Object
            },
            organizationName: String,
        },
        data(){
            return {
                certificateClone: {
                    ...this.certificate,
                },
                longitude: null,
                latitude: null,
                trackingDetailsAreEditable: false
            }
        },
        mounted() {
            this.longitude = this.certificateClone.location.coordinates[0];
            this.latitude = this.certificateClone.location.coordinates[1];
        },
        computed: {
            registrationDocuments() {
                return this.certificateClone.files.filter(file => {
                    return file.fileType === 'REGISTRATION_DOCUMENT';
                });
            },
            cleanupImages() {
                return this.certificateClone.files.filter(file => {
                    return file.fileType === 'CLEAN_UP_IMAGE';
                });
            },
            otherFiles() {
                return this.certificateClone.files.filter(file => {
                    return file.fileType === 'OTHER_FILES';
                });
            },
        },
        methods: {
            async goBack() {
                await this.$emit('closeView');
            },
            closeView() {
                this.$emit('closeOneCertificateView', true)
            },
            formatDate(date) {
                return moment.unix(date / 1000).format('DD-MM-YYYY HH:mm')
            },
            formatStatus(status) {
                if (status === 'SIGNED_BY_EMPOWER'){
                    return 'Issued'
                }
                else if (status === 'ISSUED') {
                    return 'Issued'
                }
                else if (status === 'REJECTED'){
                    return 'Reject'
                }
                else{
                    return status
                }
            },
            certStatusUpdated(certInfo) {
              this.$emit('certStatusUpdated', certInfo)
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "src/variables";

    .my-certificates__top-info{
        background: #F6F5F5;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 20px 50px 0;
        margin-bottom: 25px;
        &--item{
            width: 28%;
            padding-bottom: 15px;
            h2, p {
                color: #1B1B1B;
                padding-bottom: 10px;
            }
            h2{
                font-weight: bold;
                font-size: 18px;
                line-height: 25px;
            }
            p{
                font-size: 14px;
                line-height: 19px;
            }
        }
    }

    .is-issued {
        border: 1px solid $new-green;
    }

    .is-rejected {
        border: 1px solid $error-red;;
    }

</style>
