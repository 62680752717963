<template>
    <div>
        <Navbar />
        <main class="page-width">
            <LeftNavigation :navigation="navigation" />
            <div id="main-content" :class="{'adjust-width': navigation}">
                <slot/>
            </div>
        </main>
    </div>
</template>

<script>
import Navbar from "./Navbar.vue";
import LeftNavigation from "./LeftNavigation.vue";

export default {
        name: "Layout",
        components: {
            Navbar,
            LeftNavigation
        },
        props: {
            navigation: Object
        }
    }
</script>

<style lang="scss" scoped>
    main {
        display: flex;
        margin-top: 25px;
        padding: 0 5.5% 50px;
        min-height: calc(100vh - 125px);


        #main-content {
            background-color: #fff;
            flex-grow: 1;
        }

        .adjust-width{
            max-width: calc(100% - 264px);
        }
    }
</style>
