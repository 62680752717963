<template>
    <div>
        <DataTable :data-config="dataConfig" @reload="reloadDepositOrganizations"/>
    </div>
</template>

<script>
import DataTable from "../DataTable";

export default {
    name: "DepositOrganizations",
    components: {DataTable},
    async mounted() {
        await this.reloadDepositOrganizations()
    },
    data() {
        return {
            dataConfig: {
                columns: [
                    "Organization ID", "Org Name", "Public key"
                ],
                rows: []
            },
        }
    },
    methods: {
        async reloadDepositOrganizations() {
            this.loading = true;

            const depositOrgs = await this.$depositAdminClient.getAllOrganizations()

            this.dataConfig.items = [];
            for (let organization of depositOrgs) {
                this.dataConfig.rows.push({
                    id: organization.id,
                    selected: false,
                    data: {
                        organizationId: organization.id,
                        organizationName: organization.name,
                        organizationPublicKey: organization.pubKey
                    },
                    callback: () => {
                        this.$router.push({
                            name: 'view-deposit-organization',
                            params: {organizationId: organization.id}
                        })
                    }
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
