<template>
    <div>
        <input type="text" v-on:input="search($event)">
        <div v-on:click="selectCallback(organization)" v-for="organization of matchedOrganizations"
             v-bind:key="organization.organizationId">
            {{organization.organizationName}} ({{organization.organizationNumber}})
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrganizationSearch",
        props: {
            selectCallback: Function,
            organizationsToIgnore: Array
        },
        async created() {
            if (!this.$store.state.organizationStore.organizations.length) {
                await this.$store.dispatch('getAllOrganizations')
            }
        },
        data() {
            return {
                matchedOrganizations: [],
            }
        },
        computed: {
            organizations() {
                return this.$store.state.organizationStore.organizations
            }
        },
        methods: {
            search(event) {
                this.matchedOrganizations = [];
                const searchTerm = event.target.value || "";
                let organizationsToSearch = this.organizations;
                if (this.organizationsToIgnore) {
                    organizationsToSearch = this.organizations.filter(
                        organization => !this.organizationsToIgnore.some(
                            organizationToIgnore => organization.organizationId === organizationToIgnore.organizationId));
                }

                for (const organization of organizationsToSearch) {
                    if (organization.organizationName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
                        (organization.organizationNumber && organization.organizationNumber.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)) {
                        this.matchedOrganizations.push(organization);
                    }
                }
            }
        },
    }
</script>

<style scoped>

</style>
