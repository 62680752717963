<template>
    <div id="login-container">
        <img src="../assets/empower-workspace-logo-green.svg">
        <div id="login-block">
            <form v-on:submit.prevent="handleSubmit">
                <input type="email" id="email" v-model="email" placeholder="User name/Email" required>
                <input type="password" id="password" v-model="password" placeholder="Password" required>

                <button id="sign-up-button">Login</button>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "login",
        data() {
            return {
                email: '',
                password: ''
            }
        },
        methods: {
            handleSubmit() {
                this.$userService.login(this.password, this.email).then(() => this.$router.push('/')).catch(() => {
                    alert("Wrong email or password");
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    #login-container {
        width: 100%;
        max-width: 435px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 86px;
        padding-bottom: 40px;

        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        #login-block {
            width: 100%;
            background-color: #fff;
            margin-top: 40px;
            padding-top: 64px;
            padding-bottom: 84px;
            border-radius: 4px;

            input {
                box-sizing: border-box;
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 350px;
                height: 50px;
                max-width: 90%;
                margin-bottom: 45px;
                border: none;
                border-bottom: 1px solid;
                font-size: 14px;
            }

            #sign-up-button {
                display: block;
                font-size: 18px;
                width: 350px;
                height: 50px;
                max-width: 90%;
                margin-left: auto;
                margin-right: auto;
                border: none;
                background-color: #F6F5F5;
                border-radius: 4px;
            }
        }
    }
</style>
