<template>
    <ContentView :content-config="contentConfig"/>
</template>

<script>
import ContentView from "../ContentView";

export default {
        name: "ViewFacility",
        components: {ContentView},
        created() {
            this.loadFacility()
        },
        methods: {
            loadFacility() {
                return this.$facilityService.getFacility(this.$route.params.facilityId)
                .then(facility => {
                        this.contentConfig.sections[0].data.facilityId.value = facility.facilityId;
                        this.contentConfig.sections[0].data.facilityName.value = facility.facilityName;
                        this.contentConfig.sections[0].data.street.value = facility.street;
                        this.contentConfig.sections[0].data.zip.value = facility.zip;
                        this.contentConfig.sections[0].data.city.value = facility.city;
                        this.contentConfig.sections[0].data.country.value = facility.country;
                        this.contentConfig.sections[0].data.countryCode.value = facility.countryCode;
                        this.contentConfig.sections[0].data.phoneNumber.value = facility.phoneNumber;
                        this.contentConfig.sections[0].data.email.value = facility.email;
                        this.contentConfig.sections[0].data.contactPerson.value = facility.contactPerson;
                        this.contentConfig.sections[0].data.lat.value = facility.latitude;
                        this.contentConfig.sections[0].data.lng.value = facility.longitude;
                        this.contentConfig.sections[0].data.pictures.values = facility.pictures;
                    }
                );
            }
        },
        data() {
            return {
                contentConfig: {
                    buttons: [
                        {
                            title: "Edit",
                            isDark: true,
                            callback: () => {
                                this.$router.push({
                                    name: 'edit-facility',
                                    params: {facilityId: this.$route.params.facilityId}
                                });
                            }
                        },
                        {
                          title: "Delete",
                          isDark: true,
                          callback: async () => {
                            let confirmed = confirm(`Are you sure you want to delete ${this.contentConfig.sections[0].data.facilityName.value} ?`)
                            if(confirmed) {
                              try {
                                await this.$facilityService.deleteFacility(this.$route.params.facilityId);
                                await this.$router.push({name: 'facilities'})
                              } catch (err) {
                                alert(err);
                              }

                            }
                          }
                        }
                    ],
                    sections: [
                        {
                            type: "info",
                            title: "Facility Info",
                            data: {
                                "facilityId": {
                                    title: "Facility ID",
                                    value: ""
                                },
                                "facilityName": {
                                    title: "Facility Name",
                                    value: ""
                                },
                                "street": {
                                    title: "Street",
                                    value: ""
                                },
                                "city": {
                                    title: "City",
                                    value: ""
                                },
                                "zip": {
                                    title: "Zip",
                                    value: ""
                                },
                                "country": {
                                    title: "Country",
                                    value: ""
                                },
                                "countryCode": {
                                    title: "Country Code",
                                    value: ""
                                },
                                "phoneNumber": {
                                    title: "Phone Number",
                                    value: ""
                                },
                                "email": {
                                    title: "Email",
                                    value: ""
                                },
                                "contactPerson": {
                                    title: "Contact Person",
                                    value: ""
                                },
                                "lat": {
                                    title: "Latitude",
                                    value: ""
                                },
                                "lng": {
                                    title: "Longitude",
                                    value: ""
                                },
                                "pictures": {
                                    title: "Pictures",
                                    type: "multiple",
                                    typeDefinition: "img",
                                    values: []
                                }
                            }
                        }
                    ]
                }
            }
        }
    }
</script>

<style scoped>

</style>
