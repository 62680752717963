<template>
  <div>
    <DataTable :data-config="dataConfig" @reload="reloadOrganizations"/>
  </div>
</template>

<script>
import DataTable from "../DataTable";
import moment from 'moment';

export default {
      name: "AllOrganizations",
      components: {DataTable},
      async mounted() {
        if (this.allOrganizations.length === 0) {
          await this.$store.dispatch('getAllOrganizations');
        }
        await this.setAllOrganizationsTable();
      },
      data() {
        return {
          dataConfig: {
            columns: [
              "Organization Name", "Org Number", "Registered"
            ],
            rows: []
          },
        }
      },
      computed: {
        allOrganizations() {
          return this.$store.state.organizationStore.organizations;
        }
      },
      methods: {
        async reloadOrganizations() {
          this.loading = true;
          await this.$store.dispatch('getAllOrganizations');
          await this.setAllOrganizationsTable();
        },
        async setAllOrganizationsTable() {
          this.dataConfig.items = [];
          for (let organization of this.allOrganizations) {
            organization.registered = organization.registered ? moment(organization.registered).format('MM-DD-YY') : ''
            this.dataConfig.rows.push({
              id: organization.organizationId,
              selected: false,
              data: {
                organizationName: organization.organizationName,
                organizationNumber: organization.organizationNumber,
                registered: organization.registered
              },
              callback: () => {
                this.$router.push({
                  name: 'view-organization',
                  params: {organizationId: organization.organizationId}
                })
              }
            })
          }
        },

      },
  }
</script>

<style scoped>

</style>
