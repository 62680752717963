<template>
    <Layout>
        Home Sweet Home
    </Layout>
</template>

<script>
import Layout from "../components/Layout";

export default {
        name: 'home',
        components: {
            Layout
        }
    }
</script>

<style lang="scss" scoped>

</style>
