import axios from 'axios';
import {loadingService, userService} from '@/main';


export default class ApiService {
    token;
    endpoint;
    vue;

    setToken() {
        this.token = userService.getToken()
        this.updateEndpoint()
    }

    setVue(vueInstance) {
        this.vue = vueInstance;
    }

    updateEndpoint() {
        this.endpoint = axios.create({
            json: true,
            headers: {
                'Authorization': `Bearer ${this.token}`,
            }
        })
    }

    async call(method, resource, data) {
        loadingService.addLoadingItem()
        return this.endpoint({
            method,
            url: resource,
            data,
        })
            .then(res => {
                loadingService.removeLoadingItem()
                return res.data
            })
            .catch(err => {
                this.handleError(err)
                throw err.response.data
            })
    }

    handleError(error) {
        loadingService.removeLoadingItem();
        let errorString;
        if (error && error.response && error.response.data && error.response.data.message) {
            errorString = error.response.data.message;
        }
        this.vue.$eventBus.$emit("error", errorString);
    }

}
