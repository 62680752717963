<template>
    <div>
        <ContentInput :content-config="contentConfig"/>
    </div>
</template>

<script>
import ContentInput from "../ContentInput";

export default {
    name: "EditScheme",
    components: {ContentInput},
    async created() {
        const scheme = await this.$depositAdminClient.getScheme(this.$route.params.schemeId)
        this.contentConfig.sections.schemeInfo.inputs.schemeName.value = scheme.name
        if (scheme.rewardDefinitions && scheme.rewardDefinitions.length === 1) {
            this.contentConfig.sections.schemeInfo.inputs.materialType.value = JSON.stringify(scheme.rewardDefinitions[0].itemDefinition.materialDefinition)
            this.contentConfig.sections.schemeInfo.inputs.magnitude.value = scheme.rewardDefinitions[0].itemDefinition.magnitude === 1 ? "COUNT": "WEIGHT"
            this.contentConfig.sections.schemeInfo.inputs.rewardType.value = scheme.rewardDefinitions[0].rewardType === 1 ? "VOUCHER": "TOKEN"
            this.contentConfig.sections.schemeInfo.inputs.rewardId.value = scheme.rewardDefinitions[0].rewardTypeID
            this.contentConfig.sections.schemeInfo.inputs.perItem.value = scheme.rewardDefinitions[0].perItem
        }
        if (scheme.collectionPoints) {
            this.contentConfig.sections.schemeInfo.inputs.collectionPoints.value = scheme.collectionPoints.join(';')
        }

    },
    data() {
        return {
            contentConfig: {
                title: "Edit scheme",
                buttons: [
                    {
                        title: "Save",
                        isDark: true,
                        callback: () => {
                            const schemeId = this.contentConfig.sections.schemeInfo.inputs.schemeId.value
                            const schemeName = this.contentConfig.sections.schemeInfo.inputs.schemeName.value
                            const materialType = this.contentConfig.sections.schemeInfo.inputs.materialType.value
                            const magnitudeStr = this.contentConfig.sections.schemeInfo.inputs.magnitude.value
                            const rewardTypeStr = this.contentConfig.sections.schemeInfo.inputs.rewardType.value
                            const rewardId = this.contentConfig.sections.schemeInfo.inputs.rewardId.value
                            const perItem = parseFloat(this.contentConfig.sections.schemeInfo.inputs.perItem.value)
                            const adminToken = this.contentConfig.sections.schemeInfo.inputs.adminToken.value
                            const collectionPointsStr = this.contentConfig.sections.schemeInfo.inputs.collectionPoints.value
                            const collectionPoints = collectionPointsStr.split(';')
                                .map(cp => cp.trim())
                                .filter(cp => cp !== "")

                            if (!schemeId || !schemeName || !materialType || !magnitudeStr || !rewardTypeStr || !rewardId || !collectionPointsStr || !adminToken) {
                                alert('Missing field(s)')
                                return
                            }

                            const magnitude = magnitudeStr.toLowerCase() === "count" ? 1 : 0
                            const rewardType = rewardTypeStr.toLowerCase() === "voucher" ? 1 : 0

                            this.$depositAdminClient.editScheme({
                                schemeID: schemeId,
                                name: schemeName,
                                rewardDefinitions: [{
                                    itemDefinition: {
                                        magnitude,
                                        materialDefinition: JSON.parse(materialType)
                                    },
                                    rewardType,
                                    rewardTypeID: rewardId,
                                    perItem
                                }],
                                collectionPoints: collectionPoints
                            }, adminToken)
                                .then(() => {
                                    alert("Success!")
                                    this.$router.push({name: 'deposit-organizations'})
                                })
                                .catch(err => {
                                    console.error(err)
                                    alert(err);
                                })
                        }
                    }
                ],
                sections: {
                    "schemeInfo": {
                        title: "Scheme Info:",
                        type: "info",
                        inputs: {
                            "schemeId": {
                                title: "Scheme ID",
                                type: "text",
                                value: this.$route.params.schemeId,
                            },
                            "schemeName": {
                                title: "Scheme Name",
                                type: "text",
                                value: undefined,
                            },
                            "collectionPoints": {
                                title: "Collection points",
                                type: "text",
                                value: "",
                            },
                            "materialType": {
                                title: "Material type (JSON)",
                                type: "text",
                                value: undefined,
                            },
                            "magnitude": {
                                title: "Magnitude",
                                type: "dropdown",
                                values: ["WEIGHT", "COUNT"],
                                value: "WEIGHT"
                            },
                            "rewardType": {
                                title: "Reward type",
                                type: "dropdown",
                                values: ["TOKEN", "VOUCHER"],
                                value: "VOUCHER"
                            },
                            "rewardId": {
                                title: "Reward ID",
                                type: "text",
                                value: undefined,
                            },
                            "perItem": {
                                title: "Per itme",
                                type: "number",
                                value: 1.0
                            },
                            "adminToken": {
                                title: "Admin token",
                                type: "text",
                                value: undefined,
                            }
                        }
                    }
                }
            }
        }
    },
}
</script>

<style scoped>

</style>
