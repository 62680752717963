<template>
    <div>
        <Modal :close-callback="closeCallback" v-if="showOrganizationModal">
            <OrganizationSearch :select-callback="organizationSelected"/>
        </Modal>
        <ContentInput :content-config="contentConfig"/>
    </div>
</template>

<script>
import ContentInput from "../ContentInput";
import Modal from "../Modal";
import OrganizationSearch from "../OrganizationSearch";

export default {
        name: "CreateDepositOrganization",
        components: {OrganizationSearch, Modal, ContentInput},
        data() {
            return {
                showOrganizationModal: false,
                showFacilityModal: false,
                organizationId: undefined,
                contentConfig: {
                    title: "Create Deposit Organization",
                    buttons: [
                        {
                            title: "Create Deposit Organization",
                            isDark: true,
                            callback: () => {
                                const organizationId = this.contentConfig.sections.depositOrgInfo.inputs.organizationId.value
                                const organizationName = this.contentConfig.sections.depositOrgInfo.inputs.organizationName.value
                                const organizationSigningPublicKey = this.contentConfig.sections.depositOrgInfo.inputs.organizationSigningPublicKey.value
                                const organizationEncryptionPublicKey = this.contentConfig.sections.depositOrgInfo.inputs.organizationEncryptionPublicKey.value
                                const adminToken = this.contentConfig.sections.depositOrgInfo.inputs.adminToken.value

                                if (!organizationSigningPublicKey) {
                                    alert("Organization needs a signing public key")
                                    return
                                }

                                if (!organizationEncryptionPublicKey) {
                                    alert("Organization needs a encryption public key")
                                    return
                                }

                                if (!adminToken) {
                                    alert("Missing admin token")
                                    return
                                }

                                this.$depositAdminClient.createOrganization({
                                    id: organizationId,
                                    name: organizationName,
                                    signingPubKey: organizationSigningPublicKey,
                                    encryptionPubKey: organizationEncryptionPublicKey
                                }, adminToken)
                                    .then(() => {
                                        alert("Success!")
                                        this.$router.push({name: 'deposit-organizations'})
                                    })
                                    .catch(err => {
                                        console.error(err)
                                        alert(err);
                                    })
                            }
                        }
                    ],
                    sections: {
                        "depositOrgInfo": {
                            title: "Organization Info:",
                            type: "info",
                            inputs: {
                                "organizationId": {
                                    title: "OrganizationID",
                                    type: "text",
                                    value: undefined,
                                    onFocus: () => {
                                        this.showOrganizationModal = true
                                    }
                                },
                                "organizationName": {
                                    title: "Organization name",
                                    type: "text",
                                    value: undefined,
                                    onFocus: () => {
                                        this.showOrganizationModal = true
                                    }
                                },
                                "organizationSigningPublicKey": {
                                    title: "Organization signing public key",
                                    type: "text",
                                    value: undefined,
                                    onFocus: () => {
                                        this.showOrganizationModal = true
                                    }
                                },
                                "organizationEncryptionPublicKey": {
                                    title: "Organization signing public key",
                                    type: "text",
                                    value: undefined,
                                    onFocus: () => {
                                        this.showOrganizationModal = true
                                    }
                                },
                                "adminToken": {
                                    title: "Admin token",
                                    type: "text",
                                    value: undefined,
                                }
                            }
                        }
                    }
                }
            }
        },
        methods: {
            closeCallback() {
                this.showOrganizationModal = false;
                this.showFacilityModal = false;
            },
            organizationSelected(organization) {
                this.organizationId = organization.organizationId;
                this.contentConfig.sections.depositOrgInfo.inputs.organizationId.value = organization.organizationId;
                this.contentConfig.sections.depositOrgInfo.inputs.organizationName.value = organization.organizationName;
                this.contentConfig.sections.depositOrgInfo.inputs.organizationSigningPublicKey.value = organization.signingPublicKey;
                this.contentConfig.sections.depositOrgInfo.inputs.organizationEncryptionPublicKey.value = organization.encryptionPublicKey;
                this.showOrganizationModal = false;
            },
        }

    }
</script>

<style scoped>

</style>
