<template>
    <div>
        <div id="data-table-header">
            <img alt="Reload image" id="data-table-reload-img" src="../assets/reload.svg" @click="$emit('reload')">
        </div>
<!--        <div id="data-table-header">-->
<!--            <img v-on:click="dataConfig.reload()" id="data-table-reload-img" src="../assets/reload.svg">-->
<!--        </div>-->
        <div id="data-table-container">
            <table>
                <tr>
                    <th><input v-on:change="toggleAll($event)" type="checkbox"></th>
                    <th v-for="column in dataConfig.columns" v-bind:key="column">
                        {{column}}
                    </th>
                </tr>
                <tr v-for="(row, index) in dataConfig.rows" v-bind:key="index"
                    v-on:click="handleTrClick(row, $event)">
                    <td>
                        <input v-model="row.selected" type="checkbox">
                    </td>
                    <td v-for="(value, index) of row.data" v-bind:key="`data-table-row-${index}`">
                        {{value}}
                    </td>
                    <td v-for="(button, buttonIndex) of row.buttons" v-bind:key="`button-table-row-${buttonIndex}`">
                        <button type="button" v-on:click="button.callback()">{{button.title}}</button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DataTable",
        props: {
            dataConfig: Object
        },
        methods: {
            toggleAll(event) {
                const newValue = event.target.checked;
                for (let item of this.dataConfig.rows) {
                    item.selected = newValue;
                }
            },
            handleTrClick(item, event) {
                if (event.target.type !== 'checkbox' && event.target.type !== 'button') {
                    item.callback();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    #data-table-header {
        background-color: #4A4A4A;
        padding: 12px 14px 9px 25px;

        #data-table-reload-img {
            cursor: pointer;
        }
    }

    #data-table-container {
        padding: 16px 25px 16px 25px;

        table {
            width: 100%;

            th {
                text-align: left;
            }

            th, td {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }


</style>
