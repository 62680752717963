<template>
    <div>
        <form>
            <div class="verification-documents__photo">
                <div class="verification-documents__photo-upload">
                    <div class="profile-upload-photo">
                        <div class="avatar-placeholder add-border" v-if="!organizationCopy.organizationPicture"> E </div>
                        <input ref="file"
                               type="file"
                               accept="image/*"
                               id="uploadAvatar"
                               style="display: none"
                        >
                        <img class="profile-upload-photo" v-if="organizationCopy.organizationPicture" :src="organizationCopy.organizationPicture" alt="Organization's avatar" />
                    </div>
                    <div style="margin-left: 12px;" >
                        <p class="title">Organization’s profile:</p>
                    </div>
                </div>
            </div>
        </form>
        <form novalidate class="md-layout" style="padding-bottom: 42px;">
            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title title">Basic:</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field>
                                <label for="organizationName">Organization name*</label>
                                <md-input type="text" name="organizationName" id="organizationName" autocomplete="organizationName" v-model="organizationCopy.organizationName" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field>
                                <label for="organizationNumber">Organization number*</label>
                                <md-input type="text" name="organizationNumber" id="organizationNumber" autocomplete="organizationNumber" v-model="organizationCopy.organizationNumber" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field>
                                <label for="country">Country/Area of organization*</label>
                                <md-input type="text" id="country" name="country" autocomplete="country" v-model="organizationCopy.country" :disabled="blockInputs" />
                            </md-field>
                        </div>

                        <div class="md-layout-item md-small-size-100">
                            <md-field>
                                <label for="address">Organization main site address*</label>
                                <md-input type="text" id="address" name="address" autocomplete="address" v-model="organizationCopy.address" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title title">Contact info:</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field>
                                <label for="contactName">Contact person’s name*</label>
                                <md-input type="text" name="contactName" id="contactName" autocomplete="given-name" v-model="organizationCopy.contactPerson" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field >
                                <label for="contactEmail">Contact person’s Email address*</label>
                                <md-input type="email" name="contactEmail" id="contactEmail" autocomplete="contactEmail" v-model="organizationCopy.contactEmail" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field>
                                <label for="contactPhone">Phone number</label>
                                <md-input type="text" name="phoneNumber" id="contactPhone" autocomplete="phoneNumber" v-model="organizationCopy.contactPhone" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
            <md-card class="md-layout-item md-size-100 md-small-size-100">
                <md-card-header>
                    <div class="md-title title">Others:</div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-small-size-100">
                            <md-field>
                                <label for="website">Organization website URL</label>
                                <md-input type="text" name="website" id="website" autocomplete="website" v-model="organizationCopy.companyWebsite" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                    <div class="md-layout md-gutter" style="margin-bottom: 42px">
                        <div class="md-layout-item md-small-size-100">
                            <md-field >
                                <label for="orgDesc">Short description of organization</label>
                                <md-input type="text" name="orgDesc" id="orgDesc" autocomplete="orgDesc" v-model="organizationCopy.aboutOrganization" :disabled="blockInputs" />
                            </md-field>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </form>
    </div>
</template>

<script>
    export default {
        name: "ViewOrganizationProfile",
        props: {
          organization: {
            type: Object
          }
        },
        data() {
            return {
                organizationCopy: JSON.parse(JSON.stringify(this.organization)),
                blockInputs: true,
            }
        },
    }
</script>

<style scoped lang="scss">
     @import "../../variables";


    form{
        margin: 30px 0;
        background: #F6F5F5 !important;
    }

    section{
        width: 76%;
        margin: 0 auto;
    }

    .verification-documents__photo{
        height: 140px;
        &-upload{
            display: flex;
            padding: 40px;
        }
    }

    .avatar-placeholder{
        width: 60px;
        height: 60px;
        background: #FFFFFF;
        border: 0.5625px solid #9B9B9B;
        font-weight: bold;
        font-size: 45px;
        line-height: 61px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
        justify-content: center;
    }

    .profile-upload-photo{
        height: 60px;
        width: 60px;
    }

    .md-card-header{
        padding: 30px 40px 0;
    }

    .md-card{
        text-align: left;
    }

    .md-card-content{
        padding: 0 40px 0;
        flex-wrap: wrap;
        padding-bottom: 0 !important;
    }

    .md-layout{
        display: block;
    }

    .md-layout, .md-card{
        background: #F6F5F5;
    }

    .md-title{
        text-align: left;
        font-weight: bold !important;
        font-size: 18px !important;
        line-height: 25px !important;
    }

    .md-card{
        box-shadow: none;
        margin-top: 0 !important;
    }

    .md-input{
        border-bottom: 0.5px solid #1B1B1B !important;
    }

    .md-field{
        margin: 4px 0 18px;
        label{
            color: #9B9B9B !important;
        }
    }

    .md-error{
        color: #B00020;
    }

    .md-card.md-theme-default {
        background: #F6F5F5;
    }

</style>
