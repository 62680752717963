import {apiService} from "@/main";

export default class {
    organizationEndpoint = process.env.VUE_APP_ORGANIZATION_API_BASE_URL + '/api/v1/organization';

    async createOrganization(organizationObj) {
        return await apiService.call("POST", `${this.organizationEndpoint}/organizations`,
            {
                    organizationName: organizationObj.organizationName,
                    organizationNumber: organizationObj.organizationNumber
            }
        )
    }

    async editOrganization(organizationId, organizationUpdateObj) {
        return await apiService.call("PUT", `${this.organizationEndpoint}/organizations/${organizationId}`,
            {
                    organizationName: organizationUpdateObj.organizationName,
                    organizationNumber: organizationUpdateObj.organizationNumber,
                    aboutOrganization: organizationUpdateObj.aboutOrganization,
                    organizationPicture: organizationUpdateObj.orgPicture,
                    customDashboardUrl: organizationUpdateObj.customDashboardUrl,
                    customDashboardEmbedUrl: organizationUpdateObj.customDashboardEmbedUrl,
            }
        )
    }

    async getOrganization(organizationId) {
        return await apiService.call("GET", `${this.organizationEndpoint}/organizations/${organizationId}`)
    }

    async getAllOrganizations() {
        let result = await apiService.call("GET", `${this.organizationEndpoint}/organizations`)
        return result.organizations
    }

    async getUsersInOrganization(organizationId) {
        let result = await apiService.call("GET", `${this.organizationEndpoint}/organizations/${organizationId}/members`)
        return result.usersInOrganization
    }

    async addUserToOrganization(organizationId, userId) {
        return await apiService.call("PUT", `${this.organizationEndpoint}/organizations/${organizationId}/members/${userId}`)
    }

    async removeUserFromOrganization(organizationId, userId) {
        return await apiService.call("DELETE", `${this.organizationEndpoint}/organizations/${organizationId}/members/${userId}`)
    }

    async generateKey(organizationId) {
        return await apiService.call("POST", `${this.organizationEndpoint}/organizations/${organizationId}/generate-key`)
    }

}
