<template>
    <div class="content-container">
        <div class="content-header">
          <h4>{{contentConfig.title}}</h4>
          <div class="content-buttons">
            <button class="button__confirm"
                    v-for="(button, index) of contentConfig.buttons"
                    v-bind:key="index"
                    v-on:click="button.callback()">
              {{button.title}}
            </button>
          </div>
        </div>

        <hr>
        <ContentInputSection v-for="(section, sectionIndex) in contentConfig.sections" :title="section.title" v-bind:key="`section-index-${sectionIndex}`" >
            <div v-if="section.type === 'info'">
                <div v-for="(sectionInput, sectionInputIndex) in section.inputs" class="content-container-info " v-bind:key="`section-input-index-${sectionInputIndex}`">
                    <label>{{sectionInput.title}}</label>

                    <div v-if="sectionInput.type === 'array'">
                        <button v-on:click="sectionInput.selectCallback">Select</button>
                        <div v-for="(value, arrayIndex) of sectionInput.values" v-bind:key="arrayIndex">
                            <span v-if="sectionInput.label">
                                {{ value[sectionInput.label] }}
                            </span>
                            <span v-else>
                                {{ value }}
                            </span>
                        </div>
                    </div>
                    <div v-else-if="sectionInput.type === 'date-range'">
                        <label for="from-date">From:</label>
                        <input type="date" id="from-date" v-model="sectionInput.from">

                        <label for="to-date">To:</label>
                        <input type="date" id="to-date" v-model="sectionInput.to">
                    </div>
                    <select v-else-if="sectionInput.type === 'dropdown'" v-model="sectionInput.value">
                        <option v-for="optionValue of sectionInput.values" v-bind:key="optionValue" :value="optionValue">{{optionValue}}</option>
                    </select>
                    <select v-else-if="sectionInput.type === 'multiselect'"   multiple="multiple" v-bind:class="{ 'fix-height': multiple === 'true' }" v-model="sectionInput.value">
                        <option v-for="optionValue of sectionInput.values" v-bind:key="optionValue"  :value="optionValue">{{optionValue}}</option>
                    </select>
                    <div v-else-if="sectionInput.type === 'checkbox'">
                        <ul>
                            <li v-for="optionValue of sectionInput.values" :key="optionValue">
                               <input type="checkbox" :value="optionValue" :id="optionValue" v-model="sectionInput.value"> {{optionValue}}
                            </li>
                        </ul>
                    </div>
                    <div v-else-if="sectionInput.type === 'pictureupload'">
                        <img :src="sectionInput.value">
                        <input type="file" v-on:change="fileChanged($event, sectionInput.upload)">
                    </div>
                    <div v-else-if="sectionInput.type === 'fileupload'">
                        <input type="file" v-on:change="fileChanged($event, sectionInput.upload)">
                    </div>
                    <div v-else-if="sectionInput.type === 'phonenumber'">
                        <VuePhoneNumberInput class="phone-number-input" @update="phoneNumberUpdate" v-model="sectionInput.phoneNumber" placeholder="Phone number" props="size=sm"/>
                    </div>
                    <div v-else-if="sectionInput.type === 'multiple'">
                        <div v-if="sectionInput.typeDefinition === 'pictureupload'">
                            <div v-for="multipleValue of sectionInput.values" v-bind:key="multipleValue">
                                <img :src="multipleValue">
                                <button v-on:click="sectionInput.delete(multipleValue)">Delete</button>
                            </div>
                            <input type="file" v-on:change="fileChanged($event, sectionInput.upload)">
                        </div>
                        <div v-else>
                            Multiple of type definition {{sectionInput.typeDefinition}} not supported
                        </div>
                    </div>
                    <input v-else v-on:focus="sectionInput.onFocus ? sectionInput.onFocus() : () => {}" :type="sectionInput.type" v-model="sectionInput.value" >
                </div>

            </div>
            <div v-else-if="section.type === 'table'" class="content-container-info">
                <table>
                    <tr>
                        <th v-if="section.selectable"></th>
                        <th v-for="column in section.columns" v-bind:key="column">
                            {{column}}
                        </th>
                    </tr>
                    <tr v-for="(row, rowIndex) in section.rows" v-bind:key="row.id"
                        v-on:click="handleTrClick(row)">
                        <td v-if="section.selectable">
                            <input v-model="row.selected" type="checkbox">
                        </td>

                        <td v-for="(value, columnIndex) of row.data" v-bind:key="`content-input-table-row-${rowIndex}-${columnIndex}}`">
                            {{value}}
                        </td>
                    </tr>
                </table>
            </div>

            <div class="content-buttons">
               <button class="button__confirm"
                        v-for="(button, index) of section.buttons"
                        v-bind:key="index"
                        v-bind:class="{ 'admin-btn-dark': button.isDark }"
                        v-on:click="button.callback()">
                    {{button.title}}
                </button>
            </div>

        </ContentInputSection>
    </div>
</template>

<script>
import ContentInputSection from "./ContentInputSection";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
        name: "ContentInput",
        components: {ContentInputSection, VuePhoneNumberInput},
        props: {
            contentConfig: Object
        },
        methods: {
            handleTrClick(row) {
                row.callback();
            },
            fileChanged(event, upload) {
                upload(event.target.files);
            },
            multiple() {

            },
            phoneNumberUpdate(phoneNumberObj) {
                this.phoneNumberObj = phoneNumberObj;
                this.$parent.$emit('phone_number_updated', phoneNumberObj)
            }
        }
    }
</script>

<style lang="scss">
  .content-container {
    padding: 12px 24px;
    padding: 1rem;
    hr {
      border: solid .5px black;
      background-color: black;
    }
  }

  .content-header {
    font-size: 18px;
    font-weight: bold;
  }

  .content-buttons {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-direction: row;

    button {
      margin-right: 2rem;
    }

  }

    select.fix-height {
        height: 100px;
    }

    options.active {
        border:1px solid white;
        background: linear-gradient(#0e4f9a, #052f60);
        color: white;
    }


</style>
