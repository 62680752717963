<template>
    <DataTable :data-config="dataConfig"/>
</template>

<script>
import DataTable from "../DataTable";

export default {
        name: "Coupons",
        components: {DataTable},
        created() {
           this.loadCoupons();
        },
        methods: {
            loadCoupons() {
                this.$cleanCityService.getCoupons()
                    .then(coupons => {
                    this.dataConfig.items = [];
                    for (let aCoupon of coupons.coupons) {
                        this.dataConfig.rows.push({
                            id: aCoupon.couponId,
                            selected: false,
                            data: {
                                couponId: aCoupon.couponId,
                                name: aCoupon.code,
                                inUse: aCoupon.redeemableId,
                                isRedeemed: aCoupon.redeemed?"Yes":"No"
                            },
                            callback: () => {
                                this.$router.push({
                                    name: 'view-coupon',
                                    params: {couponId: aCoupon.couponId}
                                });
                            }
                            
                        })
                    }

                }
              )
            }
        },
        data() {
            return {
                dataConfig: {
                    columns: [
                        "CouponId", "Code", "In Use" , "Is Redeemed"
                    ],
                    rows: [],
                    reload: () => {
                            this.loadCoupons();
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
