<template>
    <div>
        <ContentInput :content-config="contentConfig"/>
    </div>
</template>

<script>
import ContentInput from "../ContentInput";

export default {
        name: "CreateRedeemable",
        components: {ContentInput},
        created() {
            this.loadMetadatas();
        },
        methods: {
            loadMetadatas() {
                 this.$cleanCityService.getUnusedCoupons().then(coups => {
                     this.contentConfig.sections.redeemableInfo.inputs.availableCoupons.values = coups.coupons.map(coup => coup.code)
                 })
            }
        },
        data() {
            return {
                 contentConfig: {
                    title: "New Redeemable",
                    buttons: [
                        {
                            title: "Create Redeemable",
                            isDark: true,
                            callback: () => {
                                const redeemableInfoInputs = this.contentConfig.sections.redeemableInfo.inputs;
                                let redeemableClone =  {
                                        name: redeemableInfoInputs.name.value,
                                        goal: redeemableInfoInputs.goal.value,
                                        description: redeemableInfoInputs.description.value,
                                        type: redeemableInfoInputs.type.value,
                                        couponCost: 0,
                                        availableCoupons: []
                                }
                                if (redeemableClone.type.toUpperCase() === 'COUPON') {
                                    redeemableClone.couponCost = redeemableInfoInputs.couponCost.value;
                                    redeemableClone.availableCoupons = redeemableInfoInputs.availableCoupons.value;
                                }
                                if (redeemableInfoInputs.picture.value !== undefined) {
                                    this.$sharedFileUploadService.uploadFiles(redeemableInfoInputs.picture.value).then(files => {
                                     redeemableClone.picture = files[0].ssl_url
                                     this.$cleanCityService.createRedeemable(redeemableClone).then(() => this.$router.push({name: 'all-redeemables'}))
                                    })
                                } else {
                                    this.$cleanCityService.createRedeemable(redeemableClone).then(() => this.$router.push({name: 'all-redeemables'}))
                                }
                            }
                        }
                    ],
                    sections: {
                        "redeemableInfo": {
                            title: "Redeemable Info:",
                            type: "info",
                            inputs: {
                                "name": {
                                    title: "Name",
                                    type: "text",
                                    value: undefined
                                },
                                "goal": {
                                    title: "Goal",
                                    type: "number",
                                    value: 0
                                },
                                "description": {
                                    title: "Description",
                                    type: "text",
                                    value: "",
                                    optional: true,
                                },
                                "type": {
                                    title: "Type",
                                    type: "dropdown",
                                    values: ["DONATION", "COUPON"],
                                    value: "DONATION"
                                },
                                "availableCoupons": {
                                    title: "Available Coupons",
                                    type: "checkbox",
                                    value: [],
                                    values: []
                                },
                                "couponCost": {
                                    title: "Coupon Cost",
                                    type: "number",
                                    value: 0
                                },
                                "picture": {
                                    title: "Picture",
                                    value: undefined,
                                    type: "pictureupload",
                                    upload: (files) => {
                                        this.contentConfig.sections.redeemableInfo.inputs.picture.value = files
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
