import Vue from 'vue';
import Vuex from 'vuex';

import {userStore} from './users';
import {organizationStore} from './organizations';
import {plasticCreditStore} from './plasticCredits';
import {facilityStore} from "./facilities";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        userStore,
        organizationStore,
        facilityStore,
        plasticCreditStore,
    },
    state: {
        isLoading: false
    },
});

export default store;
