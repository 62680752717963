<template>
    <div>
        <Modal :close-callback="closeCallback" v-if="showModal">
            <UserSearch :select-callback="userSelected" :users-to-ignore="usersToIgnore"/>
        </Modal>
        <ContentInput :content-config="contentConfig"/>
    </div>
</template>

<script>
import ContentInput from "../ContentInput";
import Modal from "../Modal";
import UserSearch from "../UserSearch";

export default {
        name: "EditOrganization",
        components: {UserSearch, Modal, ContentInput},
        methods: {
            closeCallback() {
                this.showModal = false;
            },
            loadOrganization() {
                this.$organizationsService.getOrganization(this.$route.params.organizationId)
                    .then(organization => {
                        this.contentConfig.sections.organizationInfo.inputs.organizationName.value = organization.organizationName;
                        this.contentConfig.sections.organizationInfo.inputs.organizationNumber.value = organization.organizationNumber;
                        this.contentConfig.sections.organizationInfo.inputs.aboutOrganization.value = organization.aboutOrganization;
                        this.contentConfig.sections.organizationInfo.inputs.customDashboardUrl.value = organization.customDashboardUrl;
                        this.contentConfig.sections.organizationInfo.inputs.customDashboardEmbedUrl.value = organization.customDashboardEmbedUrl;
                        this.contentConfig.sections.organizationInfo.inputs.organizationPicture.values = [organization.organizationPicture];
                    })
            },
            loadUsersInOrganization() {
                this.$organizationsService.getUsersInOrganization(this.$route.params.organizationId)
                    .then(usersInSelectedOrganization => {
                        this.contentConfig.sections.organizationMembers.rows = [];
                        this.usersToIgnore = usersInSelectedOrganization;

                        for (const user of usersInSelectedOrganization) {
                            this.contentConfig.sections.organizationMembers.rows.push({
                                id: user.userId,
                                data: [`${user.firstName} ${user.lastName}`, user.email, user.countryCode, user.phoneNumber],
                                callback: () => {
                                    const remove = confirm("Are you sure you want to remove this user from the organization?");
                                    if (remove) {
                                        this.$organizationsService.removeUserFromOrganization(this.$route.params.organizationId, user.userId)
                                            .then(this.loadUsersInOrganization);
                                    }

                                }
                            })
                        }
                    })
            },
            userSelected(user) {
                this.showModal = false;
                this.$organizationsService.addUserToOrganization(this.$route.params.organizationId, user.userId)
                    .then(this.loadUsersInOrganization);
            }
        },
        created() {
            this.loadOrganization();
            this.loadUsersInOrganization();
        },
        data() {
            return {
                showModal: false,
                usersToIgnore: [],
                contentConfig: {
                    title: "Edit Organization",
                    buttons: [
                        {
                            title: "Save",
                            isDark: true,
                            callback: () => {
                                const organizationInfoInputs = this.contentConfig.sections.organizationInfo.inputs;
                                const organizationUpdateObj = {
                                    organizationName: organizationInfoInputs.organizationName.value,
                                    organizationNumber: organizationInfoInputs.organizationNumber.value,
                                    aboutOrganization: organizationInfoInputs.aboutOrganization.value,
                                    orgPicture: organizationInfoInputs.organizationPicture.values[0],
                                    /**
                                     * if no value was provided for the Datastudio Dashboard URls in this interface,
                                     * we want it to explicitly be "empty string". That way the backend will clear those
                                     * atributes.
                                     * 
                                     * If the values were null/not-provided, the backend wouldn't update those fields.
                                     */
                                    customDashboardUrl: organizationInfoInputs.customDashboardUrl.value || '',
                                    customDashboardEmbedUrl: organizationInfoInputs.customDashboardEmbedUrl.value || '',
                                };
                                this.$organizationsService.editOrganization(this.$route.params.organizationId, organizationUpdateObj)
                                    .then(() => {
                                        this.$router.push({
                                            name: 'view-organization',
                                            params: {organizationId: this.$route.params.organizationId}
                                        })
                                    })
                            }
                        }
                    ],
                    sections: {
                        "organizationInfo": {
                            title: "Organization Info:",
                            type: "info",
                            inputs: {
                                "organizationName": {
                                    title: "Organization Name",
                                    type: "text",
                                    value: "",
                                },
                                "organizationNumber": {
                                    title: "Organization Number",
                                    type: "text",
                                    value: ""
                                },
                                "aboutOrganization": {
                                    title: "About",
                                    value: ""
                                },
                                "customDashboardUrl": {
                                    "title": "Full Datastudio Dashboard URL",
                                    value: "",
                                },
                                "customDashboardEmbedUrl": {
                                    title: "Embedded Datastudio Dashboard URL",
                                    value: "",
                                },
                                "organizationPicture": {
                                    title: "Pictures",
                                    type: "multiple",
                                    typeDefinition: "pictureupload",
                                    values: [],
                                    upload: (files) => {
                                        this.$sharedFileUploadService.uploadFiles(files).then(response => {
                                            this.contentConfig.sections.organizationInfo.inputs.organizationPicture.values = [response[0].ssl_url]
                                        });
                                        event.target.disabled = true
                                    },
                                    delete: () => {
                                        event.target.disabled = true
                                        this.contentConfig.sections.organizationInfo.inputs.organizationPicture.values = []
                                    }
                                }
                            }
                        },
                        "organizationMembers": {
                            type: "table",
                            buttons: [
                                {
                                    title: "Add Member",
                                    isDark: true,
                                    callback: () => {
                                        this.showModal = true;
                                    }
                                }
                            ],
                            columns: ["Name", "Email", "Country Code", "Phone Number"],
                            rows: []
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
