<template>
    <ContentInput :content-config="contentConfig"/>
</template>

<script>
import ContentInput from "../ContentInput";

export default {
        name: "CreateNewUser",
        components: {ContentInput},
        created() {
            this.$on('phone_number_updated', function(phoneNumberObj){
                this.phoneNumberObj = phoneNumberObj;
            });
        },
        data() {
            return {
                phoneNumberObj: {},
                phoneNumber: "",
                contentConfig: {
                    title: "Create New User",
                    buttons: [
                        {
                            title: "Save",
                            isDark: true,
                            callback: () => {
                                const accountInfoInputs = this.contentConfig.sections.accountInfo.inputs;
                                this.$userService.createUser({
                                    password: accountInfoInputs.password.value,
                                    email: accountInfoInputs.email.value,
                                    firstName: accountInfoInputs.firstName.value,
                                    lastName: accountInfoInputs.lastName.value,
                                    country: accountInfoInputs.country.value,
                                    countryCode: this.phoneNumberObj.countryCallingCode,
                                    phoneNumber: this.phoneNumberObj.nationalNumber
                                }).then(() => {
                                    this.$store.dispatch("getAllUsers").then(() => {
                                        this.$router.push({ name: "all-users"});
                                    })
                                });
                            }
                        }
                    ],
                    sections: {
                        "accountInfo": {
                            title: "Account Info:",
                            type: "info",
                            inputs: {
                                "firstName": {
                                    title: "First Name",
                                    type: "text",
                                    value: "",
                                },
                                "lastName": {
                                    title: "Last Name",
                                    type: "text",
                                    value: ""
                                },
                                "email": {
                                    title: "E-mail",
                                    type: "email",
                                    value: ""
                                },
                                "phoneNumber": {
                                    title: "Phone Number",
                                    type: "phonenumber",
                                    value: ""
                                },
                                "country": {
                                    title: "Country",
                                    type: "text",
                                    value: ""
                                },
                                "password": {
                                    title: "Password",
                                    type: "password",
                                    value: ""
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
