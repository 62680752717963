<template>
    <div>
        <div ref="gmap" id="map">
        </div>
        <!--    <span  class="blue-action-btn" @click="resetToCurrentPosition" :class="{ 'cpm' : cpm }">Reset location</span>-->
    </div>
</template>

<script>
import { getGoogleMaps } from '@empower-platform/google-maps-shared';

export default {
        name: "GoogleMap",
        data(){
            return {
                google: null,
                map: null,
                marker: null,
            }
        },
        props: {
            showButton: Boolean,
            lat: {
                type: Number,
                default: 59.9086917
            },
            lng: {
                type: Number,
                default: 10.7224646
            },
            cpm: Boolean,
            getGeoPosition: {
                type: Boolean,
                default: false
            }
        },
        async mounted () {
            this.google = await getGoogleMaps();
            await this.initMap();
            await this.setGEOPositionOrMarker();
        },

        methods: {
            initMap (){
                this.map = new this.google.maps.Map(this.$refs.gmap, {
                    center: {lat: this.lat, lng: this.lng},
                    zoom: 12,
                    gestureHandling: "greedy",
                    streetViewControl: false,
                    mapTypeControl: false,
                    center_changed: () => {
                        if (!this.marker) {
                            this.marker = new this.google.maps.Marker({
                                position: this.map.center,
                                map: this.map,
                            });
                        } else {
                            this.marker.setPosition({lat: this.lat, lng: this.lng});
                        }
                        this.$emit("location-changed", {
                            lat: this.map.center.lat(),
                            lng: this.map.center.lng(),
                        })
                    }
                });
            },

            async setGEOPositionOrMarker() {
                if(this.getGeoPosition) {
                    await this.resetToCurrentPosition();
                } else {
                    this.setMarker();
                }
                this.$emit("location-changed", {
                    lat: this.map.center.lat(),
                    lng: this.map.center.lng(),
                })
            },

            resetToCurrentPosition() {
                this.$getLocation({enableHighAccuracy: true}).then(location => {
                    this.map.setCenter({
                        lat: location.lat,
                        lng: location.lng,
                    });
                    this.setMarker();
                }).catch(() => {
                    this.map.setCenter({
                        lat: this.lat,
                        lng: this.lng,
                    });
                });
            },

            setMarker() {
                if (!this.marker) {
                    this.marker = new this.google.maps.Marker({
                        position: {lat: this.map.center.lat(), lng: this.map.center.lng()},
                        map: this.map,
                    });
                } else {
                    this.marker.setPosition({lat: this.map.center.lat(), lng: this.map.center.lng()});
                }

            }
        }
    }


</script>

<style lang="scss" scoped>
    #map {
        width: 100%;
        height: 120px;
    }
    .blue-action-btn{
        position: relative;
        top: 9px;
    }
    .cpm{
        color: green !important;
    }
</style>
